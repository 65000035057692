import { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../constants/global.css";
import "./style/footerLogin.css";
import ButtonCleanGray from "../atoms/ButtonCleanGray";
import Icon1 from "../../images/Icon1.png";
import Icon2 from "../../images/Icon2.png";
import Icon3 from "../../images/Icon3.png";
import AppContext from "../../context/AppContext";

function FooterLogin() {
  const { content } = useContext(AppContext);

  return (
    <footer className="Background_Gray2 ">
      <div className="container-fluid desktop-footer py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-6">
              <h3 className="h3-stl-footer">
                <div dangerouslySetInnerHTML={{ __html: content["IFOOTER.ALL.ITEM1-LIST-TITLE"] }}></div>
              </h3>
              <div dangerouslySetInnerHTML={{ __html: content["IFOOTER.ALL.ITEM1-LIST"] }}></div>
              <h3 className="h3-stl-footer mt-5">
                <div dangerouslySetInnerHTML={{ __html: content["IFOOTER.ALL.ITEM2-LIST-TITLE"] }}></div>
              </h3>
              <div dangerouslySetInnerHTML={{ __html: content["IFOOTER.ALL-ITEM2-LIST"] }}></div>
            </div>
            <div className="col-md-4 col-lg-5 col-6">
              <h3 className="h3-stl-footer">
                <div dangerouslySetInnerHTML={{ __html: content["IFOOTER.ALL.ITEM3-LIST-TITLE"] }}></div>
              </h3>
              <div dangerouslySetInnerHTML={{ __html: content["IFOOTER.ALL-ITEM3-LIST"] }}></div>

              <h3 className="h3-stl-footer mt-5">
                <div dangerouslySetInnerHTML={{ __html: content["IFOOTER.ALL.ITEM4-LIST-TITLE"] }}></div>
              </h3>
              <div dangerouslySetInnerHTML={{ __html: content["IFOOTER.ALL-ITEM4-LIST"] }}></div>
            </div>

            <div className="col-md-5 col-lg-4 col-12">
              <div style={{ display: "flex" }}>
                <ButtonCleanGray
                  message={
                    <div className="row" style={{ width: "100%" }}>
                      <div className="col-4" style={{ paddingRight: "0", marginRight: "0" }}>
                        <img src={Icon1} style={{ paddingBottom: "10px", paddingTop: "10px", width: "80%" }}></img>
                      </div>
                      <div className="col-8">
                        <h3 className="h4-stl-footer-btn" style={{ paddingTop: "5px", width: "190px" }}>
                          <div dangerouslySetInnerHTML={{ __html: content["FOOTER.ALL.BUTTON-WORLD-TITLE"] }}></div>
                        </h3>
                      </div>
                    </div>
                  }
                  reference="https://www.facom.com/?_ga=2.258111626.1235616950.1605607184-912071394.1605607184"
                ></ButtonCleanGray>
              </div>
              <div className="pt-1"></div>
              <div style={{ display: "flex" }}>
                <ButtonCleanGray
                  message={
                    <div className="row" style={{ width: "100%" }}>
                      <div className="col-4" style={{ paddingRight: "0", marginRight: "0" }}>
                        <img src={Icon2} style={{ paddingBottom: "10px", paddingTop: "10px", width: "80%" }}></img>
                      </div>
                      <div className="col-8">
                        <h3 className="h4-stl-footer-btn" style={{ paddingTop: "5px", width: "190px" }}>
                          <div dangerouslySetInnerHTML={{ __html: content["FOOTER.ALL.BUTTON-DISTRIBUTER-TITLE"] }}></div>
                        </h3>
                      </div>
                    </div>
                  }
                  reference="https://www.facom.fr/services/points-de-ventes/Points-de-ventes.html"
                ></ButtonCleanGray>
              </div>
              <div className="pt-1"></div>
              <div style={{ display: "flex" }}>
                <ButtonCleanGray
                  message={
                    <div className="row" style={{ width: "100%" }}>
                      <div className="col-4" style={{ paddingRight: "0", marginRight: "0" }}>
                        <img src={Icon3} style={{ paddingBottom: "10px", paddingTop: "10px", width: "80%" }}></img>
                      </div>
                      <div className="col-8">
                        <h3 className="h4-stl-footer-btn" style={{ paddingTop: "5px", width: "190px" }}>
                          <div dangerouslySetInnerHTML={{ __html: content["FOOTER.ALL.BUTTON-SALES-TITLE"] }}></div>
                        </h3>
                      </div>
                    </div>
                  }
                  reference="https://www.facom.fr/services/service-apres-vente/service-apres-ventes.html"
                ></ButtonCleanGray>
              </div>
            </div>
            <div className="py-4"></div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterLogin;
