import { SET_PROFILE, CLEAR_STATE } from ".";

const Profile = {
  token: "",
  email: "",
  full_name: "",
  sub: "",
  username: "",
  language: "FR",
  reload: true,
};

/**
 *
 * @param {Profile} profile
 * @returns
 */
const setProfile = (profile) => (dispatch) => {
  dispatch({
    type: SET_PROFILE,
    payload: profile,
  });
};

const clearState = () => (dispatch) => {
  dispatch({
    type: CLEAR_STATE,
  });
};

export { setProfile, clearState };
