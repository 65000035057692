import React from 'react';
import './styles/input.css'


function  TextArea(props){
    const handleOnInputChange = (event)=>{
        const cliendData = event.target.value;
        props.handleOnInputValue(cliendData)
    }  
    return(
        <div className="h4-stl-form">
            <form action ="">
                <textarea 
                    readOnly = {props.readOnly}
                    name = {props.name} 
                    placeholder = {props.placeholder}
                    style = {props.style}
                    onChange = {(value) =>handleOnInputChange(value)}
                    value = {props.currentValue}
                    maxLength={props.maxLength}
                    >
                </textarea>
            </form>     
        </div>
    );
}
export default TextArea;