import React, { useState } from "react";
import { Template } from "../pages/pageTemplates/template";

const useGlobalStates = () => {
  const [value, setValue] = useState("");
  const [version, setVersion] = useState("2.1.0");
  const [languageUrl, setLanguageUrl] = useState("EN");
  const [logged, setLogged] = useState(false);
  const [user, setUser] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [createPassword, setCreatePassword] = useState("");
  const [createPasswordConfirm, setCreatePasswordConfirm] = useState("");
  const [details, setDetails] = useState("");
  const [reasonExchangeID, setReasonExchangeID] = useState("");
  const [productID, setProductID] = useState("");
  const [productIDReference, setProductIDReference] = useState("");
  const [productIDType, setProductIDType] = useState("");
  const [distributorID, setdistributorID] = useState("");
  const [inputProductSku, setInputProductSku] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isValidSamePass, setIsValidSamePass] = useState(false);
  const [isValidSiret, setIsValidSiret] = useState(false);
  const [isValidUpdate, setIsValidUpdate] = useState(false);
  const [isValidSamePassUpdate, setIsValidSamePassUpdate] = useState(false);
  const [isValidSiretUpdate, setIsValidSiretUpdate] = useState(false);
  const [exchengeCreated, setExchengeCreated] = useState(false);
  const [content, setContent] = useState(Template);
  const [countLoads, setCountLoads] = useState(0);
  const [elegibleExchange, setElegibleExchange] = useState(true);

  const [responsiveLaders, setResponsiveLaders] = useState("warranty-c-col-0 warranty-c-col-lg-2");
  const [styleDisplay1, setStyleDisplay1] = useState({ display: "none" });
  const [styleDisplay2, setStyleDisplay2] = useState({ display: "none" });
  const [styleDisplay3, setStyleDisplay3] = useState({ display: "none" });
  const [styleDisplay4, setStyleDisplay4] = useState({ display: "none" });
  const [assetsMedia, setAssetsMedia] = useState("");
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [successValidationEmail, setSuccessValidationEmail] = useState(false);
  const [originPath, setOriginPath] = useState("");

  const [globalAlert, setGlobalAlert] = useState(false);
  const [globalAlertMessage, setGlobalAlertMessage] = useState("");
  const [dashboardURL, setDashboardURL] = useState({
    term_condition: "",
    warranty_claim_pdf: "",
    my_account_link: "",
  });

  const [initialHistory, setInitialHistory] = useState(false);
  const [detailGroupId, setDetailGroupId] = useState("");

  return {
    languageUrl,
    setLanguageUrl,
    zipCode,
    setZipCode,
    companyName,
    setCompanyName,
    user,
    setUser,
    address,
    setAddress,
    city,
    setCity,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    mobilePhone,
    setMobilePhone,
    createPassword,
    setCreatePassword,
    createPasswordConfirm,
    setCreatePasswordConfirm,
    details,
    setDetails,
    version,
    setVersion,
    reasonExchangeID,
    setReasonExchangeID,
    inputProductSku,
    setInputProductSku,
    productID,
    setProductID,
    distributorID,
    setdistributorID,
    isValid,
    setIsValid,
    isValidSamePass,
    setIsValidSamePass,
    isValidSiret,
    setIsValidSiret,
    isValidUpdate,
    setIsValidUpdate,
    isValidSamePassUpdate,
    setIsValidSamePassUpdate,
    isValidSiretUpdate,
    setIsValidSiretUpdate,
    exchengeCreated,
    setExchengeCreated,
    dashboardURL,
    setDashboardURL,
    styleDisplay1,
    setStyleDisplay1,
    styleDisplay2,
    setStyleDisplay2,
    styleDisplay3,
    setStyleDisplay3,
    styleDisplay4,
    setStyleDisplay4,
    responsiveLaders,
    setResponsiveLaders,
    assetsMedia,
    setAssetsMedia,
    loading,
    setLoading,
    content,
    setContent,
    countLoads,
    setCountLoads,
    toggle,
    setToggle,
    elegibleExchange,
    setElegibleExchange,
    successValidationEmail,
    setSuccessValidationEmail,

    globalAlert,
    setGlobalAlert,
    globalAlertMessage,
    setGlobalAlertMessage,

    originPath,
    setOriginPath,
    initialHistory,
    setInitialHistory,
    detailGroupId,
    setDetailGroupId,
    productIDReference,
    productIDType,
    setProductIDReference,
    setProductIDType,
  };
};
export default useGlobalStates;
