import { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AppContext from "../context/AppContext";

// COMPONENTS

import { Link } from "react-router-dom";
import { getAllExchangesByCustomerId, getAllExchangesByStatusAndID } from "../api/facom_services";
// CSS
import "../constants/global.css";
import "./styles/tranking.css";

import TableState from "../components/atoms/Table";
import LoadingFacom from "../components/atoms/LoadingFacom";
import { useSelector } from "react-redux";
import { getAllUsersById } from "../api/facom_services";
import { removeTags } from "../helpers/removeHtml";
import useGlobalContent from "../hooks/useGlobalContent";
import { getInLocalStorage, setInLocalStorage } from "../helpers/local-storage-manager";
import useToastMui from "../hooks/useToastMui";
import { useMediaQuery } from "@mui/material";
import ButtonAbandonned from "../components/atoms/ButtonAbandonned";

function TrackingState(props) {
  const { profile } = useSelector((state) => state.profileReducer);
  const { sub } = profile;
  const { handleCreateToast } = useToastMui();
  const arrowLeft = "< ";
  const { content } = useContext(AppContext);
  const originPath = getInLocalStorage("origin");
  const matches = useMediaQuery("max-width:991px");

  const [offsetCalls, setOffsetCalls] = useState(0);
  const [total, setTotal] = useState(0);
  const [userSub, setUserSub] = useState(null);
  const limit = 200;

  useEffect(() => {
    setOffsetCalls(0);
    if (props.origin) {
      setInLocalStorage("origin", props.origin);
    } else {
      setInLocalStorage("origin", "");
    }
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  });
  //Call api

  const [loading, setLoading] = useState(false);
  const [groupExchangesDraft, setGroupExchangesDraft] = useState([]);
  const [groupExchangesSubmitted, setGroupExchangesSubmitted] = useState([]);
  const [groupExchangesAbandonned, setGroupExchangesAbandonned] = useState([]);
  const [groupExchangesAnalysis, setGroupExchangesAnalysis] = useState([]);
  const [groupExchangesAccepted, setGroupExchangesAccepted] = useState([]);
  const [groupExchangesAvailable, setGroupExchangesAvailable] = useState([]);
  const [groupExchangesExchanged, setGroupExchangesExchanged] = useState([]);
  const [groupExchangesRefused, setGroupExchangesRefused] = useState([]);
  const [groupExchangesRecycled, setGroupExchangesRecycled] = useState([]);
  const [empty, setEmpty] = useState(false);
  const { replaceGlobalContents } = useGlobalContent();

  useEffect(() => {
    getExchangesStatus(0);
  }, []);

  const getExchangesStatus = async (offset) => {
    setLoading(!loading);

    try {
      let data = null;
      if (!userSub) {
        const response = getAllUsersById(sub);
        data = (await response).data;
        setUserSub(data?.result?.customer_data?._id);
      }
      if (userSub || data?.result?.customer_data?._id) {
        const responseEx = getAllExchangesByCustomerId(userSub || data?.result?.customer_data?._id, limit, offset);
        const dataEx = (await responseEx).data;

        if (dataEx.result?.items?.filter((item) => item?.status[0]?.status !== "Abandonned").length === 0) return setEmpty(true);
        setGroupExchangesDraft((prev) => [...prev, ...dataEx?.result?.items?.filter((item) => item.status[0].status === "Draft")]);
        setGroupExchangesSubmitted((prev) => [...prev, ...dataEx?.result?.items?.filter((item) => item.status[0].status === "Submitted")]);
        setGroupExchangesAnalysis((prev) => [...prev, ...dataEx?.result?.items?.filter((item) => item.status[0].status === "Analysis")]);
        setGroupExchangesAccepted((prev) => [...prev, ...dataEx?.result?.items?.filter((item) => item.status[0].status === "Accepted")]);
        setGroupExchangesAvailable((prev) => [...prev, ...dataEx?.result?.items?.filter((item) => item.status[0].status === "Available")]);
        setGroupExchangesExchanged((prev) => [...prev, ...dataEx?.result?.items?.filter((item) => item.status[0].status === "Exchanged")]);
        setGroupExchangesRefused((prev) => [...prev, ...dataEx?.result?.items?.filter((item) => item.status[0].status === "Refused")]);
        setGroupExchangesRecycled((prev) => [...prev, ...dataEx?.result?.items?.filter((item) => item.status[0].status === "Recycled")]);
        setTotal(dataEx?.result?.total);
        setOffsetCalls((prev) => prev + limit);
      }
      return data;
    } catch (error) {
      console.log("error get products");

      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(removeTags(`${content["ALERT-ERROR-GET-EXCHANGES-STATUS"]}`), "error");
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  return (
    <div className="professional-account content">
      <Link to={`/dashboard/${originPath}`}>
        <h6 className="h6-return" style={{ display: "flex", gap: "10px" }}>
          {arrowLeft}
          <div dangerouslySetInnerHTML={{ __html: content["FORM.ALL.BACK"] }}></div>
        </h6>
      </Link>
      <div className="container">
        <div className={!empty ? "Background_Gray" : "Background_Gray_empty"} style={{ margin: "0" }}>
          <div className="row">
            <div className="mt-4 pt-2 pt-lg-5">
              <h2 className="h1-stl Center pb-lg-3 pb-lg-3 pb-1">
                <div
                  dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE"]) }}
                  className="main-titles"
                ></div>
              </h2>
            </div>
          </div>
          <div className="row">
            {loading && (
              <div className="py-5">
                <LoadingFacom />
              </div>
            )}

            <div style={!matches && empty ? { height: "80px" } : {}}></div>

            {!loading && (
              <div className="col-12 bg-white">
                {empty && <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TRACKING-EMPTY-EXCHANGES"]) }}></div>}
                <div style={{ height: "30px" }}>
                  {total >= offsetCalls && (
                    <ButtonAbandonned
                      message={removeTags(`${content["TRAKING-BUTTON-LOAD-MORE"]}`)}
                      onClick={() => getExchangesStatus(offsetCalls)}
                      width="150px"
                      float="right"
                    />
                  )}
                </div>
                {groupExchangesDraft?.length != 0 && (
                  <div>
                    <h2 className="h2-stl-Track pt-4">
                      <div
                        dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-DRAFT"]) }}
                        className="second-title"
                      ></div>
                    </h2>
                    <div className="historic-list"></div>
                    <TableState list={groupExchangesDraft} State={"DRAFT"} reload={() => getExchangesStatus()} />
                  </div>
                )}
                {groupExchangesSubmitted?.length != 0 && (
                  <div>
                    <h2 className="h2-stl-Track pt-4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-SUBMITTED"]),
                        }}
                        className="second-title"
                      ></div>
                    </h2>
                    <div className="historic-list"></div>
                    <TableState list={groupExchangesSubmitted} State="SOUMIS" />
                  </div>
                )}
                {groupExchangesAbandonned?.length != 0 && (
                  <div>
                    <h2 className="h2-stl-Track pt-4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-ABANDONNED"]),
                        }}
                        className="second-title"
                      ></div>
                    </h2>
                    <div className="historic-list"></div>
                    <TableState list={groupExchangesAbandonned} State="SOUMIS" />
                  </div>
                )}
                {groupExchangesAnalysis?.length != 0 && (
                  <div>
                    <h2 className="h2-stl-Track pt-4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-ANALYSIS"]),
                        }}
                        className="second-title"
                      ></div>
                    </h2>
                    <div className="historic-list"></div>
                    <TableState list={groupExchangesAnalysis} State="ANALYSE" />
                  </div>
                )}
                {groupExchangesAccepted?.length != 0 && (
                  <div>
                    <h2 className="h2-stl-Track pt-4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-ACCEPTED"]),
                        }}
                        className="second-title"
                      ></div>
                    </h2>
                    <div className="historic-list"></div>
                    <TableState list={groupExchangesAccepted} State="ACCEPTÉ" />
                  </div>
                )}
                {groupExchangesAvailable?.length != 0 && (
                  <div>
                    <h2 className="h2-stl-Track pt-4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-AVAILABLE"]),
                        }}
                        className="second-title"
                      ></div>
                    </h2>
                    <div className="historic-list"></div>
                    <TableState list={groupExchangesAvailable} State="ACCEPTÉ" />
                  </div>
                )}
                {groupExchangesExchanged?.length != 0 && (
                  <div>
                    <h2 className="h2-stl-Track pt-4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-EXCHANGED"]),
                        }}
                        className="second-title"
                      ></div>
                    </h2>
                    <div className="historic-list"></div>
                    <TableState list={groupExchangesExchanged} State="ACCEPTÉ" />
                  </div>
                )}
                {groupExchangesRefused?.length != 0 && (
                  <div>
                    <h2 className="h2-stl-Track pt-4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-REFUSED"]),
                        }}
                        className="second-title"
                      ></div>
                    </h2>
                    <div className="historic-list"></div>
                    <TableState list={groupExchangesRefused} State="REFUSÉ" />
                  </div>
                )}
                {groupExchangesRecycled?.length != 0 && (
                  <div>
                    <h2 className="h2-stl-Track pt-4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-RECYCLED"]),
                        }}
                        className="second-title"
                      ></div>
                    </h2>
                    <div className="historic-list"></div>
                    <TableState list={groupExchangesRecycled} State="REFUSÉ" />
                  </div>
                )}
                <div className="py-5"></div>
              </div>
            )}
          </div>
          <div className={!matches && empty ? "py-5 my-5" : ""} style={!matches && empty ? { height: "400px" } : {}}></div>
        </div>
      </div>
    </div>
  );
}

export default TrackingState;
