import { removeTags } from "../../helpers/removeHtml";
import useGlobalContent from "../../hooks/useGlobalContent";
import { ButtonClean, TabWarranty } from "../atoms";
import { DropDownProduct, DropDownSKU } from "../molecules";
import "./style.css";
import { useContext } from "react";
import AppContext from "../../context/AppContext";

export default function ProductSelection(props) {
  const { pageStatus, handleChangeToTerms, content } = props;
  const { productsArray, selectedProductReference, handleChangeSKU, handleChangeInput, value, loadingProd } = props;
  const { productsReferenceArray, selectedProductReferenceByName, setSelectedProductReferenceByName } = props;
  const { handleChangeToStep2, handleChangeProd } = props;
  const { productIDReference, productIDType } = useContext(AppContext);

  const { replaceGlobalContents } = useGlobalContent();

  return (
    <div className="bg-white" style={pageStatus === 2 ? { display: "block" } : { display: "none" }}>
      <TabWarranty pageStatus={pageStatus} />
      <div className="block-main">
        <h5 className="h5-stl-warranty Left py-1">
          <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FIRST.STEP-INPUT-KNOW-TITLE"]) }}></div>
        </h5>
        <DropDownSKU
          options={productsArray}
          selectedItem={selectedProductReference}
          loadingProd={loadingProd}
          onChange={handleChangeSKU}
          value={value}
          handleChangeInput={handleChangeInput}
        />
        <div className="py-lg-2 py-2"></div>

        <h5 className="h5-stl-warranty Center">
          <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FIRST.STEP-OR"]) }}></div>
        </h5>

        <div className="py-lg-2 py-2"></div>
        <h5 className="h5-stl-warranty Left ">
          <div
            dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FIRST.STEP-INPUT-DONT-KNOW-TITLE"]) }}
          ></div>
        </h5>
        <DropDownProduct
          options={productsReferenceArray}
          selectedItem={selectedProductReferenceByName}
          onSelect={setSelectedProductReferenceByName}
          onChange={handleChangeProd}
          handleChangeInputProd={props.handleChangeInputProd}
          valueProd={props.valueProd}
        />

        <div className="py-3"></div>
        <div className="container-warranty-pad-bt flex-between-bt full-width-bt">
          <div className="full-width-bt flex-start-bt py-2" style={{ textAlign: "left !important" }}>
            <ButtonClean
              message={removeTags(`${content["FORM-NEW-WARRANTY-ALL-INFO-BUTTON-PRE"]}`)}
              onClick={handleChangeToTerms}
              width="120px"
              float="left"
            />
          </div>

          <div className="full-width-bt flex-end-bt py-2">
            <ButtonClean
              message={removeTags(`${content["FORM-NEW-WARRANTY-ALL-INFO-BUTTON-FOLLOWING"]}`)}
              onClick={handleChangeToStep2}
              disabled={!productIDReference && !productIDType}
              width="120px"
              float="right"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
