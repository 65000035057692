import { useContext, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AppContext from "../context/AppContext";

// COMPONENTS

import { Link } from "react-router-dom";
import ButtonClean from "../components/atoms/ButtonClean";
// CSS
import "../constants/global.css";
import "./styles/warranty.css";

import LoadingPag from "../components/atoms/LoadingPag";
import LoadingFacom from "../components/atoms/LoadingFacom";
import { useSelector } from "react-redux";
//contents
import { DetailsSection, DistributorSection, PicturesSection, ProductSelection, TypeBreakSection } from "../components";
import { useGetDistributors, useGetExchanges, useGetPictures, useGetProducts, useGetUser } from "../hooks";
import FloatAlert from "../components/atoms/FloatAlert";
import useGlobalContent from "../hooks/useGlobalContent";
import { removeTags } from "../helpers/removeHtml";
import { getInLocalStorage, setInLocalStorage } from "../helpers/local-storage-manager";
import useToastMui from "../hooks/useToastMui";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

function WarrantyPage(props) {
  const { width } = useWindowSize();
  const { profile } = useSelector((state) => state.profileReducer);
  const { content } = useContext(AppContext);
  const { handleCreateToast } = useToastMui();

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const Minutes = date.getMinutes();
  const { distributorID, setdistributorID, assetsMedia } = useContext(AppContext);

  const {
    reasonExchangeID,
    setReasonExchangeID,
    loading,
    productID,
    setProductID,
    elegibleExchange,
    productIDReference,
    productIDType,
    setProductIDReference,
    setProductIDType,
  } = useContext(AppContext);

  const TodayDate = `${day}/${month}/${year} ${hour}:${Minutes}`;

  const { sub } = profile;

  // const [selectedProductReference,setSelectedProductReference ] = useState(productReferenceproducts[0])
  const [selectedProductReference, setSelectedProductReference] = useState("");
  const [selectedPostalCodeFinal, setSelectedPostalCodeFinal] = useState("");
  const [selectedProductReferenceByName, setSelectedProductReferenceByName] = useState("");
  const [selectedTypeCase, setselectedTypeCase] = useState("");

  // const [productReferenceID,setProductReferenceID ] = useState('')

  const originPath = getInLocalStorage("origin");

  useEffect(() => {
    if (props.origin) {
      setInLocalStorage("origin", props.origin);
    } else {
      setInLocalStorage("origin", "");
    }
    if (getInLocalStorage("recreate") == "yes") {
      setPageStatus(2);
    }
    setProductID("");
    setReasonExchangeID("");
    setdistributorID("");
    setDetails("");
    setProductIDReference("");
    setProductIDType("");
  }, []);

  const { toggle } = useContext(AppContext);

  useEffect(() => {
    setSelectedProductReference(removeTags(`${content["FORM-NEW-WARRANTY-FIRST.STEP-INPUT-KNOW-PLACEHOLDER"]}`));

    setSelectedProductReferenceByName(removeTags(`${content["FORM-NEW-WARRANTY-FIRST.STEP-DONT-INPUT-KNOW-PLACEHOLDER"]}`));

    setSelectedPostalCodeFinal(removeTags(`${content["FORM-NEW-WARRANTY-FINAL.STEP-PLACEHOLDER"]}`));

    setselectedTypeCase(removeTags(`${content["FORM-NEW-WARRANTY-THIRD.STEP-INPUT-KNOW-PLACEHOLDERS"]}`));
  }, [toggle, content]);

  const { details, setDetails } = useContext(AppContext);
  var totalCharacters = 600 - details.length;
  var [pageStatus, setPageStatus] = useState(1);
  useEffect(() => {
    window.scroll(0, 0);
  }, [pageStatus]);

  //API STATE

  //!-------------------PRODUCTS------------------------
  const {
    productsArray,
    productsReferenceArray,
    handleChange,
    handleChangeProd,
    handleChangeInput,
    value,
    loadingProd,
    handleChangeInputProd,
    valueProd,
  } = useGetProducts();

  //!-------------------DISTRIBUTOR------------------------

  const {
    getDistributorID,
    distributorsArray,
    distributorsIdArray,
    handleChangeDist,
    loadingDist,
    handleChangeInputDist,
    valueDist,
    valueDistSelect,
    getDistributorsFunction,
    hasNextPage,
    loadingScroll,
  } = useGetDistributors();

  //? Se agrega el id segun la referencia al producto

  //* MAPEO DE REASN FOR EXCHANGE EN OBJETO PARA DROPDOWN

  //!------------------------------EXCHANGES------------------------------------

  const { createNewExchanges, updateToSpecificStatus, reasonForExchangesArray, loadingLast } = useGetExchanges(setPageStatus);

  //!------------------------------PICTURES------------------------------------
  const {
    picturesArray,
    fileNameImage1,
    fileNameImage2,
    fileNameImage3,
    fileNameImage4,
    PhotosStatus,
    DisabledPhoto1,
    DisabledPhoto2,
    DisabledPhoto3,
    DisabledPhoto4,
    handleChangeStatus,
    handleChangeStatusImage2,
    handleChangeStatusImage3,
    handleChangeStatusImage4,
    alertImage,
  } = useGetPictures();
  const { replaceGlobalContents } = useGlobalContent();

  const renderInfoPage = () => {
    return (
      <div style={{ backgroundColor: "#FFF", borderRadius: "5px" }}>
        <div className="row">
          <div className="col-1 col-lg-2"></div>
          <div className="col-10 col-lg-8">
            <h3 className="h3-stl-warranty pt-5">
              <div
                dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-INFO.STEP-BOX-TITLE"]) }}
                className="main-titles"
              ></div>
            </h3>
            <div className="line-loggin pb-lg-2 pb-2" style={{ display: "flex", justifyContent: "center" }}></div>
            <h5 className="h5-stl-warranty Left pb-2">
              <div
                dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-INFO.STEP-INFO-TEXT-1"]) }}
                className="detail-warranty"
              ></div>
            </h5>
            <div className="row">
              <div className="col-2 col-lg-10"></div>
              <div className="col-8 col-lg-2">
                <ButtonClean
                  message={removeTags(`${content["FORM-NEW-WARRANTY-INFO.STEP-INFO-BUTTON"]}`)}
                  onClick={() => {
                    setPageStatus(2);
                  }}
                />
              </div>
              <div className="col-2 col-lg-0"></div>
            </div>
            <div className="py-5"></div>
          </div>
          <div className="col-1 col-lg-2"></div>
        </div>
      </div>
    );
  };

  // !------------------------------------!changes-------------------------------------

  const handleChangeToStep2 = () => {
    if (productIDReference || productIDType) {
      setPageStatus(3);
    }
  };
  const handleChangeToStep4 = () => {
    if (reasonExchangeID) {
      setPageStatus(5);
    }
  };
  const handleChangeToStep5 = async () => {
    try {
      await createNewExchanges(details, picturesArray);
    } catch (error) {
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(removeTags(`${content["ALERT-ERROR-CREATE-EXCHANGE"]}`), "error");
    }
  };

  //? -----------------------------------STEP 1 --------------------------------------

  const renderProductSelection = () => {
    return (
      <ProductSelection
        pageStatus={pageStatus}
        content={content}
        productsArray={productsArray}
        selectedProductReference={selectedProductReference}
        setSelectedProductReference={setSelectedProductReference}
        handleChangeSKU={handleChange}
        handleChangeProd={handleChangeProd}
        productsReferenceArray={productsReferenceArray}
        selectedProductReferenceByName={selectedProductReferenceByName}
        setSelectedProductReferenceByName={setSelectedProductReferenceByName}
        handleChangeToStep2={() => {
          setInLocalStorage("recreate", "no");
          handleChangeToStep2();
        }}
        handleChangeToTerms={() => {
          setInLocalStorage("recreate", "no");
          setPageStatus(1);
        }}
        handleChangeInput={handleChangeInput}
        value={value}
        loadingProd={loadingProd}
        handleChangeInputProd={handleChangeInputProd}
        valueProd={valueProd}
      />
    );
  };

  //change of screen
  var footerBool = true;
  if (window.outerWidth < 800) {
    if (window.outerWidth > 500) {
      footerBool = false;
    } else {
      footerBool = true;
    }
  }
  //? -----------------------------------STEP 2 --------------------------------------
  const renderPhotos = () => {
    return (
      <PicturesSection
        pageStatus={pageStatus}
        content={content}
        PhotosStatus={PhotosStatus}
        alertImage={alertImage}
        assetsMedia={assetsMedia}
        fileNameImage1={fileNameImage1}
        handleChangeStatus={handleChangeStatus}
        DisabledPhoto1={DisabledPhoto1}
        fileNameImage2={fileNameImage2}
        handleChangeStatusImage2={handleChangeStatusImage2}
        DisabledPhoto2={DisabledPhoto2}
        fileNameImage3={fileNameImage3}
        handleChangeStatusImage3={handleChangeStatusImage3}
        DisabledPhoto3={DisabledPhoto3}
        fileNameImage4={fileNameImage4}
        handleChangeStatusImage4={handleChangeStatusImage4}
        DisabledPhoto4={DisabledPhoto4}
        setPageStatusBefore={() => {
          setPageStatus(2);
        }}
        setPageStatusAfter={() => {
          setPageStatus(4);
        }}
      />
    );
  };

  // ! ------------------------------ STEP 3 --------------------------------
  const renderTypeCasses = () => {
    return (
      <TypeBreakSection
        pageStatus={pageStatus}
        content={content}
        reasonForExchangesArray={reasonForExchangesArray}
        selectedTypeCase={selectedTypeCase}
        setselectedTypeCase={setselectedTypeCase}
        reasonExchangeID={reasonExchangeID}
        setPageStatusBefore={() => {
          setPageStatus(3);
        }}
        setPageStatusAfter={handleChangeToStep4}
      />
    );
  };

  //? -----------------------------------STEP 4 --------------------------------------

  const renderSend = () => {
    return (
      <DetailsSection
        pageStatus={pageStatus}
        content={content}
        details={details}
        setDetails={(data) => {
          setDetails(data);
        }}
        totalCharacters={totalCharacters}
        setPageStatusBefore={() => {
          setPageStatus(4);
        }}
        setPageStatusAfter={() => {
          handleChangeToStep5();
        }}
      />
    );
  };

  //? -----------------------------------STEP 5 --------------------------------------

  const renderFinal = () => {
    return (
      <DistributorSection
        content={content}
        distributorsArray={distributorsArray}
        selectedPostalCodeFinal={selectedPostalCodeFinal}
        onSelectDdDist={(value) => {
          setSelectedPostalCodeFinal(value);
          getDistributorID(distributorID);
        }}
        onChangeDdDist={handleChangeDist}
        loadingDist={loadingDist}
        distributorsIdArray={distributorsIdArray}
        distributorID={distributorID}
        width={width}
        setPageStatusBefore={() => {
          updateToSpecificStatus(true);
        }}
        setPageStatusAfter={() => {
          updateToSpecificStatus(false);
        }}
        handleChangeInputDist={handleChangeInputDist}
        valueDist={valueDist}
        valueDistSelect={valueDistSelect}
        getDistributorsFunction={getDistributorsFunction}
        hasNextPage={hasNextPage}
        loadingScroll={loadingScroll}
      />
    );
  };

  var ColorPage = false;
  if (pageStatus === 1 || pageStatus === 6) {
    ColorPage = true;
  } else {
    ColorPage = false;
  }

  const arrowLeft = "< ";
  return (
    <div
      className="py-lg-5 py-2 exchange-form"
      style={ColorPage === true ? { backgroundColor: "#0E0F0F", margin: "0" } : { backgroundColor: "#1B1E1F", margin: "0" }}
    >
      <Link to={`/dashboard/${originPath}`}>
        <h6 className="h6-return" style={{ display: "flex", gap: "10px" }}>
          {arrowLeft}
          <div dangerouslySetInnerHTML={{ __html: content["FORM.ALL.BACK"] }}></div>
        </h6>
      </Link>

      <div className="container">
        {pageStatus === 1 && (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h2 className="h1-stl-warranty Center pt-lg-5 pt-3">
                <div
                  dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL-TITLE"]) }}
                  className="main-titles"
                ></div>
              </h2>
            </div>
            <h5 className="h5-stl-main Center py-3 ">
              <div
                dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-INFO.STEP-TITLE"]) }}
                className="description-text"
              ></div>
            </h5>
          </div>
        )}
        {(pageStatus === 2 || pageStatus === 3 || pageStatus === 4 || pageStatus === 5) && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h2 className="h1-stl-warranty Center pt-lg-5 pt-3 ">
              <div
                dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL-TITLE"]) }}
                className="main-titles"
              ></div>
            </h2>
          </div>
        )}
        {pageStatus === 6 && (
          <div>
            {elegibleExchange ? (
              <>
                <h2 className="h1-stl Center pt-5 ">
                  <div
                    dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FINAL.STEP-TITLE"]) }}
                    className="main-titles"
                  ></div>
                </h2>
                <div className="py-3">
                  <div
                    dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FINAL.STEP-INFO"]) }}
                    className="description-text"
                  ></div>
                </div>
              </>
            ) : (
              <>
                <h2 className="h1-stl Center pt-5 ">
                  <div
                    dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FINAL.STEP-TITLE"]) }}
                    className="main-titles"
                  ></div>
                </h2>
                <div className="py-3">
                  <div
                    dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FINAL.STEP-INFO-NO-WARRANTY"]) }}
                    className="description-text"
                  ></div>
                </div>
              </>
            )}
          </div>
        )}

        <div className="my-lg-3 my-3">
          {pageStatus === 1 && renderInfoPage()}
          {renderProductSelection()}
          {renderPhotos()}
          {pageStatus === 4 && renderTypeCasses()}
          {pageStatus === 5 && renderSend()}
        </div>
      </div>
      {!loadingLast ? <div>{pageStatus === 6 && renderFinal()}</div> : <LoadingFacom />}
      {/* <div className="py-4 my-4"></div> */}
      <LoadingPag open={loading} />
    </div>
  );
}

export default WarrantyPage;
