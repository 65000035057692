import { SET_PROFILE, CLEAR_STATE } from "../actions";
import { AnyAction } from "redux";
import { INITIAL_STATE } from ".";
import { setInLocalStorage } from "../../helpers/local-storage-manager";

/**
 *
 * @param {INITIAL_STATE} state
 * @param {AnyAction} action
 * @returns
 */
const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return { ...state, profile: { ...state.profile, ...action.payload } };
    case CLEAR_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export { profileReducer };
