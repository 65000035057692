import { useContext, useState } from "react";
import { ButtonClean, InputForm, LoadingFacom, ValidationAlert } from "../components";
import AppContext from "../context/AppContext";
import "../components/molecules/style/loginForm.css";
import { updateMigratedUsers } from "../api/facom_services";
import { LocalStorageKeys, getInLocalStorage } from "../helpers/local-storage-manager";
import { removeTags } from "../helpers/removeHtml";
import { useSelector } from "react-redux";

export default function VerifyIdentity() {
  const { content } = useContext(AppContext);
  const { profile } = useSelector((state) => state.profileReducer);
  const { language } = profile;
  const [email, setEmail] = useState("");

  const [error, setError] = useState(false);
  const [successSendEmail, setSuccessSendEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendVerification, setSendVerification] = useState(false);
  const arrowLeft = "< ";
  const [errorValidationMessage, setErrorValidationMessage] = useState("");
  const origin = getInLocalStorage("origin");
  const [isValid, setIsValid] = useState(false);

  const verifyIdentityFn = async () => {
    setLoading(true);
    setSendVerification(true);
    try {
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      const validatedEmailRegex = emailRegex.test(email);
      setIsValid(validatedEmailRegex);
      if (validatedEmailRegex) {
        const uuid = `${getInLocalStorage(LocalStorageKeys.USER_ID)}`;
        await updateMigratedUsers(uuid, email);
        setSuccessSendEmail(true);
      }
      setError(false);
    } catch (error) {
      console.log("error validating code");
      console.log(error);
      setErrorValidationMessage(`${error.response.data.message}`);
      if (error?.response?.data?.metadata?.error_description) {
        setErrorValidationMessage(
          `${error?.response?.data?.metadata?.error_description}` === "Attempted to update a duplicate value"
            ? language === "EN"
              ? "The email is already in use"
              : "L'adresse e-mail est déjà utilisée."
            : `${error?.response?.data?.metadata?.error_description}`
        );
      } else {
        setErrorValidationMessage(`${error.response.data.message}`);
      }
      setError(true);
    } finally {
      console.log("end");
      setLoading(false);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    verifyIdentityFn();
  };

  const RenderError = () => {
    return (
      <>
        {error && (
          <div className="my-3">
            <ValidationAlert message={errorValidationMessage} variant="error" />
          </div>
        )}
      </>
    );
  };
  const RenderSuccess = () => {
    return (
      <>
        {successSendEmail && (
          <div className="my-3">
            <ValidationAlert
              message={
                language === "EN" ? "An email has been sent to your mailbox" : "Un email vous a été envoyé sur votre boîte de messagerie"
              }
              severity="success"
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div className="Background_Gray py-5">
      <div className="px-5 mb-5">
        <a href={origin ? `/login/${origin}` : `/login`}>
          <h6 className="h6-return" style={{ display: "flex", gap: "10px" }}>
            {arrowLeft}
            <div dangerouslySetInnerHTML={{ __html: content["FORM.ALL.BACK"] }}></div>
          </h6>
        </a>
      </div>
      <div style={{ display: "flex" }}>
        <div className="col-1 col-xl-4"></div>
        <div className="col-10 col-xl-4">
          <div className="py-5 px-4 Background_White Center">
            <h2 className="h2-stl Center ">
              <div dangerouslySetInnerHTML={{ __html: content["VERIFY-IDENTITY-TITLE"] }}></div>
            </h2>
            <div dangerouslySetInnerHTML={{ __html: content["VERIFY-IDENTITY-DESCRIPTION"] }}></div>
            <div className="line-loggin"></div>
            <div className="row">
              <div className="col-12 col-lg-4">
                <h4 className="h4-stl Rigth-l py-1">
                  <div dangerouslySetInnerHTML={{ __html: content["SING-IN-BOX.LOGIN-HOME.INPUT-EMAIL"] }}></div>
                </h4>
              </div>
              <div className="col-12 col-lg-6">
                <form action="/" onSubmit={handleSubmit}>
                  <InputForm
                    type="text"
                    name="password"
                    placeholder={removeTags(`${content["SING-IN-BOX.LOGIN-HOME.INPUT-EMAIL"]}`)}
                    style={{ heigth: "250px" }}
                    currentValue={email}
                    handleOnInputValue={(data) => {
                      setEmail(data);
                    }}
                  />
                  <RenderError />
                  <RenderSuccess />
                  {!isValid && sendVerification && (
                    <div className="my-3">
                      <ValidationAlert message={`${removeTags(`${content["ALERT-NOT-EMAIL"]}`)}`} variant="error" />
                    </div>
                  )}
                </form>
              </div>
              <div className="py-2"></div>
              <form action="/" onSubmit={handleSubmit}>
                <input type="submit" value={removeTags(`${content["VERIFY-IDENTITY-BUTTON"]}`)} style={{ display: "none" }}></input>
              </form>
              <div className="mt-2">
                <ButtonClean
                  message={removeTags(`${content["VERIFY-IDENTITY-BUTTON"]}`)}
                  onClick={() => verifyIdentityFn(email)}
                  width="60%"
                />
              </div>

              <div className="col-0 col-lg-2"></div>
            </div>
          </div>
        </div>
        <div className="col-1 col-xl-4"></div>
      </div>

      {loading && <LoadingFacom />}
    </div>
  );
}
