import "bootstrap/dist/css/bootstrap.css";

import "../../constants/global.css";
import "./styles/buttonMain.css";

function ButtonTable(props) {
  return (
    <div className="button-font-bold-table">
      <button type="button" onClick={props.onClick} style={{ width: `${props.width}`, float: `${props.float}` }} className="btn-white pt-2">
        <strong>{props.message}</strong>
      </button>
    </div>
  );
}

export default ButtonTable;
