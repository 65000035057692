import { useState } from "react";
import Compressor from "compressorjs";

const useGetPictures = () => {
  const [picturesArray, setPicturesArray] = useState([]);
  const [fileNameImage1, setFileNameImage1] = useState("");
  const [fileNameImage2, setFileNameImage2] = useState("");
  const [fileNameImage3, setFileNameImage3] = useState("");
  const [fileNameImage4, setFileNameImage4] = useState("");
  const [PhotosStatus, setPhotosStatus] = useState(1);

  var DisabledPhoto1 = false;
  var DisabledPhoto2 = true;
  var DisabledPhoto3 = true;
  var DisabledPhoto4 = true;

  if (fileNameImage1 != "") {
    DisabledPhoto2 = false;
  }
  if (fileNameImage2 != "") {
    DisabledPhoto3 = false;
  }
  if (fileNameImage3 != "") {
    DisabledPhoto4 = false;
  }

  //FILE ATTACHEMENT
  const getBase64 = (file) => {
    console.log(typeof file);
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const [alertImage, setAlertImage] = useState(false);

  const handleChangeStatus = async (meta, status, files) => {
    if (status === "done") {
      const file = files[0].file;
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 1920,
        maxHeight: 1080,
        async success(result) {
          const base64File = await getBase64(result);

          setFileNameImage1(files[0]?.file?.name);
          setPhotosStatus(2);
          if (fileNameImage2) {
            setPhotosStatus(3);
          }
          if (fileNameImage3 && fileNameImage2) {
            setPhotosStatus(4);
          }

          let base64noType = "";
          if (result?.type === "image/png" || result?.type === "image/jpg") {
            base64noType = base64File.slice(22);
          } else if (result?.type === "image/jpeg") {
            base64noType = base64File.slice(23);
          }

          if (picturesArray.length > 0) {
            let copyPictures = picturesArray;
            copyPictures[0] = { file_content: base64noType, extension: result.type };
            setPicturesArray(copyPictures);
          } else {
            setPicturesArray([...picturesArray, { file_content: base64noType, extension: result?.type }]);
          }
        },
      });
    } else if (status === "error_file_size") {
      files.forEach((f) => f.remove());
      setFileNameImage1("");
      setPhotosStatus(1);
      DisabledPhoto2 = true;
      setAlertImage(true);
    } else {
      setAlertImage(false);
    }
    if (status === "removed") {
      setFileNameImage1("");
      setPhotosStatus(1);
      let copyPictures = picturesArray;
      copyPictures[0] = {};
      setPicturesArray(copyPictures);
    }
  };
  const handleChangeStatusImage2 = async (meta, status, files) => {
    if (status === "done") {
      const file = files[0].file;
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 1920,
        maxHeight: 1080,
        async success(result) {
          const base64File = await getBase64(result);

          setFileNameImage2(files[0].file.name);
          setPhotosStatus(3);
          if (fileNameImage3) {
            setPhotosStatus(4);
          }
          let base64noType = "";
          if (result?.type === "image/png" || result?.type === "image/jpg") {
            base64noType = base64File.slice(22);
          } else if (result?.type === "image/jpeg") {
            base64noType = base64File.slice(23);
          }
          if (picturesArray.length > 1) {
            let copyPictures = picturesArray;
            copyPictures[1] = { file_content: base64noType, extension: result?.type };
            setPicturesArray(copyPictures);
          } else {
            setPicturesArray([...picturesArray, { file_content: base64noType, extension: result?.type }]);
          }
        },
      });    
    } else if (status === "error_file_size") {
      files.forEach((f) => f.remove());
      setFileNameImage2("");
      setPhotosStatus(2);
      DisabledPhoto3 = true;
      setAlertImage(true);
    } else {
      setAlertImage(false);
    }
    if (status === "removed") {
      setFileNameImage2("");
      setPhotosStatus(2);
      let copyPictures = picturesArray;
      copyPictures[1] = {};
      setPicturesArray(copyPictures);
    }
  };
  const handleChangeStatusImage3 = async (meta, status, files) => {
    if (status === "done") {
      const file = files[0].file;
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 1920,
        maxHeight: 1080,
        async success(result) {
          const base64File = await getBase64(result);

          setFileNameImage3(files[0].file.name);
          setPhotosStatus(4);
          let base64noType = "";
          if (result?.type === "image/png" || result?.type === "image/jpg") {
            base64noType = base64File.slice(22);
          } else if (result?.type === "image/jpeg") {
            base64noType = base64File.slice(23);
          }
          if (picturesArray.length > 2) {
            let copyPictures = picturesArray;
            copyPictures[2] = { file_content: base64noType, extension: result?.type };
            setPicturesArray(copyPictures);
          } else {
            setPicturesArray([...picturesArray, { file_content: base64noType, extension: result?.type }]);
          }
        },
      });
    } else if (status === "error_file_size") {
      files.forEach((f) => f.remove());
      setFileNameImage3("");
      setPhotosStatus(3);
      DisabledPhoto4 = true;
      setAlertImage(true);
    } else {
      setAlertImage(false);
    }
    if (status === "removed") {
      setFileNameImage3("");
      setPhotosStatus(3);
      let copyPictures = picturesArray;
      copyPictures[2] = {};
      setPicturesArray(copyPictures);
    }
  };
  const handleChangeStatusImage4 = async (meta, status, files) => {
    if (status === "done") {
      const file = files[0].file;
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 1920,
        maxHeight: 1080,
        async success(result) {
          const base64File = await getBase64(result);

          setFileNameImage4(files[0].file.name);

          let base64noType = "";
          if (result.type === "image/png" || result.type === "image/jpg") {
            base64noType = base64File.slice(22);
          } else if (result.type === "image/jpeg") {
            base64noType = base64File.slice(23);
          }
          if (picturesArray.length > 3) {
            let copyPictures = picturesArray;
            copyPictures[3] = { file_content: base64noType, extension: result.type };
            setPicturesArray(copyPictures);
          } else {
            setPicturesArray([...picturesArray, { file_content: base64noType, extension: result.type }]);
          }
        },
      });
      
    } else if (status === "error_file_size") {
      files.forEach((f) => f.remove());
      setFileNameImage4("");
      setPhotosStatus(4);
      setAlertImage(true);
    } else {
      setAlertImage(false);
    }
    if (status === "removed") {
      setFileNameImage4("");
      setPhotosStatus(4);
      let copyPictures = picturesArray;
      copyPictures[3] = {};
      setPicturesArray(copyPictures);
    }
  };

  return {
    picturesArray,
    fileNameImage1,
    fileNameImage2,
    fileNameImage3,
    fileNameImage4,
    PhotosStatus,
    DisabledPhoto1,
    DisabledPhoto2,
    DisabledPhoto3,
    DisabledPhoto4,
    handleChangeStatus,
    handleChangeStatusImage2,
    handleChangeStatusImage3,
    handleChangeStatusImage4,
    alertImage,
  };
};

export default useGetPictures;
