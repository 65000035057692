import { useState, useContext, useEffect } from "react";
import {
  getAllDistributorGroupCodeVisible,
  getAllDistributorPostalCode,
  getAllDistributorPostalCodeAndGroupCode,
  getAllDistributors,
  getDistributorsById,
} from "../api/facom_services";
import AppContext from "../context/AppContext";
import { getInLocalStorage } from "../helpers/local-storage-manager";
import useToastMui from "./useToastMui";
import { removeTags } from "../helpers/removeHtml";
import useInfiniteScroll from "react-infinite-scroll-hook";

const useGetDistributors = () => {
  const { loading, setLoading, content, setdistributorID } = useContext(AppContext);
  const { handleCreateToast } = useToastMui();
  const [loadingDist, setLoadingDist] = useState(false);
  const [distributorsArray, setDistributorsArray] = useState([]);
  const [distributorsIdArray, setDistributorsIdArray] = useState([]);
  const [inputDistributor, setInputDistributor] = useState("");
  const [valueDist, setValueDist] = useState("");
  const [valueDistSelect, setValueDistSelect] = useState("");
  const [valueDistSearch, setValueDistSearch] = useState("");
  const originPath = getInLocalStorage("origin");
  const [hasNextPage, setgasNextPage] = useState(false);
  const [page, setPage] = useState(0);
  const [loadingScroll, setLoadingScroll] = useState(false);

  useEffect(() => {
    if (!valueDistSearch) return;

    const delayDebounceFn = setTimeout(() => {
      getDistributorsFunction();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [valueDistSearch]);

  const getDistributorsFunction = async () => {
    if (valueDistSearch.slice(0, 1) !== "*") {
      if (valueDistSearch.length >= 2) {
        setgasNextPage(false);
        setPage(0);
        getDistributor(valueDistSearch);
      }
    }
    if (valueDistSearch === "*") {
      getAllDistributor(originPath);
    }
  };

  const getDistributor = async (value) => {
    setLoading(!loading);
    try {
      const handlePostalAndGroupCode = value.split(":");
      let arrayByPostalCodeAndGroupCode = [];
      let completeArray = [];
      if (handlePostalAndGroupCode.length > 1) {
        if (handlePostalAndGroupCode[1].length > 0) {
          if (handlePostalAndGroupCode[1] === "*") {
            const responseGroupAndPostal = getAllDistributorGroupCodeVisible(handlePostalAndGroupCode[0], originPath);
            const dataGroupAndPostal = (await responseGroupAndPostal).data;

            arrayByPostalCodeAndGroupCode = dataGroupAndPostal.result.items.map(function (items) {
              // return(items.reference)
              var rObj = {
                name: `${items.address?.postal_code} - ${items.name}`,
                id: items.id,
              };
              return rObj;
            });
          } else {
            const responseGroupAndPostal = getAllDistributorPostalCodeAndGroupCode(
              handlePostalAndGroupCode[1],
              handlePostalAndGroupCode[0],
              originPath
            );
            const dataGroupAndPostal = (await responseGroupAndPostal).data;

            arrayByPostalCodeAndGroupCode = dataGroupAndPostal.result.items.map(function (items) {
              // return(items.reference)
              var rObj = {
                name: `${items.address?.postal_code} - ${items.name}`,
                id: items.id,
              };
              return rObj;
            });
          }
        }
      } else {
        const responseByPostalCode = getAllDistributorPostalCode(value, originPath);
        const dataByPostalCode = (await responseByPostalCode).data;

        const fullArray = [...dataByPostalCode?.result?.items];
        completeArray = fullArray.map(function (items) {
          // return(items.reference)
          var rObj = {
            name: `${items.address?.postal_code} - ${items.name}`,
            id: items.id,
          };
          return rObj;
        });
      }
      let arrayOp = [...completeArray, ...arrayByPostalCodeAndGroupCode];
      let hash = {};
      arrayOp = arrayOp.filter((o) => (hash[o.id] ? false : (hash[o.id] = true)));
      setDistributorsArray(arrayOp);

      return arrayOp;
    } catch (error) {
      console.log("error get distributor");
      console.log(error);
      // if (error?.request?.status !== 401 && error?.request?.status !== 403)
      //   handleCreateToast(removeTags(`${content["ALERT-ERROR-GET-DISTRIBUTORS"]}`), "error");
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const getAllDistributor = async () => {
    if (page === 0) setLoading(!loading);
    setLoadingScroll(true);
    try {
      setgasNextPage(true);
      const response = getAllDistributors(originPath, page);

      const data = (await response).data;

      // setProductsArray(data)
      const array1 = data.result.items.map(function (items) {
        // return(items.reference)
        var rObj = {
          name: `${items.address?.postal_code} - ${items.name}`,
          id: items.id,
        };
        return rObj;
      });

      if (array1.length === 0) {
        setgasNextPage(false);
      }
      setDistributorsArray([...distributorsArray, ...array1]);
      setPage((prev) => prev + 1);
      return data;
    } catch (error) {
      console.log("error get distributors");
      console.log(error);
      // if (error?.request?.status !== 401 && error?.request?.status !== 403)
      //   handleCreateToast(removeTags(`${content["ALERT-ERROR-GET-DISTRIBUTORS"]}`), "error");
    } finally {
      console.log("end");
      setLoading(false);
      setLoadingScroll(false);
    }
  };

  const getDistributorID = async (distributorID) => {
    setLoadingDist(!loadingDist);
    try {
      if (distributorID === "0" || distributorID === "undefined") return;
      const response = getDistributorsById(distributorID);
      const data = (await response).data;
      setDistributorsIdArray(data);
      return data;
    } catch (error) {
      console.log("error get distributor");
      console.log(error);
      // if (error?.request?.status !== 401 && error?.request?.status !== 403)
      //   handleCreateToast(removeTags(`${content["ALERT-ERROR-GET-DISTRIBUTORS"]}`), "error");
    } finally {
      console.log("end");
      setLoadingDist(false);
    }
  };

  const handleChangeDist = (e, value) => {
    setValueDist(value?.name);
    setValueDistSelect(value?.name);
    setdistributorID(value?.id);
    getDistributorID(value?.id);
  };

  const handleChangeInputDist = (e) => {
    setValueDist(e?.target?.value);
    setValueDistSearch(e?.target?.value);
    setDistributorsArray([]);
  };

  return {
    getDistributorID,
    distributorsArray,
    distributorsIdArray,
    inputDistributor,
    handleChangeDist,
    loadingDist,
    handleChangeInputDist,
    valueDist,
    valueDistSelect,
    getDistributorsFunction,
    hasNextPage,
    loadingScroll,
  };
};

export default useGetDistributors;
