import { useContext } from "react";
import "./style/dropdownProduct.css";
import AppContext from "../../context/AppContext";
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { removeTags } from "../../helpers/removeHtml";
import { Autocomplete, Box, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
const filter = createFilterOptions();

const DropDownProduct = (props) => {
  // const [selectedItem, setSelectedItem] = useState(props.options[0]);
  const { productIDType, content } = useContext(AppContext);

  return (
    <div>
      <Autocomplete
        selectOnFocus
        blurOnSelect
        fullWidth
        noOptionsText="Aucune correspondance trouvée"
        id="combo-box-demo"
        options={props.options}
        getOptionLabel={(option) => option.reference}
        onChange={props.onChange}
        renderOption={(props, option) => (
          <Box component="div" {...props}>
            <div style={{ fontFamily: "DIN Pro Cond Light", fontSize: "18px", paddingLeft: "24px" }}>{option.reference}</div>
          </Box>
        )}
        ListboxProps={{ style: { maxHeight: 200 } }}
        sx={{
          fontFamily: "DIN Pro Cond Light",
          "& input": {
            padding: "0 !important",
            color: "#000",
          },
        }}
        renderInput={(params) => (
          <TextField
            sx={{
              fontFamily: "DIN Pro Cond Light",
              padding: "0 !important",
              "& .MuiOutlinedInput-root": {
                padding: "0.4em 0.8571428571428571em !important",
                fontFamily: "DIN Pro Cond Light",
                fontSize: "18px",
                color: "#000",
                "& input::placeholder": {
                  color: "#000",
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "#9b9b9b solid 2px",
              },
              "& input::placeholder": {
                fontSize: "18px",
                color: "#000 !important",
              },
            }}
            fullWidth
            {...params}
            onChange={props.handleChangeInputProd}
            value={props.valueProd}
            label=""
            InputLabelProps={{ shrink: false }}
            placeholder={removeTags(`${content["FORM-NEW-WARRANTY-FIRST.STEP-INPUT-KNOW-PLACEHOLDER"]}`)}
          />
        )}
      />
    </div>
  );
};

export default DropDownProduct;
