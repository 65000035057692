import { removeTags } from "../../helpers/removeHtml";
import useGlobalContent from "../../hooks/useGlobalContent";
import { ButtonClean, TabWarranty } from "../atoms";
import { DropDownReasonEx } from "../molecules";
import "./style.css";

export default function TypeBreakSection(props) {
  const { pageStatus, content } = props;
  const { reasonForExchangesArray, selectedTypeCase, setselectedTypeCase } = props;
  const { setPageStatusBefore, setPageStatusAfter, reasonExchangeID } = props;

  const { replaceGlobalContents } = useGlobalContent();

  return (
    <div className="bg-white">
      <TabWarranty pageStatus={pageStatus} />
      <div className="block-main">
        <h5 className="h5-stl-warranty Left py-1">
          <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-THIRD.STEP-INPUT-KNOW-TITLE"]) }}></div>
        </h5>
        <DropDownReasonEx options={reasonForExchangesArray} selectedItem={selectedTypeCase} onSelect={setselectedTypeCase} />
        <div className="py-lg-3 py-3"></div>
        <div className="container-warranty-pad-bt flex-between-bt full-width-bt">
          <div className="full-width-bt flex-start-bt py-2" style={{ textAlign: "left !important" }}>
            <ButtonClean
              message={removeTags(`${content["FORM-NEW-WARRANTY-ALL-INFO-BUTTON-PRE"]}`)}
              onClick={setPageStatusBefore}
              width="120px"
              float="left"
            />
          </div>
          <div className="full-width-bt flex-end-bt py-2">
            <ButtonClean
              message={removeTags(`${content["FORM-NEW-WARRANTY-ALL-INFO-BUTTON-FOLLOWING"]}`)}
              disabled={!reasonExchangeID}
              onClick={setPageStatusAfter}
              width="120px"
              float="right"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
