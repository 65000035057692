import { useContext, useEffect, useState } from "react";
import { ButtonClean, InputForm, LoadingFacom, LoadingPag, ValidationAlert } from "../components";
import AppContext from "../context/AppContext";
import "../components/molecules/style/loginForm.css";
import useQuery from "../helpers/getLocation";
import { updatePassword, verifyResetPasswordEmailAPI } from "../api/facom_services";
import { LocalStorageKeys, getInLocalStorage, setInLocalStorage } from "../helpers/local-storage-manager";
import { removeTags } from "../helpers/removeHtml";
import { useSelector } from "react-redux";
import InputPassword from "../components/atoms/InputPassword";

export default function UpdatePassword(props) {
  let query = useQuery();

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isValidSamePass, setIsValidSamePass] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const [errorMessage, setErrorMessage] = useState(null);
  const { content } = useContext(AppContext);
  const originPath = getInLocalStorage("origin");
  const { profile } = useSelector((state) => state.profileReducer);
  const { language } = profile;

  // if (password === passwordConfirm) {
  //   setIsValidSamePass(true);
  // } else {
  //   setIsValidSamePass(false);
  // }

  const RenderError = () => {
    return (
      <>
        {error && (
          <div className="my-3">
            <ValidationAlert message={errorMessage} variant="error" />
          </div>
        )}
      </>
    );
  };
  const RenderSuccess = () => {
    return (
      <>
        {success && (
          <div className="my-3">
            <ValidationAlert message={removeTags(`${content["SUCCESS-CHANGE-PASSWORD"]}`)} severity="success" />
          </div>
        )}
      </>
    );
  };

  const validateEmail = async () => {
    setLoading((prev) => !prev);
    try {
      if (query.get("code")) {
        await verifyResetPasswordEmailAPI(password, query.get("code"));
        setSuccess(true);
        setError(false);
        setInLocalStorage("updatePassword", true);
        setInLocalStorage("updatePasswordMigrated", false);
        setInLocalStorage("expired", false);
        window.location.replace(originPath ? `/login/${originPath}` : `/login`);
      } else {
        const email = `${getInLocalStorage(LocalStorageKeys.EMAIL)}`;
        await updatePassword(password, email);
        setInLocalStorage(LocalStorageKeys.EMAIL, "");
        setInLocalStorage("updatePassword", false);
        setInLocalStorage("updatePasswordMigrated", true);
        setSuccess(true);
        setInLocalStorage("expired", false);
        setInLocalStorage("updatePasswordMigrated", true);
        window.location.replace(originPath ? `/login/${originPath}` : `/login`);
      }
    } catch (error) {
      console.log("error validating account");
      console.log(error);
      if (error?.response?.data?.metadata?.invalid_fields?.newPassword) {
        setErrorMessage(
          language === "EN"
            ? `${error?.response?.data?.metadata?.invalid_fields?.registrationForm[0]}`
            : "Mot de passe non valide. Effectuez une nouvelle demande de réinitialisation de mot de passe."
        );
      } else if (error?.response?.data?.message) {
        setErrorMessage("Mot de passe non valide. Effectuez une nouvelle demande de réinitialisation de mot de passe.");
      } else {
        setErrorMessage(removeTags(`${content["ALERT-PASSWORD-CONTAIN"]}`));
      }
      setError(true);
      setSuccess(false);
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateEmail();
  };
  const arrowLeft = "< ";
  return (
    <div className="Background_Gray py-5">
      <div className="px-5 mb-5">
        <a href={originPath ? `/login/${originPath}` : `/login`}>
          <h6 className="h6-return" style={{ display: "flex", gap: "10px" }}>
            {arrowLeft}
            <div dangerouslySetInnerHTML={{ __html: content["FORM.ALL.BACK"] }}></div>
          </h6>
        </a>
      </div>

      <div style={{ display: "flex" }}>
        <div className="col-1 col-xl-4"></div>
        <div className="col-10 col-xl-4">
          <div className="py-5 px-4 Background_White">
            <h2 className="h2-stl Center ">
              <div dangerouslySetInnerHTML={{ __html: content["RESET-PASSWORD-TITLE"] }}></div>
            </h2>
            <div className="alert-font">
              <div dangerouslySetInnerHTML={{ __html: content["RESET-PASSWORD-DESCRIPTION"] }}></div>
            </div>

            <div className="line-loggin"></div>
            <div className="row">
              <div className="col-12 col-lg-4">
                <h4 className="h4-stl Rigth-l py-1">
                  <div dangerouslySetInnerHTML={{ __html: content["RESET-PASSWORD-INPUT-PLACEHOLDER"] }}></div>
                </h4>
              </div>
              <div className="col-12 col-lg-6">
                <form action="/" onSubmit={handleSubmit}>
                  <InputPassword
                    type="password"
                    name="password"
                    placeholder={removeTags(`${content["RESET-PASSWORD-INPUT-PLACEHOLDER"]}`)}
                    style={{ heigth: "250px" }}
                    currentValue={password}
                    handleOnInputValue={(data) => {
                      setPassword(data);
                      setIsValid(true);
                    }}
                  />
                </form>
              </div>
              <div className="py-2"></div>
              <div className="col-12 col-lg-4">
                <h4 className="h4-stl Rigth-l pt-3">
                  <div dangerouslySetInnerHTML={{ __html: content["RESET-PASSWORD-CONFIRM-LABEL"] }}></div>
                </h4>
              </div>
              <div className="col-12 col-lg-6">
                <form action="/" onSubmit={handleSubmit}>
                  <InputPassword
                    type="password"
                    name="passworConfirm"
                    placeholder={removeTags(`${content["RESET-PASSWORD-CONFIRM-LABEL"]}`)}
                    style={{ heigth: "250px" }}
                    currentValue={passwordConfirm}
                    handleOnInputValue={(data) => {
                      setPasswordConfirm(data);
                    }}
                  />

                  <div className="py-2"></div>
                  {password.length ? (
                    <div>
                      {password !== passwordConfirm && (
                        <div>
                          <ValidationAlert message={removeTags(`${content["ALERT-PASSWORD-MATCH"]}`)} />
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <RenderError />
                  <RenderSuccess />
                </form>
                <div className="py-1"></div>
                <form action="/" onSubmit={handleSubmit}>
                  <input type="submit" value={removeTags(`${content["RESET-PASSWORD-BUTTON"]}`)} style={{ display: "none" }}></input>
                </form>
                <div className="mt-2">
                  <ButtonClean
                    message={removeTags(`${content["RESET-PASSWORD-BUTTON"]}`)}
                    onClick={() => {
                      validateEmail();
                    }}
                    width="100%"
                    disabled={!isValid || password !== passwordConfirm || password === ""}
                  />
                </div>
              </div>

              <div className="col-0 col-lg-2"></div>
            </div>
          </div>
        </div>
        <div className="col-1 col-xl-4"></div>
        {/* <LoadingPag open={loading} /> */}
      </div>
      {loading && <LoadingFacom />}
    </div>
  );
}
