import React, { useState, useRef, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";

import "../../constants/global.css";
import "./styles/button.css";
import { useMediaQuery } from "@mui/material";

function ButtonMain(props) {
  const matches = useMediaQuery("max-width:450px");
  return (
    <div className="button-font-bold-main">
      <button type="button" style={{ width: "100%", minHeight: "63px" }} className="btn-red py-2">
        <div className="row" style={{ width: "100%", marginLeft: "0" }}>
          <div className="col-11" style={!matches ? { paddingRight: "0", paddingLeft: "0" } : { paddingRight: "0" }}>
            <strong>
              {props.message} <br /> {props.message2}
            </strong>
          </div>
          <div className="col-1" style={!matches ? { paddingRight: "0", paddingLeft: "0" } : { paddingRight: "0" }}>
            {">"}
          </div>
        </div>
      </button>
    </div>
  );
}

export default ButtonMain;
