import "bootstrap/dist/css/bootstrap.css";
import { useContext } from "react";
import { Nav } from "react-bootstrap";
import AppContext from "../../context/AppContext";
import "./styles/NavMenu.css";

export default function NavMenu(props) {
  const { content } = useContext(AppContext);

  return (
    <div>
      <Nav className="justify-content-start" activeKey="/home" style={{ gap: "10px" }}>
        <Nav.Item>
          <Nav.Link href="https://www.facom.fr/" className="text-nav-stl ">
            <div dangerouslySetInnerHTML={{ __html: content["MENU.LOGIN-HOME.BRAND-ITEM"] }}></div>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="https://www.facom.fr/" className="text-nav-stl ">
            <div dangerouslySetInnerHTML={{ __html: content["MENU.LOGIN-HOME.PRODUCTS-ITEM"] }}></div>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="https://www.facom.fr/" eventKey="link-2" className="text-nav-stl ">
            <div dangerouslySetInnerHTML={{ __html: content["MENU.LOGIN-HOME.SOLUTIONS-ITEM"] }}></div>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="https://www.facom.fr/" target="_blanck" className="text-nav-stl ">
            <div dangerouslySetInnerHTML={{ __html: content["HOME.LOGIN-MENU.SERVICES-ITEM"] }}></div>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="https://www.facom.fr/" target="_blanck" className="text-nav-stl ">
            <div dangerouslySetInnerHTML={{ __html: content["MENU.LOGIN-HOME.CONTACT-ITEM"] }}></div>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link href="https://www.facom.fr/" target="_blanck" className="text-nav-stl ">
            <div dangerouslySetInnerHTML={{ __html: content["MENU.LOGIN-HOME.COMMUNITY-ITEM"] }}></div>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  );
}
