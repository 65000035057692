import { useState, useEffect, useContext } from "react";
import { getAllReasonExchanges, createExchange, updateExchangesStatus, updateExchanges, getAllUsersById } from "../api/facom_services";
import { useHistory } from "react-router-dom";

import AppContext from "../context/AppContext";
import { getInLocalStorage, setInLocalStorage } from "../helpers/local-storage-manager";
import { removeTags } from "../helpers/removeHtml";
import useToastMui from "./useToastMui";
import { useSelector } from "react-redux";
const useGetExchanges = (setPageStatus) => {
  const { distributorID, reasonExchangeID, productID, loading, setLoading, content, productIDReference, productIDType } =
    useContext(AppContext);
  const [loadingLast, setLoadingLast] = useState(false);
  const [reasonForExchangesArray, setReasonForExchangesArray] = useState([]);
  const { handleCreateToast } = useToastMui();

  let history = useHistory();
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const Minutes = date.getMinutes();
  const originPath = getInLocalStorage("origin");
  const TodayDate = `${day}/${month}/${year} ${hour}:${Minutes}`;

  useEffect(() => {
    getAllReasonExchange();
  }, []);

  const getAllReasonExchange = async () => {
    try {
      const response = getAllReasonExchanges();
      const data = (await response).data;
      let filteredTableByDisplayOrder = data.result.items.sort((a, b) => (parseInt(a.display_order) > parseInt(b.display_order) ? 1 : -1));
      // setProductsArray(data)
      setReasonForExchangesArray(
        filteredTableByDisplayOrder.map(function (items) {
          var rObj = {
            label: items.label,
            refused: items.is_for_change,
            id: items.id,
          };
          return rObj;
        })
      );
      return data;
    } catch (error) {
      console.log("error get all reasons for exchange");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(removeTags(`${content["ALERT-ERROR-GET-ONE-EXCHANGE"]}`), "error");
    } finally {
      console.log("end");
    }
  };
  const { profile } = useSelector((state) => state.profileReducer);
  const { sub } = profile;

  const [createExchangesID, setCreateExchangesID] = useState("");
  const createNewExchanges = async (details, picturesArray) => {
    setLoadingLast(!loadingLast);
    try {
      const responseUser = getAllUsersById(sub);
      const dataUser = (await responseUser).data;
      if (dataUser) {
        let pictureArrayComplete = [];
        if (picturesArray.length < 4) {
          pictureArrayComplete = picturesArray;
          for (let i = 0; i < 4 - picturesArray.length; i++) {
            pictureArrayComplete = [...pictureArrayComplete, { file_content: null, extension: null }];
          }
        } else {
          pictureArrayComplete = picturesArray;
        }
        const response = await createExchange(
          TodayDate,
          dataUser.result?.customer_data?._id,
          productIDReference?.length > 0 ? productIDReference : productIDType,
          reasonExchangeID,
          details ? details : "",
          pictureArrayComplete
        );
        const data = (await response).data;
        setCreateExchangesID(data.result._id);
        handleCreateToast(removeTags(`${content["ALERT-SUCCESS-CREATE-EXCHANGE"]}`), "success");
        setPageStatus(6);
        return data;
      } else {
        handleCreateToast(removeTags(`${content["ALERT-ERROR-CREATE-EXCHANGE"]}`), "error");
      }
    } catch (error) {
      console.log("error creating new exchange");
      console.log(error);
      setPageStatus(1);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(removeTags(`${content["ALERT-ERROR-CREATE-EXCHANGE"]}`), "error");
    } finally {
      console.log("end");
      //   setPageStatus(6);
      setLoadingLast(false);
    }
  };

  const updateToSpecificStatus = async (button) => {
    if (distributorID) {
      try {
        const responseUser = getAllUsersById(sub);
        const dataUser = (await responseUser).data;
        if (dataUser) {
          await updateExchangesInfo(dataUser);
          const response = updateExchangesStatus(createExchangesID, "Submitted");
          const data = (await response).data;
          handleCreateToast(removeTags(`${content["ALERT-SUCCESS-CREATE-EXCHANGE-SUBMITTED"]}`), "success");
          if (button) {
            history.push(`/dashboard/${originPath}`);
          } else {
            setInLocalStorage("recreate", "yes");
            window.location.replace(`/demand/${originPath}`);
          }
          return data;
        } else {
          handleCreateToast(removeTags(`${content["ALERT-ERROR-UPDATE-EXCHANGE"]}`), "error");
        }
      } catch (error) {
        console.log("error updated");
        console.log(error);
        if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(error.response.data.message, "error");
      } finally {
        setLoading(false);
        console.log("end");
      }
    }
  };

  //UPDATE ALL INFO
  const updateExchangesInfo = async (userArray) => {
    if (distributorID) {
      setLoading(!loading);
      try {
        const response = updateExchanges(
          createExchangesID,
          userArray.result?.customer_data?._id,
          distributorID,
          productIDReference?.length > 0 ? productIDReference : productIDType,
          reasonExchangeID
        );
        const data = (await response).data;
        return data;
      } catch (error) {
        console.log("error update info");
        console.log(error);
        if (error?.request?.status !== 401 && error?.request?.status !== 403) handleCreateToast(error.response.data.message, "error");
      } finally {
        setLoading(false);
        console.log("end");
      }
    }
  };

  return {
    createNewExchanges,
    updateToSpecificStatus,
    reasonForExchangesArray,
    loadingLast,
  };
};

export default useGetExchanges;
