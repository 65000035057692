import { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AppContext from "../../context/AppContext";

import "../../constants/global.css";
import "./style/loginForm.css";
import "../molecules/style/loginForm.css";

import Button from "../atoms/Button";
import FormInitialUser from "../atoms/FormInitialUser";
import FormLastUser from "../atoms/FormLastUser";
import FormParticularUser from "../atoms/FormParticularUser";
import FormProfessionalUser from "../atoms/FormProfessionalUser";
import DropDownWhite from "../atoms/DropDownWhite";
import Form from "react-bootstrap/Form";
import { createNewCustomerProfessional, createNewCustomerPersonal } from "../../api/facom_services";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./style/moleculesStyle.css";
import { LoadingFacom } from "../atoms";
import { LocalStorageKeys, getInLocalStorage, setInLocalStorage } from "../../helpers/local-storage-manager";
import { removeTags } from "../../helpers/removeHtml";
import useToastMui from "../../hooks/useToastMui";

function ProfessionalForm(props) {
  let history = useHistory();
  const { handleCreateToast } = useToastMui();
  const { profile } = useSelector((state) => state.profileReducer);
  const { language } = profile;
  const { content } = useContext(AppContext);

  const originPath = getInLocalStorage("origin");

  const options = [
    removeTags(`${content["OPTIONS-PROFESSION-1"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-2"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-3"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-4"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-5"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-6"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-7"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-8"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-9"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-10"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-11"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-12"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-13"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-14"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-15"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-16"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-17"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-18"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-19"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-20"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-21"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-22"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-23"]}`),
  ];

  const { zipCode, companyName, address, city, firstName, lastName, email, mobilePhone, isValid, isValidSamePass } = useContext(AppContext);
  const { createPassword, setCreatePassword, createPasswordConfirm, setCreatePasswordConfirm } = useContext(AppContext);

  const [selectedProfession, setSelectedProfession] = useState(options[0]);
  const [checkConditions, setcheckConditions] = useState(false);
  const [checkAcceptInfo, setcheckAcceptInfo] = useState(false);

  const [loading, setLoading] = useState(false);
  const [matchingOptions, setMatchingOptions] = useState(options);

  const createUserProfessional = async () => {
    setLoading(true);
    if (isValid && isValidSamePass) {
      if (firstName && lastName && email && mobilePhone && companyName && zipCode && selectedProfession && createPassword) {
        if (createPassword === createPasswordConfirm) {
          if (checkConditions) {
            try {
              const response = createNewCustomerProfessional(
                "Professional",
                firstName,
                lastName,
                email,
                mobilePhone,
                zipCode,
                companyName,
                selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-1"]}`)
                  ? "Industry"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-2"]}`)
                  ? "Mechanic"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-3"]}`)
                  ? "Exterior renovation"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-4"]}`)
                  ? "Interior renovation"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-5"]}`)
                  ? "Coachbuilder"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-6"]}`)
                  ? "Carpenter"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-7"]}`)
                  ? "Coppersmith"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-8"]}`)
                  ? "Roofer"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-9"]}`)
                  ? "Electrician"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-10"]}`)
                  ? "Ironworker"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-11"]}`)
                  ? "Builder"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-12"]}`)
                  ? "Wood shaper"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-13"]}`)
                  ? "Toolmaker"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-14"]}`)
                  ? "Building worker"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-15"]}`)
                  ? "House painter"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-16"]}`)
                  ? "Plumer"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-17"]}`)
                  ? "Locksmith"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-18"]}`)
                  ? "Welder"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-19"]}`)
                  ? "Stonerutter"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-20"]}`)
                  ? "Electrician technician"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-21"]}`)
                  ? "Lathe milling"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-22"]}`)
                  ? "Other craftman"
                  : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-23"]}`)
                  ? "Others"
                  : "Others",
                createPassword,
                checkAcceptInfo,
                checkConditions
              );
              const data = (await response).data;
              handleCreateToast(removeTags(`${content["ALERT-SUCCESS-CREATE-ACCOUNT"]}`), "success");
              setInLocalStorage(LocalStorageKeys.EMAIL, email);
              history.push(`/email-verification/${originPath}`);
              return data;
            } catch (error) {
              console.log("error create User");
              if (error?.response?.data?.metadata?.invalid_fields?.emailAddress) {
                handleCreateToast(
                  language === "EN"
                    ? `${error?.response?.data?.metadata?.invalid_fields?.emailAddress[0]}`
                    : "L'adresse e-mail est déjà utilisée.",
                  "error"
                );
              } else if (error?.response?.data?.metadata?.invalid_fields?.registrationForm) {
                handleCreateToast(
                  language === "EN"
                    ? `${error?.response?.data?.metadata?.invalid_fields?.registrationForm[0]}`
                    : "Tentative de mise à jour d'une valeur en double",
                  "error"
                );
              } else if (error?.response?.data?.metadata?.invalid_fields?.newPassword) {
                handleCreateToast(
                  language === "EN"
                    ? `${error?.response?.data?.metadata?.invalid_fields?.registrationForm[0]}`
                    : "Minimum 8 caractères. Au moins 1 chiffre ou caractère spécial. Évitez de mettre 3 (ou plus) caractères identiques (aaa ou 111).",
                  "error"
                );
              } else {
                handleCreateToast(removeTags(`${content["ALERT-ERROR-CREATE-ACCOUNT"]}`), "error");
              }
              console.log(error);
            } finally {
              console.log("end");
              setLoading(false);
            }
          }
        }
      }
    } else {
      handleCreateToast(removeTags(`${content["ALERT-PASSWORD-MATCH"]}`), "error");
    }
    setLoading(false);
  };

  const createUserParticular = async () => {
    setLoading(true);
    if (isValid && isValidSamePass) {
      if (firstName && lastName && email && mobilePhone && createPassword) {
        if (createPassword === createPasswordConfirm) {
          if (checkConditions) {
            try {
              const { data } = await createNewCustomerPersonal(
                "Individual",
                firstName,
                lastName,
                email,
                mobilePhone,
                address,
                zipCode,
                city,
                createPassword,
                checkAcceptInfo,
                checkConditions
              );
              handleCreateToast(removeTags(`${content["ALERT-SUCCESS-CREATE-ACCOUNT"]}`), "success");
              setInLocalStorage(LocalStorageKeys.EMAIL, email);
              history.push(`/email-verification/${originPath}`);
              return data;
            } catch (error) {
              console.log("error create User");
              console.log(error);
              if (error?.response?.data?.metadata?.invalid_fields?.emailAddress) {
                handleCreateToast(
                  language === "EN"
                    ? `${error?.response?.data?.metadata?.invalid_fields?.emailAddress[0]}`
                    : "L'adresse e-mail est déjà utilisée.",
                  "error"
                );
              } else if (error?.response?.data?.metadata?.invalid_fields?.newPassword) {
                handleCreateToast(
                  language === "EN"
                    ? `${error?.response?.data?.metadata?.invalid_fields?.registrationForm[0]}`
                    : "Minimum 8 caractères. Au moins 1 chiffre ou caractère spécial. Évitez de mettre 3 (ou plus) caractères identiques (aaa ou 111).",
                  "error"
                );
              } else if (error?.response?.data?.metadata?.invalid_fields?.registrationForm) {
                handleCreateToast(
                  language === "EN"
                    ? `${error?.response?.data?.metadata?.invalid_fields?.registrationForm[0]}`
                    : "Tentative de mise à jour d'une valeur en double",
                  "error"
                );
              } else {
                handleCreateToast(removeTags(`${content["ALERT-ERROR-CREATE-ACCOUNT"]}`), "error");
              }
            } finally {
              console.log("end");
            }
          }
        }
      }
    } else {
      handleCreateToast(removeTags(`${content["ALERT-PASSWORD-MATCH"]}`), "error");
    }
    setLoading(false);
  };

  const renderProfessionalForm = () => {
    return (
      <div className="row">
        {/* Falta sacar lo que se escribe*/}
        <FormProfessionalUser />
        <div className="col-12 col-lg-4 px-lg-0 ">
          <h4 className="h4-stl Rigth-l">
            <div dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PROFESSIONAL.JOB"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-8 pt-0 px-lg-2">
          <DropDownWhite
            options={matchingOptions}
            selectedItem={selectedProfession}
            onSelect={setSelectedProfession}
            matchingOptions={matchingOptions}
            setMatchingOptions={(data) => setMatchingOptions(data)}
          />
        </div>
      </div>
    );
  };

  const renderParticularForm = () => {
    return (
      <div className="row">
        <FormParticularUser />
      </div>
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    props.typeOfClient === "Professional" ? createUserProfessional() : createUserParticular();
  };

  return (
    <div className="Background_White">
      <h2 className="h2-stl Center ">
        <div
          dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PROFESSIONAL.FORM-TITLE"] }}
          className="second-title-box spacing"
        ></div>
      </h2>
      <div className="line-loggin"></div>
      <form action="/" onSubmit={handleSubmit}>
        <div className="row">
          <FormInitialUser />
        </div>
        {props.typeOfClient === "Professional" && renderProfessionalForm()}
        {props.typeOfClient === "Particular" && renderParticularForm()}
        <div className="row">
          <FormLastUser />
        </div>
        <div className="row">
          <div className="col-12 col-lg-4 mt-3 padding-form"></div>
          <div className="col-12 col-lg-8 mt-1 padding-form">
            <div className="mb-3" style={{ display: "flex", textAlign: "justify" }}>
              <Form.Check
                required
                type="checkbox"
                id={`default-cond-term`}
                onChange={(event) => {
                  setcheckConditions(event.target.checked);
                }}
              />
              <div style={{ paddingLeft: "10px" }}>
                <h6 className="h6-stl">
                  <div dangerouslySetInnerHTML={{ __html: content["FORM.ALL.CONDITIONS"] }} className="link-warranty"></div>
                </h6>
              </div>
            </div>
            <div style={{ display: "flex", textAlign: "justify" }}>
              <Form.Check
                type="checkbox"
                id={`default-accept-term`}
                onChange={(event) => {
                  setcheckAcceptInfo(event.target.checked);
                }}
              />
              <div style={{ paddingLeft: "10px" }}>
                <h6 className="h6-stl">
                  <div dangerouslySetInnerHTML={{ __html: content["FORM.ALL.INFORMATION"] }}></div>
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-0 col-lg-4"></div>
          <div className="col-12 col-lg-8">
            <div className="py-1">
              <Button
                type="submit"
                message={removeTags(`${content["FORM.REGISTER-PROFESSIONAL.FORM-TITLE"]}`)}
                // onClick={() => createUserParticular()}
              />
            </div>
          </div>
          {loading ? (
            <div className="py-3 mt-3">
              <LoadingFacom />
            </div>
          ) : (
            <></>
          )}
        </div>
      </form>
    </div>
  );
}

export default ProfessionalForm;
