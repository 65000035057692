import React, { useContext } from "react";
import "./style/dropdownSku.css";
import AppContext from "../../context/AppContext";
import { Autocomplete, Box, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { removeTags } from "../../helpers/removeHtml";
const filter = createFilterOptions();

const DropDownSKU = (props) => {
  const { content } = useContext(AppContext);
  return (
    <Autocomplete
      selectOnFocus
      blurOnSelect
      fullWidth
      loading={props.loadingProd}
      noOptionsText="Aucune correspondance trouvée"
      id="combo-box-demo"
      options={props.options}
      getOptionLabel={(option) => option.reference}
      onChange={props.onChange}
      renderOption={(props, option) => (
        <Box component="div" {...props}>
          <div style={{ fontFamily: "DIN Pro Cond Light", fontSize: "18px", paddingLeft: "24px" }}>{option.reference}</div>
        </Box>
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;

        // Suggest the creation of a new value
        let isExisting = options.some((option) => inputValue === option.reference);

        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
          });
        }
        if (inputValue === "*") {
          return options;
        }
        if (options.length === 0) {
          return [];
        }
        if (filtered[filtered.length - 1]?.id === undefined) {
          filtered.pop();
        }
        return filtered;
      }}
      ListboxProps={{ style: { maxHeight: 200 } }}
      sx={{
        fontFamily: "DIN Pro Cond Light",
        "& input": {
          padding: "0 !important",
          color: "#000",
        },
      }}
      renderInput={(params) => (
        <TextField
          sx={{
            fontFamily: "DIN Pro Cond Light",
            padding: "0 !important",
            "& .MuiOutlinedInput-root": {
              padding: "0.4em 0.8571428571428571em !important",
              fontFamily: "DIN Pro Cond Light",
              fontSize: "18px",
              color: "#000",
              "& input::placeholder": {
                color: "#000",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "#9b9b9b solid 2px",
            },
            "& input::placeholder": {
              fontSize: "18px",
              color: "#000 !important",
            },
          }}
          fullWidth
          {...params}
          onChange={props.handleChangeInput}
          value={props.value}
          label=""
          InputLabelProps={{ shrink: false }}
          placeholder={removeTags(`${content["FORM-NEW-WARRANTY-FIRST.STEP-INPUT-KNOW-PLACEHOLDER"]}`)}
        />
      )}
    />
  );
};

export default DropDownSKU;
