import { useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";

// COMPONENTS
import ProfessionalForm from "../components/molecules/ProfessionalFrom";
import { Link } from "react-router-dom";
// CSS
import "../constants/global.css";
import "./styles/login.css";
import "../components/molecules/style/loginForm.css";
import AppContext from "../context/AppContext";
import { getInLocalStorage, setInLocalStorage } from "../helpers/local-storage-manager";

function ParticularRegister(props) {
  const arrowLeft = "< ";
  const { content } = useContext(AppContext);

  const originPath = getInLocalStorage("origin");
  useEffect(() => {
    window.scroll(0, 0);
    if (props.origin) {
      setInLocalStorage("origin", props.origin);
    } else {
      setInLocalStorage("origin", "");
    }
  }, []);

  return (
    <div className="professional-account content">
      <div className="Background_Gray" style={{ margin: "0" }}>
        <a href={`/login/${originPath}`}>
          <h6 className="h6-return" style={{ display: "flex", gap: "10px" }}>
            {arrowLeft}
            <div dangerouslySetInnerHTML={{ __html: content["FORM.ALL.BACK"] }}></div>
          </h6>
        </a>
        <h2 className="h1-stl Center pt-lg-5 pt-4 ">
          <div dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PERSONAL.TITLE"] }} className="main-titles"></div>
        </h2>
        <h3 className="h3-stl Center py-1 ">
          <div dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PERSONAL.SUB-TITLE"] }} className="second-title"></div>
        </h3>
        <div className="container">
          <div className="row">
            <div className="d-flex col-md-offset-3 col-md-6 col-sm-12 bg-white">
              <ProfessionalForm typeOfClient="Particular" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParticularRegister;
