import { useContext, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AppContext from "../context/AppContext";

// COMPONENTS
import Button from "../components/atoms/Button";
import { useSelector } from "react-redux";
import { getAllUsersById, updateUsers, setUserPassword, updateUserProfessional } from "../api/facom_services";
import LoadingFacom from "../components/atoms/LoadingFacom";
import InputForm from "../components/atoms/InputForm";
import DropDownCities from "../components/atoms/DropDownCities";
import { useHistory } from "react-router-dom";
// CSS
import "../constants/global.css";
import "./styles/login.css";
import ValidationAlert from "../components/atoms/ValidationAlert";
import { DropDownWhite } from "../components";
import useGlobalContent from "../hooks/useGlobalContent";
import { removeTags } from "../helpers/removeHtml";
import { getInLocalStorage, setInLocalStorage } from "../helpers/local-storage-manager";
import useToastMui from "../hooks/useToastMui";
import InputPassword from "../components/atoms/InputPassword";
import { Alert } from "@mui/material";

const listaPaysOptions = ["France", "Spain", "United Kingdom", "Germany"];

function PersonalInfoForm(props) {
  const { profile } = useSelector((state) => state.profileReducer);
  const { sub } = profile;
  const { handleCreateToast } = useToastMui();
  let history = useHistory();
  const {
    isValidUpdate,
    setIsValidUpdate,
    isValidSamePassUpdate,
    setIsValidSamePassUpdate,
    setCreatePassword,
    setCreatePasswordConfirm,
    setGlobalAlertMessage,
    setGlobalAlert,
  } = useContext(AppContext);

  const { content } = useContext(AppContext);

  const options = [
    removeTags(`${content["OPTIONS-PROFESSION-1"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-2"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-3"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-4"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-5"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-6"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-7"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-8"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-9"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-10"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-11"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-12"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-13"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-14"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-15"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-16"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-17"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-18"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-19"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-20"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-21"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-22"]}`),
    removeTags(`${content["OPTIONS-PROFESSION-23"]}`),
  ];

  const originPath = getInLocalStorage("origin");

  useEffect(() => {
    window.scroll(0, 0);
    if (props.origin) {
      setInLocalStorage("origin", props.origin);
    } else {
      setInLocalStorage("origin", "");
    }
    setCreatePassword("");
    setCreatePasswordConfirm("");
  }, []);

  const [selectedCountry, setSelectedCountry] = useState(listaPaysOptions[0]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [workPhone, setWorkPhone] = useState("-");
  const [homePhone, setHomePhone] = useState("-");
  const [faxPhone, setFaxPhone] = useState("-");
  const [address, setAddress] = useState("");
  const [addressComplement, setAddressComplement] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [title, setTitle] = useState("");
  const [preferedLanguage, setPreferedlanguage] = useState("");
  const [languageActive, setLanguageActive] = useState("");
  const [status, setStatus] = useState("");
  const [selectedProfession, setSelectedProfession] = useState(options[0]);
  const [companyName, setCompanyName] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [matchingOptions, setMatchingOptions] = useState(options);

  //Alerts
  if (password === passwordConfirm) {
    setIsValidSamePassUpdate(true);
  } else {
    setIsValidSamePassUpdate(false);
  }

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    try {
      getUser();
    } catch (error) {
    } finally {
    }
  }, []);

  //!-------------------USER------------------------

  const getUser = async () => {
    setLoading(!loading);
    try {
      const { data } = await getAllUsersById(sub);

      setFirstName(data.result?.first_name);
      setLastName(data.result?.last_name);
      setMobilePhone(data.result?.company_phone_number);
      setWorkPhone(data.result?.work_phone);
      setHomePhone(data.result?.home_phone);
      setEmail(data.result?.email);
      // setFaxPhone(data.result?.work_phone)
      setAddress(data.result?.address?.address);
      setAddressComplement(data.result?.address?.address_complement);
      setPostalCode(data.result?.address?.zipcode);
      setCity(data.result?.address?.city);
      setFaxPhone(data?.result?.fax_number ? data?.result?.fax_number : "");
      setCountry(
        data.result?.address?.country === "FR"
          ? "France"
          : data.result?.address?.country === "ES"
          ? "Spain"
          : data.result?.address?.country === "UK"
          ? "United Kingdom"
          : data.result?.address?.country === "DE"
          ? "Germany"
          : ""
      );
      setTitle(data.result?.title);
      setPreferedlanguage(data.result?.users_settings?.preferred_language);
      setLanguageActive(data.result?.users_settings?.lenguage_active);
      setStatus(data.result?.status);
      setPassword("");
      setPasswordConfirm("");
      setSelectedProfession(
        data.result.customer_data.business === "Industry"
          ? removeTags(`${content["OPTIONS-PROFESSION-1"]}`)
          : data.result.customer_data.business === "Mechanic"
          ? removeTags(`${content["OPTIONS-PROFESSION-2"]}`)
          : data.result.customer_data.business === "Exterior renovation"
          ? removeTags(`${content["OPTIONS-PROFESSION-3"]}`)
          : data.result.customer_data.business === "Interior renovation"
          ? removeTags(`${content["OPTIONS-PROFESSION-4"]}`)
          : data.result.customer_data.business === "Coachbuilder"
          ? removeTags(`${content["OPTIONS-PROFESSION-5"]}`)
          : data.result.customer_data.business === "Carpenter"
          ? removeTags(`${content["OPTIONS-PROFESSION-6"]}`)
          : data.result.customer_data.business === "Coppersmith"
          ? removeTags(`${content["OPTIONS-PROFESSION-7"]}`)
          : data.result.customer_data.business === "Roofer"
          ? removeTags(`${content["OPTIONS-PROFESSION-8"]}`)
          : data.result.customer_data.business === "Electrician"
          ? removeTags(`${content["OPTIONS-PROFESSION-9"]}`)
          : data.result.customer_data.business === "Ironworker"
          ? removeTags(`${content["OPTIONS-PROFESSION-10"]}`)
          : data.result.customer_data.business === "Builder"
          ? removeTags(`${content["OPTIONS-PROFESSION-11"]}`)
          : data.result.customer_data.business === "Wood shaper"
          ? removeTags(`${content["OPTIONS-PROFESSION-12"]}`)
          : data.result.customer_data.business === "Toolmaker"
          ? removeTags(`${content["OPTIONS-PROFESSION-13"]}`)
          : data.result.customer_data.business === "Building worker"
          ? removeTags(`${content["OPTIONS-PROFESSION-14"]}`)
          : data.result.customer_data.business === "House painter"
          ? removeTags(`${content["OPTIONS-PROFESSION-15"]}`)
          : data.result.customer_data.business === "Plumer"
          ? removeTags(`${content["OPTIONS-PROFESSION-16"]}`)
          : data.result.customer_data.business === "Locksmith"
          ? removeTags(`${content["OPTIONS-PROFESSION-17"]}`)
          : data.result.customer_data.business === "Welder"
          ? removeTags(`${content["OPTIONS-PROFESSION-18"]}`)
          : data.result.customer_data.business === "Stonerutter"
          ? removeTags(`${content["OPTIONS-PROFESSION-19"]}`)
          : data.result.customer_data.business === "Electrician technician"
          ? removeTags(`${content["OPTIONS-PROFESSION-20"]}`)
          : data.result.customer_data.business === "Lathe milling"
          ? removeTags(`${content["OPTIONS-PROFESSION-21"]}`)
          : data.result.customer_data.business === "Other craftman"
          ? removeTags(`${content["OPTIONS-PROFESSION-22"]}`)
          : data.result.customer_data.business === "Others"
          ? removeTags(`${content["OPTIONS-PROFESSION-23"]}`)
          : ""
      );
      setCompanyName(data.result.customer_data.company_name);
      setCustomerId(data.result.customer_data._id);
      setCustomerType(data.result.customer_data.account_type);
      return data;
    } catch (error) {
      console.log("error get products");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(removeTags(`${content["ALERT-ERROR-GET-USER"]}`), "error");
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const updateInfo = async () => {
    setLoading(!loading);

    try {
      if (customerType === "Professional") {
        if (firstName && lastName && email && mobilePhone && selectedProfession && companyName) {
          await updateUserProfessional(
            customerId,
            "Professional",
            selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-1"]}`)
              ? "Industry"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-2"]}`)
              ? "Mechanic"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-3"]}`)
              ? "Exterior renovation"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-4"]}`)
              ? "Interior renovation"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-5"]}`)
              ? "Coachbuilder"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-6"]}`)
              ? "Carpenter"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-7"]}`)
              ? "Coppersmith"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-8"]}`)
              ? "Roofer"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-9"]}`)
              ? "Electrician"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-10"]}`)
              ? "Ironworker"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-11"]}`)
              ? "Builder"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-12"]}`)
              ? "Wood shaper"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-13"]}`)
              ? "Toolmaker"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-14"]}`)
              ? "Building worker"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-15"]}`)
              ? "House painter"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-16"]}`)
              ? "Plumer"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-17"]}`)
              ? "Locksmith"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-18"]}`)
              ? "Welder"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-19"]}`)
              ? "Stonerutter"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-20"]}`)
              ? "Electrician technician"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-21"]}`)
              ? "Lathe milling"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-22"]}`)
              ? "Other craftman"
              : selectedProfession === removeTags(`${content["OPTIONS-PROFESSION-23"]}`)
              ? "Others"
              : "Others",
            "",
            "",
            firstName,
            lastName,
            email,
            workPhone,
            homePhone,
            mobilePhone,
            faxPhone,
            address ? address : "",
            addressComplement ? addressComplement : "",
            "",
            "",
            city,
            country === "France"
              ? "FR"
              : country === "Spain"
              ? "ES"
              : country === "United Kingdom"
              ? "UK"
              : country === "Germany"
              ? "DE"
              : "",
            postalCode ? postalCode : "",
            companyName
          );
        }
      } else {
        if (firstName && lastName && email && mobilePhone) {
          await updateUsers(
            customerId,
            firstName,
            lastName,
            email,
            workPhone,
            mobilePhone,
            homePhone,
            address ? address : "",
            addressComplement ? addressComplement : "",
            city ? city : "",
            country === "France"
              ? "FR"
              : country === "Spain"
              ? "ES"
              : country === "United Kingdom"
              ? "UK"
              : country === "Germany"
              ? "DE"
              : "",
            postalCode ? postalCode : "",
            title,
            languageActive,
            preferedLanguage,
            status,
            faxPhone,
            companyName
          );
        }
      }
      if (password.length) {
        if (isValidUpdate && isValidSamePassUpdate) {
          if (password === passwordConfirm) {
            setUserPassword(sub, password);
          }
        } else {
          handleCreateToast(removeTags(`${content["ALERT-ERROR-UPDATE-1"]}`), "error");
        }
      }
      handleCreateToast(removeTags(`${content["ALERT-SUCCESS-UPDATE-USER"]}`), "success");
      history.push(`/dashboard/${originPath}`);
    } catch (error) {
      console.log("error update");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(removeTags(`${content["ALERT-ERROR-UPDATE-1"]}`), "error");
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const arrowLeft = "< ";

  const { replaceGlobalContents } = useGlobalContent();

  return (
    <div className="professional-account content">
      <div className="Background_Gray" style={{ margin: "0" }}>
        <a href={`/login/${originPath}`}>
          <h6 className="h6-return" style={{ display: "flex", gap: "10px" }}>
            {arrowLeft}
            <div dangerouslySetInnerHTML={{ __html: content["FORM.ALL.BACK"] }}></div>
          </h6>
        </a>
        {!loading ? (
          <div className="container">
            <div className="row ">
              <div className="col-12">
                <h3 className="h3-stl Center pt-3 ">
                  <div
                    dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-PERSONAL-INFO.TITLE"]) }}
                    className="main-titles spacing"
                  ></div>
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-offset-3 col-md-6 col-sm-12 bg-white">
                <div className="Background_White">
                  <div className="row">
                    <div>
                      {/* parte 1 */}
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <h4 className="h4-stl Rigth-l py-1">
                            <div
                              dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM.PERSONAL.INFO.FIRST-NAME"]) }}
                            ></div>
                          </h4>
                        </div>
                        <div className="col-12 col-lg-8">
                          <InputForm
                            type="text"
                            name="firstName"
                            placeholder={removeTags(`${content["FORM.PERSONAL.INFO.FIRST-NAME"]}`)}
                            style={{ heigth: "250px" }}
                            currentValue={firstName}
                            handleOnInputValue={(data) => {
                              setFirstName(data);
                            }}
                          />
                        </div>
                      </div>
                      <div className="py-1"></div>
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <h4 className="h4-stl Rigth-l py-1">
                            <strong>
                              <div
                                dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM.PERSONAL.INFO.LAST-NAME"]) }}
                              ></div>
                            </strong>
                          </h4>
                        </div>
                        <div className="col-12 col-lg-8">
                          <InputForm
                            type="text"
                            name="userLastName"
                            placeholder={removeTags(`${content["FORM.PERSONAL.INFO.LAST-NAME"]}`)}
                            style={{ heigth: "250px" }}
                            currentValue={lastName}
                            handleOnInputValue={(data) => {
                              setLastName(data);
                            }}
                          />
                        </div>
                      </div>

                      {/* parte 2 */}

                      {customerType === "Professional" && (
                        <div className="mt-4">
                          <div className="row">
                            <div className="col-12 col-lg-4 pt-2">
                              <h4 className="h4-stl Rigth-l py-1">
                                <div
                                  dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM.REGISTER-PROFESSIONAL.SIRET"]) }}
                                ></div>
                              </h4>
                            </div>
                            <div className="col-12 col-lg-8 pt-2">
                              <InputForm
                                type="text"
                                name="Numéro SIRET"
                                placeholder={removeTags(`${content["FORM.REGISTER-PROFESSIONAL.SIRET"]}`)}
                                style={{ heigth: "250px" }}
                                currentValue={companyName}
                                handleOnInputValue={(data) => {
                                  setCompanyName(data);
                                }}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 col-lg-4 pt-2">
                              <h4 className="h4-stl Rigth-l py-1">
                                <div
                                  dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM.REGISTER-PROFESSIONAL.JOB"]) }}
                                ></div>
                              </h4>
                            </div>
                            <div className="col-12 col-lg-8 pt-2">
                              <DropDownWhite
                                options={options}
                                selectedItem={selectedProfession}
                                onSelect={setSelectedProfession}
                                matchingOptions={matchingOptions}
                                setMatchingOptions={(data) => setMatchingOptions(data)}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {/* parte 3 */}
                      <div className="py-3"></div>
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <h4 className="h4-stl Rigth-l py-1">
                            <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM.PERSONAL.INFO.EMAIL"]) }}></div>
                          </h4>
                        </div>
                        <div className="col-12 col-lg-8">
                          <InputForm
                            type="email"
                            name="userEmail"
                            readOnly
                            placeholder={removeTags(`${content["FORM.PERSONAL.INFO.EMAIL"]}`)}
                            style={{ heigth: "250px", background: "rgb(233, 236, 239)" }}
                            currentValue={email}
                            handleOnInputValue={(data) => {
                              setEmail(data);
                            }}
                          />
                        </div>
                      </div>
                      <div className="py-1"></div>
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <h4 className="h4-stl Rigth-l py-1">
                            <div
                              dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM.PERSONAL.INFO.MOBILE-PHONE"]) }}
                            ></div>
                          </h4>
                        </div>
                        <div className="col-12 col-lg-8">
                          <InputForm
                            type="text"
                            name={removeTags(`${content["FORM.PERSONAL.INFO.MOBILE-PHONE"]}`)}
                            placeholder="Teléphone mobile"
                            style={{ heigth: "250px" }}
                            currentValue={mobilePhone}
                            handleOnInputValue={(data) => {
                              setMobilePhone(data);
                            }}
                          />
                        </div>
                      </div>
                      <div className="py-1"></div>
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <h4 className="h4-stl Rigth-l py-1">
                            <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM.PERSONAL.INFO.TEL-FIX"]) }}></div>
                          </h4>
                        </div>
                        <div className="col-12 col-lg-8">
                          <InputForm
                            type="text"
                            name="userPhoneFix"
                            placeholder={removeTags(`${content["FORM.PERSONAL.INFO.TEL-FIX"]}`)}
                            style={{ heigth: "250px" }}
                            currentValue={homePhone}
                            handleOnInputValue={(data) => {
                              setHomePhone(data);
                            }}
                          />
                        </div>
                      </div>
                      <div className="py-1"></div>
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <h4 className="h4-stl Rigth-l py-1">
                            <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM.PERSONAL.INFO.TEL-JOB"]) }}></div>
                          </h4>
                        </div>
                        <div className="col-12 col-lg-8">
                          <InputForm
                            type="text"
                            name="userPhoneJob"
                            placeholder={removeTags(`${content["FORM.PERSONAL.INFO.TEL-JOB"]}`)}
                            style={{ heigth: "250px" }}
                            currentValue={workPhone}
                            handleOnInputValue={(data) => {
                              setWorkPhone(data);
                            }}
                          />
                        </div>
                      </div>
                      <div className="py-1"></div>
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <h4 className="h4-stl Rigth-l py-1">
                            <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM.PERSONAL.INFO.FAX"]) }}></div>
                          </h4>
                        </div>
                        <div className="col-12 col-lg-8">
                          <InputForm
                            type="text"
                            name="userFax"
                            placeholder={removeTags(`${content["FORM.PERSONAL.INFO.FAX"]}`)}
                            style={{ heigth: "250px" }}
                            currentValue={faxPhone}
                            handleOnInputValue={(data) => {
                              setFaxPhone(data);
                            }}
                          />
                        </div>
                      </div>

                      {/* parte 4 */}
                      <div className="py-3"></div>
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <h4 className="h4-stl Rigth-l py-1">
                            {customerType === "Professional" ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["FORM.PERSONAL.INFO.ADDRESS"]?.replace("*", "")),
                                }}
                              ></div>
                            ) : (
                              <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM.PERSONAL.INFO.ADDRESS"]) }}></div>
                            )}
                          </h4>
                        </div>
                        <div className="col-12 col-lg-8">
                          <InputForm
                            type="text"
                            name="userAdress"
                            placeholder={
                              customerType === "Professional"
                                ? removeTags(`${content["FORM.PERSONAL.INFO.ADDRESS"]?.replace("*", "")}`)
                                : removeTags(`${content["FORM.PERSONAL.INFO.ADDRESS"]}`)
                            }
                            style={{ heigth: "250px" }}
                            currentValue={address}
                            handleOnInputValue={(data) => {
                              setAddress(data);
                            }}
                          />
                        </div>
                      </div>
                      <div className="py-1"></div>
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <h4 className="h4-stl Rigth-l py-1">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: replaceGlobalContents(content["FORM.PERSONAL.INFO.ADDRESS-COMPLEMENT"]),
                              }}
                            ></div>
                          </h4>
                        </div>
                        <div className="col-12 col-lg-8">
                          <InputForm
                            type="text"
                            name="userAdress2"
                            placeholder={removeTags(`${content["FORM.PERSONAL.INFO.ADDRESS-COMPLEMENT"]}`)}
                            style={{ heigth: "250px" }}
                            currentValue={addressComplement}
                            handleOnInputValue={(data) => {
                              setAddressComplement(data);
                            }}
                          />
                        </div>
                      </div>
                      <div className="py-1"></div>
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <h4 className="h4-stl Rigth-l py-1">
                            <div
                              dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM.PERSONAL.INFO-POSTAL-CODE"]) }}
                            ></div>
                          </h4>
                        </div>
                        <div className="col-12 col-lg-8">
                          <InputForm
                            type="text"
                            name="userPostalCode"
                            placeholder={removeTags(`${content["FORM.PERSONAL.INFO-POSTAL-CODE"]}`)}
                            style={{ heigth: "250px" }}
                            currentValue={postalCode}
                            handleOnInputValue={(data) => {
                              setPostalCode(data);
                            }}
                          />
                        </div>
                      </div>
                      <div className="py-1"></div>
                      <div className="row">
                        <div className="col-12 col-lg-4">
                          <h4 className="h4-stl Rigth-l py-1">
                            {customerType === "Professional" ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["FORM.PERSONAL.INFO-CITY"]?.replace("*", "")),
                                }}
                              ></div>
                            ) : (
                              <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM.PERSONAL.INFO-CITY"]) }}></div>
                            )}
                          </h4>
                        </div>
                        <div className="col-12 col-lg-8">
                          <InputForm
                            type="text"
                            name="Ville"
                            placeholder={
                              customerType === "Professional"
                                ? removeTags(`${content["FORM.PERSONAL.INFO-CITY"]?.replace("*", "")}`)
                                : removeTags(`${content["FORM.PERSONAL.INFO-CITY"]}`)
                            }
                            style={{ heigth: "250px" }}
                            currentValue={city}
                            handleOnInputValue={(data) => {
                              setCity(data);
                            }}
                          />
                        </div>
                      </div>
                      <div className="py-1"></div>
                    </div>
                    <div className="col-12 col-lg-4 pt-2 px-lg-2 px-4">
                      <h4 className="h4-stl Rigth-l py-1">
                        <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM.PERSONAL.INFO-COUNTRY"]) }}></div>
                      </h4>
                    </div>
                    <div className="col-12 col-lg-8 pt-2 px-lg-2 px-3">
                      <DropDownCities options={listaPaysOptions} selectedItem={country} onSelect={(item) => setCountry(item)} />
                    </div>
                  </div>

                  <div className="row px-lg-0 px-0">
                    <div className="col-12 col-lg-4 mt-3 pt-4">
                      <h4 className="h4-stl Rigth-l py-1">
                        <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM.PERSONAL.INFO-PASSWORD"]) }}></div>
                      </h4>
                    </div>
                    <div className="col-12 col-lg-8 mt-lg-3 mt-0 pt-lg-4 pt-2">
                      <InputPassword
                        type="password"
                        name="password"
                        placeholder={removeTags(`${content["FORM.PERSONAL.INFO-PASSWORD"]}`)}
                        currentValue={password}
                        handleOnInputValue={(data) => {
                          setPassword(data);
                          setIsValidUpdate(true);
                        }}
                      />
                    </div>
                    <div className="col-12 col-lg-4 pt-2">
                      <h4 className="h4-stl Rigth-l py-1">
                        <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM.PERSONAL.INFO-CONFIRMATION"]) }}></div>
                      </h4>
                    </div>
                    <div className="col-12 col-lg-8 pt-2">
                      <InputPassword
                        type="password"
                        name="passwordConf"
                        placeholder={removeTags(`${content["FORM.PERSONAL.INFO-CONFIRMATION"]}`)}
                        style={{ heigth: "250px" }}
                        currentValue={passwordConfirm}
                        handleOnInputValue={(data) => {
                          setPasswordConfirm(data);
                        }}
                      />
                      <Alert variant="outlined" severity="warning" className="mt-3">
                        <h6 className="h6-stl ">
                          <div dangerouslySetInnerHTML={{ __html: content["PASSWORD-VALIDATION"] }}></div>
                        </h6>
                      </Alert>
                      {password.length ? (
                        <div>
                          {!isValidUpdate && (
                            <div className="pt-4">
                              <ValidationAlert message={removeTags(`${content["ALERT-PASSWORD-CONTAIN"]}`)} />
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="py-2"></div>
                      {password.length ? (
                        <div>
                          {!isValidSamePassUpdate && (
                            <div>
                              <ValidationAlert message={removeTags(`${content["ALERT-PASSWORD-MATCH"]}`)} />
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row pt-lg-5 pt-3 pb-3 px-lg-0 px-3">
                    <div className="col-0 col-lg-4"></div>
                    <div className="col-12 col-lg-8">
                      <div className="py-1"></div>
                      {customerType === "Professional" ? (
                        <Button
                          disabled={
                            !firstName ||
                            !lastName ||
                            !email ||
                            !mobilePhone ||
                            !selectedProfession ||
                            !companyName ||
                            (password.length ? !isValidSamePassUpdate || !isValidUpdate : false)
                          }
                          message={removeTags(`${content["FORM.PERSONAL.INFO-BUTTON"]}`)}
                          onClick={() => updateInfo(sub)}
                        />
                      ) : (
                        <Button
                          disabled={
                            !firstName ||
                            !lastName ||
                            !email ||
                            !mobilePhone ||
                            (password.length ? !isValidSamePassUpdate || !isValidUpdate : false)
                          }
                          message={removeTags(`${content["FORM.PERSONAL.INFO-BUTTON"]}`)}
                          onClick={() => updateInfo(sub)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="py-5">
            <LoadingFacom />
          </div>
        )}
      </div>
    </div>
  );
}

export default PersonalInfoForm;
