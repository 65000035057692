import { Row } from "@zendeskgarden/react-grid";
import "bootstrap/dist/css/bootstrap.css";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import "./styles/fileAttachement.css";

import UploadImg1 from "../../images/UploadIMG1.svg";
import UploadImg2 from "../../images/UploadIMG2.svg";
import { useMediaQuery } from "@mui/material";

const FileAttachement = (props) => {
  const matches = useMediaQuery("(min-width:767px)");
  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };

  const contentText = props.contentText;
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   accept: ['image/jpeg', 'image/png', 'image/pdf'],
  //   onDrop
  // });
  const PhotoStatus = props.photoStatus;
  const NumberOfPhoto = props.numberPhoto;
  var statusUpload = false;

  if (NumberOfPhoto === 1) {
    if (PhotoStatus === 1) {
      statusUpload = true;
    } else {
      statusUpload = false;
    }
  }
  if (NumberOfPhoto === 2) {
    if (PhotoStatus === 2) {
      statusUpload = true;
    } else {
      statusUpload = false;
    }
  }
  if (NumberOfPhoto === 3) {
    if (PhotoStatus === 3) {
      statusUpload = true;
    } else {
      statusUpload = false;
    }
  }
  if (NumberOfPhoto === 4) {
    if (PhotoStatus === 4) {
      statusUpload = true;
    } else {
      statusUpload = false;
    }
  }
  return (
    <Row className="images-font">
      <Dropzone
        onChangeStatus={props.handleChangeStatus}
        onSubmit={handleSubmit}
        // onClick = {props.onClick}
        maxFiles={1}
        disabled={props.disabled}
        handleChangeStatus={(files, allFiles) => {
          allFiles.forEach((f) => f.remove());
        }}
        // maxSizeBytes={5242880}
        accept="image/jpeg,image/png,image/jpg"
        styles={
          statusUpload === false
            ? {
                dropzone: {
                  height: `${matches ? "20vh" : "10vh"}`,
                  maxWidth: "110px",
                  maxHeight: `${matches ? "110px" : "80px"}`,
                  width: `${matches ? "20vw" : "17vw"}`,
                  overflow: "hidden",
                  background: "#FFF",
                  borderRadius: "0",
                  marginLeft: "0",
                  border: "#9b9b9b dotted 2px",
                },
                previewImage: { width: `${matches ? "100px" : "70px"}`, height: `${matches ? "100px" : "70px"}` },
                dropzoneActive: { background: "#e60003" },
                dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
                inputLabel: (files, extra) => (extra.reject ? { color: "red" } : {}),
              }
            : {
                dropzone: {
                  height: `${matches ? "20vh" : "10vh"}`,
                  maxWidth: "110px",
                  maxHeight: `${matches ? "110px" : "80px"}`,
                  width: `${matches ? "20vw" : "17vw"}`,
                  overflow: "hidden",
                  background: "#e60003",
                  borderRadius: "0",
                  marginLeft: "0",
                  border: "#e60003 solid 2px",
                },
                previewImage: { width: `${matches ? "100px" : "70px"}`, height: `${matches ? "100px" : "70px"}` },
                dropzoneActive: { background: "#e60003" },
                dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
                inputLabel: (files, extra) => (extra.reject ? { color: "red" } : {}),
              }
        }
        inputContent={(files, extra) =>
          extra.reject ? (
            "Formato no válido"
          ) : statusUpload === false ? (
            <div style={{ margin: "0 auto" }}>
              <div className="pb-3">{contentText}</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={UploadImg2}></img>
              </div>
            </div>
          ) : (
            <div>
              <div className="pb-2">
                <h3 className="dzu-inputLabel2" style={{ color: "#FFF" }}>
                  {contentText}
                </h3>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={UploadImg1} style={{ width: "30px" }}></img>
              </div>
            </div>
          )
        }
        SubmitButtonComponent={null}
      />
      {/* <Preview></Preview> */}
      {/* <div className="col-12 col-lg-12 py-3" style={{width:'500px !important',textAlign:'left'}}>
                <Hint style={{width:250,fontWeight:'bold',color:'#469712',textAlign:'left'}}>
                    {`Archivo cargado:${props.fileName}`}
                </Hint> */}
      {/* <Hint style={{width:150 }}>
                    Formatos permitidos: JPG, PNG, y PDF.
                </Hint> */}
      {/* </div> */}
    </Row>
  );
};

export default FileAttachement;
