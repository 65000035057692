export const LocalStorageKeys = {
  REFRESH_TOKEN: "refreshToken",
  TOKEN: "token",
  EMAIL: "email",
  USER_ID: "uuid",
  USER_EMAIL: "userEmail"
};

/**
 *
 * @param {string} key
 * @param {string} value
 */
export const setInLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

/**
 *
 * @param {string} key
 * @returns
 */
export const getInLocalStorage = (key) => {
  const result = localStorage.getItem(key);
  return result;
};

export const clearLocalStorage = () => {
  localStorage.clear();
};
