import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./styles/input.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

function InputPassword(props) {
  const handleOnInputChange = (event) => {
    const cliendData = event.target.value;
    props.handleOnInputValue(cliendData);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword((prev) => !prev);

  return (
    <div className="input-font input-flex ">
      <input
        required={props.required}
        className="form-control"
        type={showPassword ? "text" : "password"}
        onKeyDown={props.onKeyDown}
        name={props.name}
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        style={props.style}
        onChange={(value) => handleOnInputChange(value)}
        value={props.currentValue}
        readOnly={props.readOnly}
      ></input>
      <div className="password-icon">
        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} onClick={handleShowPassword} style={{ width: "18px" }} />
      </div>
    </div>
  );
}
export default InputPassword;
