import { useMediaQuery } from "@mui/material";
import { removeTags } from "../../helpers/removeHtml";
import useGlobalContent from "../../hooks/useGlobalContent";
import { ButtonClean, TabWarranty, TextArea } from "../atoms";
import "./style.css";

export default function DetailsSection(props) {
  const { pageStatus, content } = props;
  const { details, setDetails, totalCharacters } = props;
  const { setPageStatusBefore, setPageStatusAfter } = props;
  const matches = useMediaQuery("(max-width:600px)");
  const { replaceGlobalContents } = useGlobalContent();

  return (
    <div className="bg-white">
      <TabWarranty pageStatus={pageStatus} />
      <div className="block-main">
        <h5 className="h5-stl-warranty Left py-1">
          <div
            dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FOURTH.STEP-INPUT-DESCRIPTION-TITLE"]) }}
          ></div>
        </h5>
        <TextArea
          name="Code"
          placeholder={removeTags(`${content["FORM-NEW-WARRANTY-FOURTH.STEP-INPUT-DESCRIPTION-PLACEHOLDER"]}`)}
          style={matches ? { height: "190px", width: "100%", padding: "15px" } : { height: "350px", width: "100%", padding: "15px" }}
          currentValue={details}
          handleOnInputValue={(data) => {
            setDetails(data);
          }}
          maxLength={600}
        />
        <h5 className="h5-stl-warranty Left py-1">
          <div style={{ display: "flex", gap: "4px" }}>
            <strong>{totalCharacters}</strong>
            <div
              dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FOURTH.STEP-INPUT-DESCRIPTION-INFO"]) }}
            ></div>
          </div>
        </h5>
        <div className="py-3"></div>
        <div className="container-warranty-pad-bt flex-between-bt full-width-bt">
          <div className="full-width-bt flex-start-bt py-2" style={{ textAlign: "left !important" }}>
            <ButtonClean
              message={removeTags(`${content["FORM-NEW-WARRANTY-ALL-INFO-BUTTON-PRE"]}`)}
              onClick={() => setPageStatusBefore(4)}
              width="120px"
              float="left"
            />
          </div>

          <div className="full-width-bt flex-end-bt py-2">
            <ButtonClean
              message={removeTags(`${content["FORM-NEW-WARRANTY-ALL-INFO-BUTTON-FOLLOWING"]}`)}
              onClick={() => setPageStatusAfter()}
              width="120px"
              float="right"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
