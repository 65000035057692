import React, { useState, useRef, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AppContext from "../../context/AppContext";

import "../../constants/global.css";
import "../molecules/style/loginForm.css";

import Button from "../atoms/Button";
function FormPersonalUser(props) {
  const formUserName = useRef(null);
  const formUserLastName = useRef(null);

  const {
    addFormUserName,
    addFormLastUserName,
    addFormUserEmail,
    addFormUserPhone,
    addFormUserAdress,
    addFormUserPostalCode,
    addFormUserVille,
    addFormUserPhoneFix,
    addFormUserPhoneJob,
    addFormUserFax,
    addFormUserAdress2,
  } = useContext(AppContext);
  // PARTE 1
  const handleSubmitUserName = () => {
    const formDataName = new FormData(formUserName.current);
    const UserName = {
      userName: formDataName.get("userName"),
    };
    addFormUserName(UserName);
  };
  const handleSubmitUserLastName = () => {
    const formDataLastName = new FormData(formUserLastName.current);
    const UserLastName = {
      userLastName: formDataLastName.get("userLastName"),
    };
    addFormLastUserName(UserLastName);
  };

  //Parte 2

  const formUserEmail = useRef(null);
  const formUserPhone = useRef(null);
  const formUserPhoneFix = useRef(null);
  const formUserPhoneJob = useRef(null);
  const formUserFax = useRef(null);

  const handleSubmitUserEmail = () => {
    const formDataEmail = new FormData(formUserEmail.current);
    const UserEmail = {
      userEmail: formDataEmail.get("userEmail"),
    };
    addFormUserEmail(UserEmail);
  };
  const handleSubmitUserPhone = () => {
    const formDataPhone = new FormData(formUserPhone.current);
    const UserPhone = {
      userPhone: formDataPhone.get("userPhone"),
    };
    addFormUserPhone(UserPhone);
  };
  const handleSubmitUserPhoneFix = () => {
    const formDataPhoneFix = new FormData(formUserPhoneFix.current);
    const UserPhoneFix = {
      userPhoneFix: formDataPhoneFix.get("userPhoneFix"),
    };
    addFormUserPhoneFix(UserPhoneFix);
  };
  const handleSubmitUserPhoneJob = () => {
    const formDataPhoneJob = new FormData(formUserPhoneJob.current);
    const UserPhoneJob = {
      userPhoneJob: formDataPhoneJob.get("userPhoneJob"),
    };
    addFormUserPhoneJob(UserPhoneJob);
  };
  const handleSubmitUserFax = () => {
    const formDataFax = new FormData(formUserFax.current);
    const UserFax = {
      userFax: formDataFax.get("userFax"),
    };
    addFormUserFax(UserFax);
  };
  //PARTE 3
  const formUserAdress = useRef(null);
  const formUserAdress2 = useRef(null);
  const formUserPostalCode = useRef(null);
  const formUserVillaje = useRef(null);

  const handleSubmitUserAdress = () => {
    const formDataAdress = new FormData(formUserAdress.current);
    const UserNewAdress = {
      userAdress: formDataAdress.get("userAdress"),
    };
    addFormUserAdress(UserNewAdress);
  };

  const handleSubmitUserAdress2 = () => {
    const formDataAdress2 = new FormData(formUserAdress2.current);
    const UserNewAdress2 = {
      userAdress2: formDataAdress2.get("userAdress2"),
    };
    addFormUserAdress2(UserNewAdress2);
  };
  const handleSubmitUserPostalCode = () => {
    const formDataPostalCode = new FormData(formUserPostalCode.current);
    const UserPostalCode = {
      userPostalCode: formDataPostalCode.get("userPostalCode"),
    };
    addFormUserPostalCode(UserPostalCode);
  };

  const handleSubmitUserVillage = () => {
    const formDataVillaje = new FormData(formUserVillaje.current);
    const UserPostalVillaje = {
      userVille: formDataVillaje.get("userVillaje"),
    };
    addFormUserVille(UserPostalVillaje);
  };

  return (
    <div className="Background_White">
      {/* parte 1 */}
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <strong>Prénom*</strong>
          </h4>
        </div>
        <div className="col-12 col-lg-6">
          <form ref={formUserName}>
            <input type="text" placeholder="Prénom" name="userName" onChange={handleSubmitUserName} className="form-control"></input>
          </form>
        </div>
      </div>
      <div className="py-1"></div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <strong>Nom*</strong>
          </h4>
        </div>
        <div className="col-12 col-lg-6">
          <form ref={formUserLastName}>
            <input type="text" placeholder="Nom" name="userLastName" onChange={handleSubmitUserLastName} className="form-control"></input>
          </form>
        </div>
      </div>

      {/* parte 2 */}
      <div className="py-3"></div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <strong>Email*</strong>
          </h4>
        </div>
        <div className="col-12 col-lg-6">
          <form ref={formUserEmail}>
            <input type="email" placeholder="E-mail" name="userEmail" onChange={handleSubmitUserEmail} className="form-control"></input>
          </form>
        </div>
      </div>
      <div className="py-1"></div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <strong>Tel Mobile*</strong>
          </h4>
        </div>
        <div className="col-12 col-lg-6">
          <form ref={formUserPhone}>
            <input
              type="text"
              placeholder="Teléphone mobile"
              name="userPhone"
              onChange={handleSubmitUserPhone}
              className="form-control"
            ></input>
          </form>
        </div>
      </div>
      <div className="py-1"></div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <strong>Tel fix*</strong>
          </h4>
        </div>
        <div className="col-12 col-lg-6">
          <form ref={formUserPhoneFix}>
            <input
              type="text"
              placeholder="Tel.Fixe"
              name="userPhoneFix"
              onChange={handleSubmitUserPhoneFix}
              className="form-control"
            ></input>
          </form>
        </div>
      </div>
      <div className="py-1"></div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <strong>Tel Job*</strong>
          </h4>
        </div>
        <div className="col-12 col-lg-6">
          <form ref={formUserPhoneJob}>
            <input
              type="text"
              placeholder="Tel-Job"
              name="userPhoneJob"
              onChange={handleSubmitUserPhoneJob}
              className="form-control"
            ></input>
          </form>
        </div>
      </div>
      <div className="py-1"></div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <strong>fax*</strong>
          </h4>
        </div>
        <div className="col-12 col-lg-6">
          <form ref={formUserFax}>
            <input type="text" placeholder="FAX" name="userFax" onChange={handleSubmitUserFax} className="form-control"></input>
          </form>
        </div>
      </div>

      {/* parte 3 */}
      <div className="py-3"></div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <strong>Adresse 1*</strong>
          </h4>
        </div>
        <div className="col-12 col-lg-6">
          <form ref={formUserAdress}>
            <input type="text" placeholder="Adresse 1" name="userAdress" onChange={handleSubmitUserAdress} className="form-control"></input>
          </form>
        </div>
      </div>
      <div className="py-1"></div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <strong>Adresse 2*</strong>
          </h4>
        </div>
        <div className="col-12 col-lg-6">
          <form ref={formUserAdress2}>
            <input
              type="text"
              placeholder="Adresse (complément)"
              name="userAdress2"
              onChange={handleSubmitUserAdress2}
              className="form-control"
            ></input>
          </form>
        </div>
      </div>
      <div className="py-1"></div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <strong>Code Postal*</strong>
          </h4>
        </div>
        <div className="col-12 col-lg-6">
          <form ref={formUserPostalCode}>
            <input
              type="text"
              placeholder="Postal code"
              name="userPostalCode"
              onChange={handleSubmitUserPostalCode}
              className="form-control"
            ></input>
          </form>
        </div>
      </div>
      <div className="py-1"></div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <strong>Ville*</strong>
          </h4>
        </div>
        <div className="col-12 col-lg-6">
          <form ref={formUserVillaje}>
            <input
              type="text"
              placeholder="Teléphone mobile"
              name="userVillaje"
              onChange={handleSubmitUserVillage}
              className="form-control"
            ></input>
          </form>
        </div>
      </div>
      <div className="py-1"></div>
    </div>
  );
}

export default FormPersonalUser;
