import { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/tabWarranty.css";

import AppContext from "../../context/AppContext";
import useGlobalContent from "../../hooks/useGlobalContent";
import WarrantyProgress from "../molecules/WarrantyProgress.jsx";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

const TabWarranty = (props) => {
  const { width, height } = useWindowSize();
  const { content } = useContext(AppContext);
  const { replaceGlobalContents } = useGlobalContent();

  const renderTabTypologie = () => {
    return (
      <div className="row" style={{ marginLeft: "0 !important" }}>
        <div className="col-1"></div>
        <div className="col-10">
          <div className="row">
            <div className="col-12 col-lg-4 red-back ">
              <h4 className="textTabsred ">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL.TAB-PRODUCT"]) }}></div>
              </h4>
              {width > 800 && <div className="arrow"> </div>}
            </div>
            <div className="col-12 col-lg-2 gray-back ">
              <h4 className="textTabs">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL.TAB-PICTURES"]) }}></div>
              </h4>
            </div>
            <div className="col-12 col-lg-3 gray-back ">
              <h4 className="textTabs">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL.TAB-TYPE"]) }}></div>
              </h4>
            </div>
            <div className="col-12 col-lg-3 gray-back ">
              <h4 className="textTabs">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL.TAB-SUBMIT"]) }}></div>
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTabPhotos = () => {
    return (
      <div className="row" style={{ marginLeft: "0 !important" }}>
        <div className="col-1"></div>
        <div className="col-10">
          <div className="row">
            <div className="col-12 col-lg-4 gray-back">
              <h4 className="textTabs">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL.TAB-PRODUCT"]) }}></div>
              </h4>
              {width > 800 && <div className="arrow2"></div>}
            </div>
            <div className="col-12 col-lg-2 red-back ">
              <h4 className="textTabsred">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL.TAB-PICTURES"]) }}></div>
              </h4>
            </div>
            <div className="col-12 col-lg-3 gray-back ">
              <h4 className="textTabs">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL.TAB-TYPE"]) }}></div>
              </h4>
            </div>
            <div className="col-12 col-lg-3 gray-back ">
              <h4 className="textTabs">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL.TAB-SUBMIT"]) }}></div>
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTabTypeCasse = () => {
    return (
      <div className="row" style={{ marginLeft: "0 !important" }}>
        <div className="col-1"></div>
        <div className="col-10">
          <div className="row">
            <div className="col-12 col-lg-4 gray-back">
              <h4 className="textTabs">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL.TAB-PRODUCT"]) }}></div>
              </h4>
              {width > 800 && <div className="arrow3"></div>}
            </div>
            <div className="col-12 col-lg-2 gray-back">
              <h4 className="textTabs">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL.TAB-PICTURES"]) }}></div>
              </h4>
            </div>
            <div className="col-12 col-lg-3 red-back ">
              <h4 className="textTabsred">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL.TAB-TYPE"]) }}></div>
              </h4>
            </div>
            <div className="col-12 col-lg-3 gray-back ">
              <h4 className="textTabs">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL.TAB-SUBMIT"]) }}></div>
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTabSend = () => {
    return (
      <div className="row" style={{ marginLeft: "0 !important" }}>
        <div className="col-1"></div>
        <div className="col-10">
          <div className="row">
            <div className="col-12 col-lg-4 gray-back">
              <h4 className="textTabs">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL.TAB-PRODUCT"]) }}></div>
              </h4>
              {width > 800 && <div className="arrow4"></div>}
            </div>
            <div className="col-12 col-lg-2 gray-back">
              <h4 className="textTabs">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL.TAB-PICTURES"]) }}></div>
              </h4>
            </div>
            <div className="col-12 col-lg-3 gray-back ">
              <h4 className="textTabs">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL.TAB-TYPE"]) }}></div>
              </h4>
            </div>
            <div className="col-12 col-lg-3 red-back ">
              <h4 className="textTabsred">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-ALL.TAB-SUBMIT"]) }}></div>
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {width > 991 ? (
        <>
          {props.pageStatus === 2 && renderTabTypologie()}
          {props.pageStatus === 3 && renderTabPhotos()}
          {props.pageStatus === 4 && renderTabTypeCasse()}
          {props.pageStatus === 5 && renderTabSend()}
        </>
      ) : (
        <WarrantyProgress pageStatus={props.pageStatus} />
      )}
    </div>
  );
};

export default TabWarranty;
