import React from "react";
import "./styles/input.css";
import { TextField } from "@mui/material";

function InputForm(props) {
  const handleOnInputChange = (event) => {
    const cliendData = event.target.value;
    props.handleOnInputValue(cliendData);
  };

  return (
    <div className="input-font">
      {/* <form action =""> */}
      <input
        required={props.required}
        className="form-control"
        type={props.type}
        onKeyDown={props.onKeyDown}
        name={props.name}
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        style={props.style}
        onChange={(value) => handleOnInputChange(value)}
        value={props.currentValue}
        readOnly={props.readOnly}
      ></input>
      {/* </form>      */}
    </div>
  );
}
export default InputForm;
