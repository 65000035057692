import { Nav } from "react-bootstrap";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useContext, useState } from "react";
import useContents from "../../hooks/useContents";
import AppContext from "../../context/AppContext";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../redux/actions/profile_actions";
import Fade from "@mui/material/Fade";
import arrowRed from "../../images/ArrowbuttonRed.png";
import youtubeIcon from "../../images/YoutubeIcon.png";
import facebookIcon from "../../images/Facebookicon.png";

import MenuEnglish from "../../images/MenuEnglish.png";
import MenuFrench from "../../images/MenuFrance.png";
import { Link } from "react-router-dom";
import facomLogo from "../../images/Facom_logo.png";
import NavMenu from "./NavMenu";
import { updateUsersLanguage } from "../../api/facom_services";
import { getInLocalStorage } from "../../helpers/local-storage-manager";

export default function LanguageMenu() {
  const { getContents } = useContents();
  const { profile } = useSelector((state) => state.profileReducer);
  const { language, sub } = profile;
  const { content } = useContext(AppContext);
  const { setToggle } = useContext(AppContext);
  const dispatch = useDispatch();

  const originPath = getInLocalStorage("origin");

  const handleLanguageEnglish = () => {
    try {
      dispatch(setProfile({ language: "EN" }));
      dispatch(setProfile({ contents: {} }));
      getContents("EN", true);
      setToggle((prev) => !prev);
      // await updateUsersLanguage(sub, "english");
    } catch {
    } finally {
      // successContent();
    }
  };
  const handleLanguageFrench = () => {
    try {
      dispatch(setProfile({ language: "FR" }));
      dispatch(setProfile({ contents: {} }));
      getContents("FR", true);
      setToggle((prev) => !prev);
      // await updateUsersLanguage(sub, "french");
    } catch {
    } finally {
      // successContent();
    }
  };
  //menu fade
  const [anchorEl, setAnchorEl] = useState(null);
  const open = anchorEl;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="mainHeader">
      <div className="row" style={{ paddingTop: "0 !important" }}>
        <div className="col-12 col-lg-2">
          <a href="https://www.facom.fr">
            <img className="logoMainHeader" src={facomLogo} alt="Logo Facom"></img>
          </a>
        </div>
        <div className="col-12 col-lg-7">
          <NavMenu />
        </div>
        <div className="col-12 col-lg-3">
          <div style={{ margin: "0" }}>
            <Nav className="justify-content-end" activeKey="/home" style={{ gap: "10px" }}>
              <Nav.Item>
                <Nav.Link href="#" className="text-nav-stl ">
                  <div dangerouslySetInnerHTML={{ __html: content["MENU.LOGIN-HOME.WEB-ITEM"] }}></div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://www.youtube.com/user/FacomGroup" className="text-nav-stl ">
                  <img src={youtubeIcon}></img>{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://www.facebook.com/facom.fr/" className="text-nav-stl-blue">
                  <img src={facebookIcon} style={{ width: "10px" }}></img>{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="text-nav-stl" onClick={(e) => handleClick(e)}>
                  <div>
                    {language} <img src={arrowRed} alt="bottomArrow"></img>
                  </div>
                </Nav.Link>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  {/* <MenuItem
                    onClick={() => handleLanguageEnglish()}
                    style={{
                      fontSize: "15px",
                      borderBottom: "solid 1px #e0e0e0",
                      background: "#eeeeef",
                      width: "150px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      alt=""
                      className="languaje_brand"
                      src={MenuEnglish}
                      style={
                        language === "EN"
                          ? { width: "30px", height: "30px", borderBottom: "solid 1px #333" }
                          : { width: "25px", height: "25px" }
                      }
                    ></img>
                  </MenuItem> */}
                  <MenuItem
                    onClick={() => handleLanguageFrench()}
                    style={{
                      fontSize: "15px",
                      borderBottom: "solid 1px #e0e0e0",
                      background: "#eeeeef",
                      width: "150px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      alt=""
                      className="languaje_brand"
                      src={MenuFrench}
                      style={
                        language === "EN"
                          ? { width: "30px", height: "30px" }
                          : { width: "25px", height: "25px", borderBottom: "solid 1px #333" }
                      }
                    ></img>
                  </MenuItem>
                </Menu>
              </Nav.Item>
            </Nav>
          </div>
        </div>
      </div>
    </div>
  );
}
