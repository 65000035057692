import "bootstrap/dist/css/bootstrap.css";
import "./styles/NavMenu.css";
//Redux
import { useWindowSize } from "../../hooks";
import LanguageMenu from "./LanguageMenu";
import MobileMenu from "./MobileMenu";

export default function NavMenuEnd(props) {
  const { width } = useWindowSize();

  return (
    <>
      {width > 991 ? (
        <LanguageMenu />
      ) : (
        <div style={{ paddingBottom: "47px", backgroundColor: " rgb(28, 30, 31)", zIndex: "1000" }}>
          <MobileMenu />
        </div>
      )}
    </>
  );
}
