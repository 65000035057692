import { useContext } from "react";
import AppContext from "../../context/AppContext";
import { removeTags } from "../../helpers/removeHtml";
import useGlobalContent from "../../hooks/useGlobalContent";
import { ButtonClean, LoadingFacom } from "../atoms";
import { DropDownDistributor } from "../molecules";
import { useMediaQuery } from "@mui/material";
import ButtonDistributor from "../atoms/ButtonDistributor";
import { Autocomplete, Box, TextField } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();
export default function DistributorSection(props) {
  const { content } = useContext(AppContext);
  const { distributorsArray, selectedPostalCodeFinal } = props;
  const { loadingDist, distributorsIdArray, distributorID } = props;
  const { onSelectDdDist, onChangeDdDist } = props;
  const { setPageStatusBefore, setPageStatusAfter, width, handleChangeInputDist, valueDist } = props;

  const { hasNextPage } = props;
  const { replaceGlobalContents } = useGlobalContent(distributorsIdArray);
  const matches = useMediaQuery("(min-width:991px)");

  return (
    <div>
      <div className="distributor-box ">
        <DropDownDistributor
          options={distributorsArray}
          loadingProd={loadingDist}
          onChange={onChangeDdDist}
          value={valueDist}
          handleChangeInput={handleChangeInputDist}
          getDistributorsFunction={props.getDistributorsFunction}
          hasNextPage={hasNextPage}
          loadingScroll={props.loadingScroll}
        />
      </div>

      <div className="distributor-box ">
        {!loadingDist ? (
          <div>
            {props.valueDistSelect && props.valueDistSelect !== "-" && (
              <div
                className="row pt-2 mt-lg-5 mt-2"
                style={{ padding: "0 10px", backgroundColor: "#FFF", borderRadius: "3px", width: "98%", marginLeft: "1%" }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FINAL.STEP-DISTRIBUTOR-DETAIL"]) }}
                ></div>
              </div>
            )}
          </div>
        ) : (
          <div className="py-5">
            <LoadingFacom />
          </div>
        )}
      </div>

      <div className=" pt-lg-5 pt-2 distributor-box ">
        <ButtonDistributor
          disabled={!distributorID || distributorID === "0"}
          message={removeTags(`${content["FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-SUBMIT"]}`)}
          onClick={setPageStatusBefore}
          width="120px"
          float="right"
        />
      </div>
      <div className="mt-5 pt-0 pt-lg-4 distributor-box ">
        <ButtonDistributor
          disabled={!distributorID || distributorID === "0"}
          message={removeTags(`${content["FORM-NEW-WARRANTY-FINAL.STEP-BUTTON-CREATE"]}`)}
          onClick={setPageStatusAfter}
          width="350px"
          maxWidth={width < 450 ? "300px" : "500px"}
          float="right"
        />
      </div>

      <div className="py-0 py-lg-4"></div>
    </div>
  );
}
