import React from 'react';
import { Backdrop } from '@mui/material';
import { CircularProgress } from '@mui/material';



export default function LoadingPag(props) {

    return (
        <div>
        <Backdrop style={{ zIndex: '2',color: '#fff',}} open={props.open} onClick={props.handleClose}>
            <CircularProgress color="inherit" />
        </Backdrop>
        </div>
    );
}