import { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";

// COMPONENTS

import ButtonMain from "../components/atoms/ButtonMain";
import ButtonLoggout from "../components/atoms/ButtonLoggout";
import { Link } from "react-router-dom";

import { END_USER_URL } from "../config/settings.json";

// CSS
import "../constants/global.css";
import "./styles/login.css";

import MainIcon1 from "../images/MainIcon1.svg";
import MainIcon2 from "../images/MainIcon2.svg";
import MainIcon3 from "../images/MainIcon3.svg";
import MainIcon4 from "../images/MainIcon4.svg";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../redux/actions/profile_actions";
import { useHistory } from "react-router-dom";
//contents
import AppContext from "../context/AppContext";
import { clearLocalStorage, getInLocalStorage, setInLocalStorage } from "../helpers/local-storage-manager";
import { getAllUsersById, getAllExchangesByStatusAndID, getAllDistributorSet } from "../api/facom_services";
import ExchangeRateModal from "../components/molecules/ExchangeRateModal";
import { LoadingPag } from "../components";
import useGlobalContent from "../hooks/useGlobalContent";
import { removeTags } from "../helpers/removeHtml";

function MainPage(props) {
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profileReducer);
  const { sub } = profile;
  const { content, initialHistory } = useContext(AppContext);
  let history = useHistory();
  const originPath = getInLocalStorage("origin");

  const [open, setOpen] = useState(false);
  const [exchangesToEvaluate, setExchangesToEvaluate] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
  });
  useEffect(() => {
    if (props.origin) {
      setInLocalStorage("origin", props.origin);
    } else {
      setInLocalStorage("origin", "");
    }
    if (!exchangesToEvaluate.length) {
      setOpen(false);
    }
  }, [exchangesToEvaluate]);
  const getExchangesByID = async (ExchangeId) => {
    setLoading(true);
    try {
      const response = getAllUsersById(sub);
      const data = (await response).data;
      let dataExchanges = undefined;
      let dataRecycled = undefined;
      let exchangesWithoutEvaluation = [];
      if (data?.result?.customer_data?._id) {
        const response = getAllExchangesByStatusAndID("Exchanged", data?.result?.customer_data?._id);
        const responseRecycled = getAllExchangesByStatusAndID("Recycled", data?.result?.customer_data?._id);
        dataExchanges = (await response).data;
        dataRecycled = (await responseRecycled).data;
        const dataMerged = [...dataExchanges?.result?.items, ...dataRecycled?.result?.items];
        exchangesWithoutEvaluation = dataMerged?.filter((item) => item?.evaluation === null || item?.evaluation === 0);
        if (exchangesWithoutEvaluation?.length) {
          setOpen(true);
          setExchangesToEvaluate(exchangesWithoutEvaluation);
        }
      }

      return exchangesWithoutEvaluation;
    } catch (error) {
      console.log("error get details");
      console.log(error);
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const onClick = () => {
    // dispatch(clearState())
    const goBack = initialHistory - window.history.length;
    setInLocalStorage("validate", false);
    setInLocalStorage("updatePassword", false);
    setInLocalStorage("updatePasswordMigrated", false);
    dispatch(
      setProfile({
        token: "",
        email: "",
        full_name: "",
        sub: "",
        username: "",
        reload: true,
      })
    );
    clearLocalStorage();
    if (initialHistory !== 1) {
      const host = window.location.host;
      if (originPath) {
        window.history.go(goBack - 1);
      } else if (host !== `${END_USER_URL}`) {
        window.history.go(goBack - 1);
      }
    } else {
      const host = window.location.host;
      if (originPath) {
        window.location.assign(`/login`);
      } else if (host !== `${END_USER_URL}`) {
        window.location.assign(`https://${END_USER_URL}/login`);
      }
    }
  };

  const { replaceGlobalContents } = useGlobalContent();

  const handleRedirectToClaim = async () => {
    setInLocalStorage("recreate", "no");
    try {
      const exchangesWithoutEvaluation = await getExchangesByID();
      if (!exchangesWithoutEvaluation?.length) {
        if (props.origin) {
          const distributorsSet = await getAllDistributorSet();
          const distributorsSetCompare = distributorsSet.data.result.items.findIndex((item) => item.origin === originPath);
          if (distributorsSetCompare === -1) {
            history.push("/demand");
          } else {
            history.push(`/demand/${originPath}`);
          }
        } else {
          history.push(`/demand/${originPath}`);
        }
      }
    } catch (e) {
      history.push(`/demand/${originPath}`);
    }
  };

  return (
    <div style={{ margin: "0", padding: "0" }} className="Background_Gray">
      <div className="pt-lg-5 pt-4"></div>
      <div className="h1-stl-main Center pt-lg-5 pt-2">
        <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["WARRANTY-HOME-TITLE"]) }} className="main-titles"></div>
      </div>
      <div className=" Center ">
        <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["WARRANTY-HOME-SUB-TITLE"]) }} className="second-title"></div>
      </div>
      <h5 className="h5-stl-main Center pt-4">
        <div
          dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["WARRANTY-HOME-INFO-TOP"]) }}
          className="description-text"
        ></div>
      </h5>
      <div className="container no-padd">
        <div className="row mx-auto my-lg-5 my-5 mb-0">
          <div className="card-container col-lg-3 col-6 Center">
            <div onClick={handleRedirectToClaim} style={{ cursor: "pointer" }}>
              <div style={{ backgroundColor: "white" }} className="boxes-pad">
                <img src={MainIcon1} style={{ width: "28%" }} />
              </div>
              <ButtonMain message={removeTags(`${content["WARRANTY-HOME-SELECT-NEW-WARRANTY"]}`)} />
            </div>
          </div>
          <div className="card-container col-lg-3 col-6 Center">
            <Link to={`/historic/${originPath}`}>
              <div style={{ backgroundColor: "white" }} className="boxes-pad">
                <img src={MainIcon2} style={{ width: "28%" }} />
              </div>
              <ButtonMain message={removeTags(`${content["WARRANTY-HOME-SELECT-TRACK-WARRANTY"]}`)} />
            </Link>
          </div>
          <div className="card-container col-lg-3 col-6 Center">
            <a href="https://www.facom.fr/partage/services/Garantie%20FACOM%202016.pdf" target="_blank">
              <div style={{ backgroundColor: "white" }} className="boxes-pad">
                <img src={MainIcon3} style={{ width: "28%" }} />
              </div>
              <ButtonMain message={removeTags(`${content["WARRANTY-HOME-SELECT-INFO-WARRANTY"]}`)} />
            </a>
          </div>
          <div className="card-container col-lg-3 col-6 Center">
            <Link to={`/account/update/${originPath}`}>
              <div style={{ backgroundColor: "white" }} className="boxes-pad">
                <img src={MainIcon4} style={{ width: "28%" }} />
              </div>
              <ButtonMain message={removeTags(`${content["WARRANTY-HOME-SELECT-PERSONAL-INFORMATION"]}`)} />
            </Link>
          </div>
          <div className="col-0 col-xxl-2"></div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {originPath ? (
          <ButtonLoggout message={removeTags(`${content["WARRANTY-HOME-BUTTON-SING-OUT"]}`)} width="150px" onClick={() => onClick()} />
        ) : (
          <a href={`/login/${originPath}`}>
            <ButtonLoggout message={removeTags(`${content["WARRANTY-HOME-BUTTON-SING-OUT"]}`)} width="150px" onClick={() => onClick()} />
          </a>
        )}
      </div>
      <div className="py-5 "></div>
      <ExchangeRateModal
        open={open}
        setOpen={setOpen}
        data={exchangesToEvaluate.length ? exchangesToEvaluate : []}
        setExchangesToEvaluate={setExchangesToEvaluate}
      />
      <LoadingPag open={loading} />
    </div>
  );
}

export default MainPage;
