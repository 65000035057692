import { useState, useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AppContext from "../context/AppContext";
import "./styles/details.css";
// COMPONENTS

import { Link } from "react-router-dom";
// CSS
import "../constants/global.css";
import "./styles/tranking.css";

import { getAllExchangesById, addRating } from "../api/facom_services";
import CircleRed from "../images/CircleRed.svg";
import LoadingFacom from "../components/atoms/LoadingFacom";
import HistoryRating from "../components/atoms/Rating";
import useQuery from "../helpers/getLocation";
import { removeTags } from "../helpers/removeHtml";
import useGlobalContent from "../hooks/useGlobalContent";
import { getInLocalStorage, setInLocalStorage } from "../helpers/local-storage-manager";
import { useSelector } from "react-redux";
import useContentsDetails from "../hooks/useContentsDetails";
import { createAdapterDetail } from "../api/adapters/exchangeDetailAdapter";
import useToastMui from "../hooks/useToastMui";

function DetailsExchange(props) {
  const { profile } = useSelector((state) => state.profileReducer);
  const { language } = profile;
  const { handleCreateToast } = useToastMui();
  const { getContents, loadingCont } = useContentsDetails();
  const [rating, setRating] = useState(1);
  const { content } = useContext(AppContext);
  const originPath = getInLocalStorage("origin");
  //!Llamar por query
  let query = useQuery();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const arrowLeft = "< ";

  //Alerts

  const [loading, setLoading] = useState(false);
  const [detailsArray, setDetailsArray] = useState({});

  const { replaceGlobalContents } = useGlobalContent(detailsArray);

  useEffect(() => {
    if (props.origin) {
      setInLocalStorage("origin", props.origin);
    } else {
      setInLocalStorage("origin", "");
    }
    if (props.id) {
      (async () => {
        await getExchangesByID(props.id);
      })();
    } else {
      if (originPath) {
        window.location.replace(`/historic/${originPath}`);
      } else {
        window.location.replace(`/historic`);
      }
    }
  }, [language]);

  const getExchangesByID = async (ExchangeId) => {
    setLoading(!loading);
    try {
      const response = getAllExchangesById(ExchangeId);
      const data = (await response).data;
      setDetailsArray(createAdapterDetail(data));

      getContents(language, data?.result?.group_data?.id);

      setRating(data.result.evaluation);
      return data;
    } catch (error) {
      console.log("error get details");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(removeTags(`${content["ALERT-ERROR-GET-EXCHANGES"]}`), "error");
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const addRatingPut = async (ratingValue) => {
    setLoading(!loading);
    try {
      addRating(props.id, ratingValue);
      handleCreateToast(removeTags(`${content["ALERT-SUCCESS-RATING"]}`), "success");
    } catch (error) {
      console.log("error get details");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(removeTags(`${content["ALERT-ERROR-RATING"]}`), "error");
    } finally {
      console.log("end");

      setLoading(false);
    }
  };

  return (
    <div className="professional-account content ">
      <Link to={`/historic/${originPath}`}>
        <h6 className="h6-return" style={{ display: "flex", gap: "10px" }}>
          {arrowLeft}
          <div dangerouslySetInnerHTML={{ __html: content["FORM.ALL.BACK-DEMAND"] }}></div>
        </h6>
      </Link>
      <div className=" Background_Gray">
        {loading && (
          <div className="py-5 ">
            {" "}
            <LoadingFacom />
          </div>
        )}

        {!loading && (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="h1-stl Center py-lg-3 py-3">
                  <div
                    dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-DETAIL"]) }}
                    className="main-titles spacing"
                  ></div>
                </h2>
              </div>
            </div>
            <div className="row ">
              <div className="offset-md-2 col-md-8 bg-white">
                <div className="flexNumber">
                  <div style={{ display: "flex" }}>
                    <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.CLAIM-ID"]) }}></div>
                  </div>
                  <div className="claim-font">
                    <span>: {detailsArray?.result?.number}</span>
                  </div>
                </div>
                <div className="detail-font">
                  {detailsArray?.result?.status[detailsArray?.result?.status.length - 1].status === "Exchanged" && (
                    <div content style={{ textAlign: "center" }}>
                      {!rating && (
                        <div
                          className="pb-3 pt-4"
                          dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-TITLE"]) }}
                        ></div>
                      )}
                      {!rating ? (
                        <div
                          className={rating && "pt-4"}
                          dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-SUB-TITLE"]) }}
                        ></div>
                      ) : (
                        <div
                          className={rating && "pt-4"}
                          dangerouslySetInnerHTML={{
                            __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-SUB-TITLE-RATING"]),
                          }}
                        ></div>
                      )}
                      <div className="rating">
                        <HistoryRating
                          value={rating}
                          onChange={(event, newValue) => {
                            setRating(newValue);
                            addRatingPut(newValue);
                          }}
                        />
                      </div>
                      <div className="pb-5 width-exchange" style={{ margin: "0 auto" }}>
                        <p style={{ fontSize: "12px", float: "left" }}>
                          <div
                            dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-OP-ONE"]) }}
                          ></div>
                        </p>
                        <p style={{ fontSize: "12px", float: "right" }}>
                          <div
                            dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-OP-TWO"]) }}
                          ></div>
                        </p>
                      </div>
                    </div>
                  )}
                  {detailsArray?.result?.status[detailsArray?.result?.status.length - 1].status === "Recycled" && (
                    <div content style={{ textAlign: "center" }}>
                      {!rating && (
                        <div
                          className="pb-3 pt-4"
                          dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-TITLE"]) }}
                        ></div>
                      )}
                      {!rating ? (
                        <div
                          className={rating && "pt-4"}
                          dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-SUB-TITLE"]) }}
                        ></div>
                      ) : (
                        <div
                          className={rating && "pt-4"}
                          dangerouslySetInnerHTML={{
                            __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-SUB-TITLE-RATING"]),
                          }}
                        ></div>
                      )}
                      <div className="rating">
                        <HistoryRating
                          value={rating}
                          onChange={(event, newValue) => {
                            setRating(newValue);
                            addRatingPut(newValue);
                          }}
                          readOnly={rating > 0}
                        />
                      </div>

                      <div className="pb-5" style={{ width: "60%", margin: "0 auto" }}>
                        <p style={{ fontSize: "12px", float: "left" }}>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-OP-ONE"]) }}
                          ></div>
                        </p>
                        <p style={{ fontSize: "12px", float: "right" }}>
                          <div
                            dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-OP-TWO"]) }}
                          ></div>
                        </p>
                      </div>
                    </div>
                  )}

                  {detailsArray?.result?.status?.map((row) => (
                    <div className="py-3">
                      <div style={{ display: "flex" }}>
                        {row.status === "Draft" && ""}
                        {row.status === "Submitted" && (
                          <div style={{ display: "block" }}>
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                              <img src={CircleRed} style={{ marginTop: "4px" }}></img>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.SUBMITTED-PART-ONE"], row),
                                }}
                              ></div>
                            </div>
                          </div>
                        )}
                        {row.status === "Analysis" && (
                          <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                            <img src={CircleRed} style={{ marginTop: "4px" }}></img>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.ANALYSIS-PART-ONE"], row),
                              }}
                            ></div>
                          </div>
                        )}
                        {row.status === "Accepted" && (
                          <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                            <img src={CircleRed} style={{ marginTop: "4px" }}></img>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.ACCEPTED-PART-ONE"], row),
                              }}
                            ></div>
                          </div>
                        )}
                        {row.status === "Available" && (
                          <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                            <img src={CircleRed} style={{ marginTop: "4px" }}></img>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.AVAILABLE-PART-ONE"], row),
                              }}
                            ></div>
                          </div>
                        )}
                        {row.status === "Exchanged" && (
                          <div style={{ display: "flex" }}>
                            {/* {detailsArray?.result?.distributor_data?.evaluation === 0 && (
                                <div style={{ display: "flex" }}>
                                  <img src={CircleRed}></img>
                                  <h5 style={{ fontSize: "12px", paddingLeft: "10px", paddingTop: "10px" }}>
                                    {" "}
                                    {content["TABLE-TRACKING-WARRANTY.EXCHANGED-NOT-QUALIFIED"]} ?{" "}
                                  </h5>
                                </div>
                              )}
                              {detailsArray?.result?.distributor_data?.evaluation !== 0 && ( */}
                            <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                              <img src={CircleRed} style={{ marginTop: "4px" }}></img>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.EXCHANGED-QUALIFIED"], row),
                                }}
                              ></div>
                            </div>

                            {/* )} */}
                          </div>
                        )}
                        {row.status === "Refused" && (
                          <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                            <img src={CircleRed} style={{ marginTop: "4px" }}></img>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.REFUSED-PART-ONE"], row),
                              }}
                            ></div>
                          </div>
                        )}
                        {row.status === "Recycled" && (
                          <div style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                            <img src={CircleRed} style={{ marginTop: "4px" }}></img>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RECYCLED-PART-ONE"], row),
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DetailsExchange;
