/**
 *
 * @param {string} str
 * @returns
 */

export function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();
  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  const stringWitOutHtml = str.replace(/(<([^>]+)>)/gi, "");
  return stringWitOutHtml.replace("&amp;", "&");
}
