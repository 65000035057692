import "bootstrap/dist/css/bootstrap.css";

import "../../constants/global.css";
import "./styles/buttonGray.css";
import ArrowBlackRigth from "../../images/ArrowBlackRigth.png";

function ButtonCleanGray(props) {
  return (
    <div style={{ display: "flex" }}>
      <a style={{ display: "flex" }} href={props.reference} target="_blank">
        <button type="button" style={{ width: "100%", marginTop: "4px", paddingRight: "50px", height: "80%" }} className="btn-gray pt-2">
          <span style={{ textAlign: "center !important" }}>{props.message}</span>
        </button>
        <img src={ArrowBlackRigth} style={{ width: "15%", marginTop: "1px", height: "85%" }}></img>
      </a>
    </div>
  );
}

export default ButtonCleanGray;
