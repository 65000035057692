import { ButtonClean, TabWarranty, FileAttachement, ValidationAlert } from "../atoms";
import Example1 from "../../images/Example1.jpeg";
import Example2 from "../../images/Example2.jpeg";
import Example3 from "../../images/Example3.jpeg";
import Example4 from "../../images/Example4.jpeg";
import useGlobalContent from "../../hooks/useGlobalContent";
import { removeTags } from "../../helpers/removeHtml";
import "./style.css";
export default function PicturesSection(props) {
  const { pageStatus, lang, content, PhotosStatus, alertImage, assetsMedia } = props;
  const { fileNameImage1, handleChangeStatus, DisabledPhoto1 } = props;
  const { fileNameImage2, handleChangeStatusImage2, DisabledPhoto2 } = props;
  const { fileNameImage3, handleChangeStatusImage3, DisabledPhoto3 } = props;
  const { fileNameImage4, handleChangeStatusImage4, DisabledPhoto4 } = props;
  const { setPageStatusBefore, setPageStatusAfter } = props;
  const { replaceGlobalContents } = useGlobalContent();
  return (
    <div className="bg-white" style={pageStatus === 3 ? { display: "block" } : { display: "none" }}>
      <TabWarranty pageStatus={pageStatus} />

      <div className="block-main">
        <div style={{ display: "flex", gap: "30px" }}>
          <FileAttachement
            fileName={fileNameImage1}
            handleChangeStatus={handleChangeStatus}
            contentText={removeTags(`${content["FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-1"]}`)}
            disabled={DisabledPhoto1 && !fileNameImage1}
            numberPhoto={1}
            photoStatus={PhotosStatus}
          />
          <FileAttachement
            fileName={fileNameImage2}
            handleChangeStatus={handleChangeStatusImage2}
            contentText={removeTags(`${content["FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-2"]}`)}
            disabled={DisabledPhoto2 && !fileNameImage2}
            numberPhoto={2}
            photoStatus={PhotosStatus}
          />
          <FileAttachement
            fileName={fileNameImage3}
            handleChangeStatus={handleChangeStatusImage3}
            contentText={removeTags(`${content["FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-3"]}`)}
            disabled={DisabledPhoto3 && !fileNameImage3}
            numberPhoto={3}
            photoStatus={PhotosStatus}
          />
          <FileAttachement
            fileName={fileNameImage4}
            handleChangeStatus={handleChangeStatusImage4}
            contentText={removeTags(`${content["FORM-NEW-WARRANTY-SECOND.STEP-PHOTO-4"]}`)}
            disabled={DisabledPhoto4 && !fileNameImage4}
            numberPhoto={4}
            photoStatus={PhotosStatus}
          />
        </div>
        {fileNameImage1
          ? !fileNameImage3 && (
              <div class="pt-4">
                <ValidationAlert message={removeTags(`${content["ALERT-MIN-PHOTOS"]}`)} />
              </div>
            )
          : ""}
        {alertImage && (
          <div class="pt-4">
            <ValidationAlert message={removeTags(`${content["ALERT-ERROR-IMAGE-SIZE"]}`)} />
          </div>
        )}
        <div className="py-3"></div>
        {PhotosStatus === 1 && (
          <div>
            <div className="py-3" style={{ textAlign: "left" }}>
              <div
                dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-SECOND.P1-STEP-INFO-1"]) }}
                className="picture-text"
              ></div>
            </div>
            <img style={{ display: "flex", alignContent: "left" }} src={Example1}></img>
          </div>
        )}
        {PhotosStatus === 2 && (
          <div>
            <div className="py-3" style={{ textAlign: "left" }}>
              <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-SECOND.STEP-P2-INFO-2"]) }}></div>
            </div>

            <img style={{ display: "flex", alignContent: "left" }} src={Example2}></img>
          </div>
        )}
        {PhotosStatus === 3 && (
          <div>
            <div className="py-3" style={{ textAlign: "left" }}>
              <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-SECOND.P3-STEP-INFO-1"]) }}></div>
            </div>

            <img style={{ display: "flex", alignContent: "left" }} src={Example3}></img>
          </div>
        )}
        {PhotosStatus === 4 && (
          <div>
            <div className="py-3" style={{ textAlign: "left" }}>
              <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-SECOND.P4-STEP-INFO-1"]) }}></div>
            </div>

            <img style={{ display: "flex", alignContent: "left" }} src={Example4}></img>
          </div>
        )}

        <div className="py-4"></div>
        <div className="container-warranty-pad-bt flex-between-bt full-width-bt">
          <div className="full-width-bt flex-start-bt py-2" style={{ textAlign: "left !important" }}>
            <ButtonClean
              message={removeTags(`${content["FORM-NEW-WARRANTY-ALL-INFO-BUTTON-PRE"]}`)}
              onClick={setPageStatusBefore}
              width="120px"
              float="left"
            />
          </div>

          <div className="full-width-bt flex-end-bt py-2">
            <ButtonClean
              message={removeTags(`${content["FORM-NEW-WARRANTY-ALL-INFO-BUTTON-FOLLOWING"]}`)}
              onClick={setPageStatusAfter}
              disabled={PhotosStatus !== 4}
              width="120px"
              float="right"
            />
          </div>
        </div>
        <div className="py-lg-3 py-3"></div>
      </div>
    </div>
  );
}
