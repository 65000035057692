import "./App.css";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import Routes from "./Routes";
import useContents from "./hooks/useContents";
import { checkSession } from "./api/facom_services";
import { setInLocalStorage } from "./helpers/local-storage-manager";
import AppContext from "./context/AppContext";

const unlessPaths = [
  "/",
  "/register/professional",
  "/register/individual",
  "/confirm-email",
  "/email-verification",
  "/forgot-password/validate",
  "/ext/FacEval",
  "/login",
  "/update-password",
];
const MainComponent = (props) => {
  const { initialHistory, setInitialHistory } = useContext(AppContext);
  const { contents } = useSelector((state) => state.profileReducer);
  const { loading } = useContents(contents);
  const unless = (url, paths) => {
    return !paths.findIndex((path) => url.includes(path));
  };

  useEffect(() => {
    const URL = window.location.pathname;
    if (!initialHistory) {
      setInitialHistory(window.history.length);
    }
    try {
      if (URL && !unless(URL, unlessPaths)) {
        if (URL === "/login/") return;
        checkSession();
      }
    } catch {
      setInLocalStorage("expired", true);
    }
  }, []);

  return (
    <div>
      <Routes loading={loading} />
    </div>
  );
};

export default MainComponent;
