import useQuery from "../helpers/getLocation";
import { useContext, useEffect, useState } from "react";
import { ButtonClean, InputForm, LoadingFacom, LoadingPag, ValidationAlert } from "../components";
import AppContext from "../context/AppContext";
import "../components/molecules/style/loginForm.css";
import { resendVerificationEmailAPI, verifyEmailAPI } from "../api/facom_services";
import { LocalStorageKeys, getInLocalStorage, setInLocalStorage } from "../helpers/local-storage-manager";
import { removeTags } from "../helpers/removeHtml";
import { useSelector } from "react-redux";
export default function EmailValidation(props) {
  let query = useQuery();
  const { profile } = useSelector((state) => state.profileReducer);
  const { language } = profile;
  const { content } = useContext(AppContext);
  const [email, setEmail] = useState("");

  const [error, setError] = useState(false);
  const [errorSendEmail, setErrorSendEmail] = useState(false);
  const [successSendEmail, setSuccessSendEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [alertWrongMessage, setAlertWrongMessage] = useState("");

  const origin = getInLocalStorage("origin");
  useEffect(() => {
    validateEmail();
  }, []);
  const validateEmail = async () => {
    setLoading((prev) => !prev);
    try {
      setInLocalStorage("validate", true);
      const response = verifyEmailAPI(query.get("verification_code"));
      const data = (await response).data;

      //IF USER IS MIGRATED THEY HAVE NOT PASSWORD
      if (data.result?.first_time_password) {
        setInLocalStorage(LocalStorageKeys.EMAIL, data.result?.email_user);
        window.location.replace(`/forgot-password/validate`);
      } else {
        setInLocalStorage("expired", false);
        window.location.replace(origin ? `/login/${origin}` : `/login`);
      }
    } catch (error) {
      console.log("error validating account");
      console.log(error);
      setError(true);
      if (language === "EN") {
        setAlertWrongMessage(`${error?.response?.data?.message}`);
      } else {
        setAlertWrongMessage(
          `${error?.response?.data?.message}` === "The token is expired, verify try send again"
            ? "Le jeton a expiré, vérifiez et réessayez d'envoyer le jeton."
            : "Erreur de validation"
        );
      }
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const resendValidationEmail = async (emailToSend) => {
    setLoadingSend((prev) => !prev);
    try {
      await resendVerificationEmailAPI(emailToSend);
      setSuccessSendEmail(true);
      setErrorSendEmail(false);
    } catch (error) {
      console.log("error sending email");
      console.log(error);
      setSuccessSendEmail(false);
      setErrorSendEmail(true);
      setAlertWrongMessage(
        error?.response?.data?.message ? `${error?.response?.data?.message}` : "Erreur lors de l'envoi du courrier électronique"
      );
    } finally {
      console.log("end");
      setLoadingSend(false);
    }
  };

  const RenderError = () => {
    return (
      <>
        {errorSendEmail && (
          <div className="my-3">
            <ValidationAlert message={alertWrongMessage} variant="error" />
          </div>
        )}
      </>
    );
  };

  const RenderErrorVerify = () => {
    return (
      <>
        {error && (
          <div className="my-3">
            <ValidationAlert message={alertWrongMessage} variant="error" />
          </div>
        )}
      </>
    );
  };
  const RenderSuccess = () => {
    return (
      <>
        {successSendEmail && (
          <div className="my-3">
            <ValidationAlert message={language === "EN" ? "Email sent" : "Email envoyé"} severity="success" />
          </div>
        )}
      </>
    );
  };

  const handleSubmit = () => {
    resendValidationEmail();
  };
  const arrowLeft = "< ";
  return (
    <div className="Background_Gray py-5">
      {!error ? (
        <>
          {loading ? (
            <div className="my-5 pb-5" style={{ margin: "0 auto" }}>
              <div className="my-5 py-5">
                <LoadingFacom />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div>
          <div className="px-5 mb-5">
            <a href={origin ? `/login/${origin}` : `/login`}>
              <h6 className="h6-return" style={{ display: "flex", gap: "10px" }}>
                {arrowLeft}
                <div dangerouslySetInnerHTML={{ __html: content["FORM.ALL.BACK"] }}></div>
              </h6>
            </a>
          </div>
          <div style={{ display: "flex" }}>
            <div className="col-1 col-xl-4"></div>
            <div className="col-10 col-xl-4">
              <div className="py-5 px-4 Background_White">
                <h2 className="h2-stl Center ">
                  <div dangerouslySetInnerHTML={{ __html: content["EMAIL-VALIDATION-TITLE"] }}></div>
                </h2>
                <div className="line-loggin"></div>
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <h4 className="h4-stl Rigth-l py-1">
                      <div dangerouslySetInnerHTML={{ __html: content["SING-IN-BOX.LOGIN-HOME.INPUT-EMAIL"] }}></div>
                    </h4>
                  </div>
                  <div className="col-12 col-lg-6">
                    <form action="/" onSubmit={handleSubmit}>
                      <InputForm
                        type="text"
                        name="password"
                        placeholder={removeTags(`${content["SING-IN-BOX.LOGIN-HOME.INPUT-EMAIL"]}`)}
                        style={{ heigth: "250px" }}
                        currentValue={email}
                        handleOnInputValue={(data) => {
                          setEmail(data);
                        }}
                      />
                      <RenderError />
                      <RenderSuccess />

                      {alertWrongMessage !== "Erreur lors de l'envoi du courrier électronique" && <RenderErrorVerify />}
                    </form>
                  </div>
                  <div className="py-2"></div>
                  <form action="/" onSubmit={handleSubmit}>
                    <input type="submit" value={removeTags(`${content["RESET-PASSWORD-BUTTON"]}`)} style={{ display: "none" }}></input>
                  </form>
                  <div className="mt-2">
                    <ButtonClean
                      message={removeTags(`${content["RESET-PASSWORD-BUTTON"]}`)}
                      onClick={() => resendValidationEmail(email)}
                      width="60%"
                    />
                  </div>
                  <div className="col-0 col-lg-2"></div>
                </div>
              </div>
            </div>
            <div className="col-1 col-xl-4"></div>
          </div>
          {loadingSend && <LoadingFacom />}
        </div>
      )}

      {/* <LoadingPag open={loading} /> */}
    </div>
  );
}
