import { Nav } from "react-bootstrap";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Fade from "@mui/material/Fade";
import arrowRed from "../../images/ArrowbuttonRed.png";

import MenuEnglish from "../../images/MenuEnglish.png";
import MenuFrench from "../../images/MenuFrance.png";
import { Link } from "react-router-dom";
import facomLogo from "../../images/Facom_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useState } from "react";
import AppContext from "../../context/AppContext";
import { setProfile } from "../../redux/actions/profile_actions";
import useContents from "../../hooks/useContents";
import houseIcon from "../../images/house.svg";
import { getInLocalStorage } from "../../helpers/local-storage-manager";
import { useMediaQuery } from "@mui/material";

export default function MobileMenu() {
  const { getContents } = useContents();
  const { profile } = useSelector((state) => state.profileReducer);
  const matches = useMediaQuery("(min-width:340px)");
  const { language } = profile;
  const { setToggle } = useContext(AppContext);
  const dispatch = useDispatch();
  const originPath = getInLocalStorage("origin");
  const handleLanguageEnglish = () => {
    try {
      dispatch(setProfile({ language: "EN" }));
      dispatch(setProfile({ contents: {} }));
      getContents("EN", true);
      setToggle((prev) => !prev);
    } catch {
    } finally {
      // successContent();
    }
  };
  const handleLanguageFrench = () => {
    try {
      dispatch(setProfile({ language: "FR" }));
      dispatch(setProfile({ contents: {} }));
      getContents("FR", true);
      setToggle((prev) => !prev);
    } catch {
    } finally {
      // successContent();
    }
  };
  //menu fade
  const [anchorEl, setAnchorEl] = useState(null);
  const opend = anchorEl;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div
      style={{ backgroundColor: "#000", height: "47px", position: "fixed", width: "100%", zIndex: "1001" }}
      className="flex-warranty flex-between"
    >
      <div className="container-warranty flex-start">
        <a href="https://www.facom.fr">
          <img src={facomLogo} style={{ width: "70%" }}></img>
        </a>
      </div>
      <div className="container-warranty flex-end ">
        {matches && (
          <Nav activeKey="/home">
            <Nav.Item>
              <Nav.Link onClick={handleClick} className="text-nav-stl_mob">
                {language} <img style={{ width: "25%" }} src={arrowRed}></img>{" "}
              </Nav.Link>

              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={opend}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                {/* <MenuItem
                  onClick={() => handleLanguageEnglish()}
                  style={{
                    fontSize: "12px",
                    borderBottom: "solid 1px #e0e0e0",
                    background: "#eeeeef",
                    width: "100px",
                  }}
                >
                  <img
                    alt=""
                    className="languaje_brand"
                    src={MenuEnglish}
                    style={
                      language === "EN"
                        ? { width: "30px", height: "30px", borderBottom: "solid 1px #333", margin: "0 auto" }
                        : { width: "25px", height: "25px", margin: "0 auto" }
                    }
                  ></img>
                </MenuItem> */}
                <MenuItem
                  onClick={() => handleLanguageFrench()}
                  style={{ fontSize: "12px", borderBottom: "solid 1px #e0e0e0", background: "#eeeeef", width: "100px" }}
                >
                  <img
                    alt=""
                    className="languaje_brand"
                    src={MenuFrench}
                    style={
                      language === "EN"
                        ? { width: "30px", height: "30px", margin: "0 auto" }
                        : { width: "25px", height: "25px", borderBottom: "solid 1px #333", margin: "0 auto" }
                    }
                  ></img>
                </MenuItem>
              </Menu>
            </Nav.Item>
          </Nav>
        )}

        <Link to={`/dashboard/${originPath}`}>
          <img src={houseIcon} style={{ height: "43px" }}></img>
        </Link>
      </div>
    </div>
  );
}
