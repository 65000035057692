import { useState, useEffect, useContext } from "react";
import AppContext from "../context/AppContext";
import { getAllProducts, getAllProductsByReference, getAllProductsByReferenceDD2 } from "../api/facom_services";
import { removeTags } from "../helpers/removeHtml";
import useToastMui from "./useToastMui";

const useGetProducts = (sub) => {
  const { setProductIDType, setProductIDReference, loading, setLoading, content, productIDType } = useContext(AppContext);
  const [productsArray, setProductsArray] = useState([]);
  const { handleCreateToast } = useToastMui();
  const [productsReferenceArray, setProductsReferenceArray] = useState([]);
  const [value, setValue] = useState("");
  const [valueProd, setValueProd] = useState("");

  useEffect(() => {
    getProductsReference();
  }, []);

  const getProductsReference = async () => {
    setLoading(!loading);
    try {
      const response = getAllProductsByReferenceDD2();
      const data = (await response).data;
      let dataTypesArray = data.result.items.map(function (items) {
        var rObj = {
          reference:
            `${items.type}` === "Fixed or variable opening wrench"
              ? `${removeTags(`${content["PRODUCTS-TYPE-OPENINGWRENCH"]}`)}`
              : `${items.type}` === "Pawls"
              ? `${removeTags(`${content["PRODUCTS-TYPE-PAWLS"]}`)}`
              : `${items.type}` === "Sockets"
              ? `${removeTags(`${content["PRODUCTS-TYPE-SOCKETS"]}`)}`
              : `${items.type}` === "Screwdrivers"
              ? `${removeTags(`${content["PRODUCTS-TYPE-SCREWDRIVERS"]}`)}`
              : `${items.type}`.toLocaleLowerCase() === "Male Wrenchs".toLocaleLowerCase()
              ? `${removeTags(`${content["PRODUCTS-TYPE-MALEWRENCHS"]}`)}`
              : `${items.type}` === "Pliers / Vise pliers"
              ? `${removeTags(`${content["PRODUCTS-TYPE-PLIERS"]}`)}`
              : `${items.type}` === "Measuring / Tracing"
              ? `${removeTags(`${content["PRODUCTS-TYPE-TRACING"]}`)}`
              : `${items.type}` === "Hammers / Strike"
              ? `${removeTags(`${content["PRODUCTS-TYPE-HAMMERS"]}`)}`
              : `${items.type}` === "Sawing / Drilling / Cutting"
              ? `${removeTags(`${content["PRODUCTS-TYPE-SAWING"]}`)}`
              : `${items.type}` === "Extraction"
              ? `${removeTags(`${content["PRODUCTS-TYPE-EXTRACTION"]}`)}`
              : `${items.type}` === "Other"
              ? `${removeTags(`${content["PRODUCTS-TYPE-OTHER"]}`)}`
              : "",
          id: items.id,
        };

        return rObj;
      });
      setProductsReferenceArray([...dataTypesArray]);

      return data;
    } catch (error) {
      console.log("error get reference products");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(removeTags(`${content["ALERT-ERROR-GET-ONE-PRODUCT"]}`), "error");

      // (() => {
      //   window.location.replace("/account-login");
      // })();
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const getProducts = async (value) => {
    setLoading(!loading);
    try {
      const response = getAllProductsByReference(value);
      const data = (await response).data;
      // setProductsArray(data)
      const arrayProducts = data?.result?.items?.map(function (items) {
        // return(items.reference)
        var rObj = {
          reference: `${items.reference} - ${items.name}`,
          id: items.id,
        };
        return rObj;
      });

      setProductsArray([...arrayProducts.filter((item) => !item?.reference?.includes("_UNKNOWN_"))]);

      return data;
    } catch (error) {
      console.log("error get products");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(removeTags(`${content["ALERT-ERROR-GET-PRODUCT"]}`), "error");
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const getAllProduct = async () => {
    setLoading(!loading);
    try {
      const response = getAllProducts();
      const data = (await response).data;
      // setProductsArray(data)
      const arrayProducts = data?.result?.items?.map(function (items) {
        // return(items.reference)
        var rObj = {
          reference: `${items.reference} - ${items.name}`,
          id: items.id,
        };
        return rObj;
      });

      setProductsArray([...arrayProducts.filter((item) => !item?.reference?.includes("_UNKNOWN_"))]);
      return data;
    } catch (error) {
      console.log("error get products");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(removeTags(`${content["ALERT-ERROR-GET-PRODUCT"]}`), "error");
    } finally {
      console.log("end");
      setLoading(false);
    }
  };
  const handleChange = (e, value) => {
    setValue(value?.reference);
    setProductIDReference(value?.id);
    // window.scroll(0, 0);
  };
  const handleChangeInput = (e) => {
    setValue(e?.target?.value);
    if (e?.target?.value.slice(0, 1) !== "*") {
      if (e?.target?.value.length >= 2) {
        getProducts(e?.target?.value);
      }
    }
    if (e?.target?.value === "*") {
      getAllProduct(e?.target?.value);
    }
    setProductsArray([]);
  };
  const handleChangeProd = (e, value) => {
    setValueProd(value?.reference);
    setProductIDType(value?.id);
  };

  const handleChangeInputProd = (e) => {
    setValueProd(e?.target?.value);
  };

  return {
    productsArray,
    productsReferenceArray,
    handleChange,
    handleChangeProd,
    handleChangeInput,
    value,
    loadingProd: loading,
    handleChangeInputProd,
    valueProd,
  };
};

export default useGetProducts;
