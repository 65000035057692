import * as React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";

export default function HistoryRating(props) {
  return (
    <Box
      sx={{
        "& > legend": { mt: 2 },
      }}
    >
      <Rating
        name="simple-controlled"
        value={props.value}
        size="large"
        defaultValue={1}
        readOnly={props.readOnly}
        onChange={props.onChange}
      />
    </Box>
  );
}
