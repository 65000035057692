import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import REDUCERS from "./reducers";
import { persistStore } from "redux-persist";

const ROOT_REDUCER = combineReducers(REDUCERS);

const STORE = createStore(ROOT_REDUCER, applyMiddleware(thunk));
const PERSISTOR = persistStore(STORE);

export { STORE, PERSISTOR };
