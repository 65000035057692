import React, { useRef, useState, useEffect, useContext } from "react";
import debounce from "lodash.debounce";
import { Item, Menu, Label, Field, Dropdown, Select } from "@zendeskgarden/react-dropdowns";
import { Row, Col } from "@zendeskgarden/react-grid";
import checkSvg from "../../images/check2.svg";
import unCheck from "../../images/uncheck.png";
import AppConext from "../../context/AppContext";
import "./style/dropdownReason.css";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";

const DropDownReasonEx = (props) => {
  const { setReasonExchangeID, assetsMedia, elegibleExchange, setElegibleExchange } = useContext(AppConext);
  // const [selectedItem, setSelectedItem] = useState(props.options[0]);
  const matches = useMediaQuery("(min-width:991px)");
  const [inputValue, setInputValue] = useState("");
  const [matchingOptions, setMatchingOptions] = useState(props.options);

  const { profile } = useSelector((state) => state.profileReducer);
  const { language } = profile;
  /**
   * Debounce filtering
   */
  const filterMatchingOptionsRef = useRef(
    debounce((value) => {
      const matchedOptions = props.options?.filter(
        (option) => option?.label.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
      );

      setMatchingOptions(matchedOptions);
    }, 300)
  );

  useEffect(() => {
    filterMatchingOptionsRef.current(inputValue);
  }, [inputValue]);

  const handleClick = (optionRefused, idRefused) => {
    setElegibleExchange(optionRefused);
    setReasonExchangeID(idRefused);
  };

  return (
    <Row justifyContent="left" className="dropdown-font">
      <Col sm={12}>
        <Dropdown
          inputValue={inputValue}
          selectedItem={props.selectedItem}
          onSelect={props.onSelect}
          onInputValueChange={(value) => {
            setInputValue(value);
          }}
          downshiftProps={{ defaultHighlightedIndex: 0 }}
        >
          <Field>
            <Select className="warranty-c-ref-autocomplete dropdown-warranty ">
              {elegibleExchange === true ? (
                <div className="warranty-c-flex">
                  <span className="warranty-color-text">{props.selectedItem}</span>
                  {/* <img className="warranty-c-img-ic" alt="ck" src={checkSvg}></img> */}
                </div>
              ) : (
                <div className="warranty-c-flex">
                  <span className="warranty-color-text">{props.selectedItem}</span>
                  {/* <img className="warranty-c-img-ic" alt="un" src={unCheck}></img> */}
                </div>
              )}
            </Select>
          </Field>
          <Menu className="warranty-c-ref-menu">
            {matchingOptions.length ? (
              matchingOptions.map((option) => (
                <Item key={option.label} value={option.label} onClick={() => handleClick(option.refused, option.id)}>
                  {option.refused === true ? (
                    <div className="warranty-c-flex">
                      <span className="warranty-color-text-exchange">{option?.label}</span>
                      <img className="warranty-c-img-ic2" alt="ck" src={checkSvg}></img>
                    </div>
                  ) : (
                    <div className="warranty-c-flex">
                      <span className="warranty-color-text-primary">{option?.label}</span>
                      <img className="warranty-c-img-ic2" alt="nck" src={unCheck}></img>
                    </div>
                  )}
                </Item>
              ))
            ) : (
              <Item disabled>{language === "EN" ? "No matches found" : "Aucune correspondance trouvée"}</Item>
            )}
          </Menu>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default DropDownReasonEx;
