import { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AppContext from "../../context/AppContext";

import "../../constants/global.css";
import "../molecules/style/loginForm.css";
import "./styles/form.css";
import InputForm from "./InputForm";
import { removeTags } from "../../helpers/removeHtml";

function FormParticularUser(props) {
  const { zipCode, setZipCode, address, setAddress, city, setCity, content } = useContext(AppContext);

  return (
    <div className="Background_White">
      <div className="row">
        {/* Falta sacar lo que se escribe*/}
        <div className="col-12 col-lg-4 pt-4">
          <h4 className="h4-stl Rigth-l py-1">
            <div dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PERSONAL.ADDRESS"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-8 padding-form">
          <InputForm
            type="text"
            name="Adresse"
            placeholder={removeTags(`${content["FORM.REGISTER-PERSONAL.ADDRESS"]}`)}
            style={{ heigth: "250px" }}
            currentValue={address}
            handleOnInputValue={(data) => {
              setAddress(data);
            }}
          />
        </div>
        <div className="col-12 col-lg-4 pt-2">
          <h4 className="h4-stl Rigth-l py-1">
            <div dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PERSONAL.POSTAL-CODE"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-8 pt-lg-2 pt-0">
          <InputForm
            type="text"
            name="Code Postal"
            placeholder={removeTags(`${content["FORM.REGISTER-PERSONAL.POSTAL-CODE"]}`)}
            style={{ heigth: "250px" }}
            currentValue={zipCode}
            handleOnInputValue={(data) => {
              setZipCode(data);
            }}
          />
        </div>
        <div className="col-12 col-lg-4 pt-2">
          <h4 className="h4-stl Rigth-l py-1">
            <div dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PERSONAL.CITY"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-8 pt-lg-2 pt-0">
          <InputForm
            type="text"
            name="Ville"
            placeholder={removeTags(`${content["FORM.REGISTER-PERSONAL.CITY"]}`)}
            style={{ heigth: "250px" }}
            currentValue={city}
            handleOnInputValue={(data) => {
              setCity(data);
            }}
          />
        </div>
        <div className="pt-1"></div>
      </div>
    </div>
  );
}

export default FormParticularUser;
