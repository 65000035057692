import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { addRating } from "../../api/facom_services";
import AppContext from "../../context/AppContext";
import { ButtonClean } from "../atoms";
import HistoryRating from "../atoms/Rating";
import { useHistory } from "react-router-dom";
import { removeTags } from "../../helpers/removeHtml";
import useGlobalContent from "../../hooks/useGlobalContent";
import { getInLocalStorage } from "../../helpers/local-storage-manager";
import useToastMui from "../../hooks/useToastMui";

export default function ExchangeRateModal(props) {
  const { content } = useContext(AppContext);
  const { handleCreateToast } = useToastMui();
  let history = useHistory();
  const { open, setOpen, data } = props;
  const [rating, setRating] = useState(0);
  const [loading, setLoading] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);

  const originPath = getInLocalStorage("origin");

  const { replaceGlobalContents } = useGlobalContent();

  const addRatingPut = async (ratingValue) => {
    setLoading(!loading);
    try {
      data.forEach((item) => {
        addRating(item._id, ratingValue);
      });
      handleCreateToast(removeTags(`${content["ALERT-SUCCESS-RATING"]}`), "success");
      setRating(0);
      setOpenConfirm(true);
      setOpen(false);
    } catch (error) {
      console.log("error get details");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(removeTags(`${content["ALERT-ERROR-RATING"]}`), "error");
    } finally {
      console.log("end");
      setOpen(false);
      setLoading(false);
    }
  };

  return (
    <>
      <Modal size="lg" centered show={open} backdrop="static" keyboard={false}>
        <Modal.Body style={{ maxHeight: "600px", overflow: "scroll" }}>
          <Modal.Title style={{ textAlign: "center" }} className="py-4">
            <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["MODAL-RATING-TITLE"]) }}></div>
          </Modal.Title>

          <div content style={{ textAlign: "center" }} className="pb-3">
            <h5 style={{ fontSize: "14px" }}>
              <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["MODAL-RATING-DESCRIPTION"]) }}></div>
            </h5>
            <h5 style={{ fontSize: "12px" }} className="pt-3">
              <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-SUB-TITLE"]) }}></div>
            </h5>
            {/* {item?.number} */}
            <HistoryRating
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
                addRatingPut(newValue);
              }}
            />

            <div className="pb-5" style={{ width: "60%", margin: "0 auto" }}>
              <p style={{ fontSize: "12px", float: "left" }}>
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-OP-ONE"]) }}></div>
              </p>
              <p style={{ fontSize: "12px", float: "right" }}>
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.RATING-OP-TWO"]) }}></div>
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* thanks modal */}
      <Modal size="lg" centered show={openConfirm} backdrop="static" keyboard={false}>
        <Modal.Body style={{ maxHeight: "600px", overflow: "scroll" }}>
          <Modal.Title style={{ textAlign: "center" }} className="py-4">
            <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["MODAL-RATING-CONGRATS-TITLE"]) }}></div>
          </Modal.Title>

          <div content style={{ textAlign: "center" }} className="pb-3">
            <h3 style={{ fontSize: "16px" }} className="pb-4">
              <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["MODAL-RATING-CONGRATS-DESCRIPTION"]) }}></div>
            </h3>

            <ButtonClean
              message={removeTags(`${content["MODAL-RATING-CONGRATS-BUTTON"]}`)}
              onClick={() => {
                setOpenConfirm(false);
                history.push(`/demand/${originPath}`);
              }}
              width="230px"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
