import React, { useState, useRef, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.css'

import '../../constants/global.css'
import './styles/button.css'

function SubmitButton(props){
    const arrow = "  >";
    return (
        <div>
            <input disabled={props.disabled} type="submit" onClick={props.onClick} className="btn-red pt-2" value={props.message}></input>{arrow}
        </div>
        );
};

export default SubmitButton;