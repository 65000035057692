import React from "react";
import Alert from "@material-ui/lab/Alert";

import "./styles/ValidationAlert.css";

export default function ValidationAlert(props) {
  return (
    <div className="alert-stl alert-font">
      <Alert variant="outlined" severity={props.severity ? props.severity : "error"}>
        {props.message}
      </Alert>
    </div>
  );
}
