import { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AppContext from "../../context/AppContext";

import "../../constants/global.css";
import "../molecules/style/loginForm.css";

import InputForm from "./InputForm";
import { removeTags } from "../../helpers/removeHtml";

function FormInitialUser(props) {
  const { firstName, setFirstName, lastName, setLastName, email, setEmail, mobilePhone, setMobilePhone, content } = useContext(AppContext);

  return (
    <div className="Background_White">
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <div dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PROFESSIONAL.FIRST-NAME"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-8">
          <InputForm
            required
            type="text"
            name="Prénom"
            placeholder={removeTags(`${content["FORM.REGISTER-PROFESSIONAL.FIRST-NAME"]}`)}
            style={{ heigth: "250px" }}
            currentValue={firstName}
            handleOnInputValue={(data) => {
              setFirstName(data);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <div dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PROFESSIONAL.LAST-NAME"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-8">
          <InputForm
            type="text"
            required
            name="Nom"
            placeholder={removeTags(`${content["FORM.REGISTER-PROFESSIONAL.LAST-NAME"]}`)}
            style={{ heigth: "250px" }}
            currentValue={lastName}
            handleOnInputValue={(data) => {
              setLastName(data);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <div dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PROFESSIONAL.EMAIL-NAME"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-8">
          <InputForm
            type="email"
            name="email"
            required
            placeholder={removeTags(`${content["FORM.REGISTER-PROFESSIONAL.EMAIL-NAME"]}`)}
            style={{ heigth: "250px" }}
            currentValue={email}
            handleOnInputValue={(data) => {
              setEmail(data);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <h4 className="h4-stl Rigth-l py-1">
            <div dangerouslySetInnerHTML={{ __html: content["FORM.REGISTER-PROFESSIONAL.PHONE"] }}></div>
          </h4>
        </div>
        <div className="col-12 col-lg-8">
          <InputForm
            type="text"
            name="Teléphone mobile"
            required
            placeholder={removeTags(`${content["FORM.REGISTER-PROFESSIONAL.PHONE"]}`)}
            style={{ heigth: "250px" }}
            currentValue={mobilePhone}
            handleOnInputValue={(data) => {
              setMobilePhone(data);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default FormInitialUser;
