import { useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";

import "../../constants/global.css";
import Table from "react-bootstrap/Table";
import { getAllDistributorSet, updateExchangesStatus } from "../../api/facom_services";
import { Link } from "react-router-dom";
import AppContext from "../../context/AppContext";
import "./styles/tablestyles.css";
import useGlobalContent from "../../hooks/useGlobalContent";
import { removeTags } from "../../helpers/removeHtml";
import { getInLocalStorage } from "../../helpers/local-storage-manager";
import useToastMui from "../../hooks/useToastMui";
import ButtonTable from "./ButtonTable";
import ButtonAbandonned from "./ButtonAbandonned";
import { useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";

function TableState(props) {
  const { content } = useContext(AppContext);
  const history = useHistory();
  const { handleCreateToast } = useToastMui();
  const matchesTable = useMediaQuery("(min-width:767px)");
  //Call api

  const { replaceGlobalContents } = useGlobalContent();
  const originPath = getInLocalStorage("origin");

  const handleRedirect = async (row) => {
    try {
      if (originPath) {
        const distributorsSet = await getAllDistributorSet();
        const distributorsSetCompare = distributorsSet.data.result.items.findIndex((item) => item.origin === originPath);
        if (distributorsSetCompare === -1) {
          history.push(`/demand-dist/${row._id}`);
        } else {
          history.push(originPath ? `/demand-dist/${originPath}/${row._id}` : `/demand-dist/${row._id}`);
        }
      } else {
        history.push(originPath ? `/demand-dist/${originPath}/${row._id}` : `/demand-dist/${row._id}`);
      }
    } catch (e) {
      history.push(originPath ? `/demand-dist/${originPath}/${row._id}` : `/demand-dist/${row._id}`);
    }
  };

  const updateStatus = async (id) => {
    try {
      const response = updateExchangesStatus(id, "Abandonned");
      const data = (await response).data;
      handleCreateToast(removeTags(`${content["ALERT-SUCCESS-ABANDONNED"]}`), "success");
      return data;
    } catch (error) {
      console.log("error update");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(`${content["ALERT-ERROR-UPDATE-ABANDONNED"]}`, "error");
    } finally {
      console.log("end");
      props.reload();
    }
  };

  return (
    <div style={{ maxHeight: "800px", overflow: "scroll" }}>
      {props.list?.length != 0 ? (
        <Table responsive="xl">
          <thead
            style={{
              marginBottom: "10px",
              textAlign: "left",
              position: "sticky",
              top: "0",
              backgroundColor: "#fff",
              borderBottom: "1px solid #000",
            }}
            className="head-font-bold-table"
          >
            <tr>
              <th className="tabletd-number">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.CLAIM-ID"]) }}></div>
              </th>
              <th>
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-TYPE"]) }}></div>
              </th>
              {matchesTable && (
                <th>
                  <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-BREAKAGE"]) }}></div>
                </th>
              )}
              <th className="tabletd-number">
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-DATE"]) }}></div>
              </th>

              <th style={{ textAlign: "right", paddingRight: "30px" }}>
                <div dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["TABLE-TRACKING-WARRANTY.TITLE-ACTIONS"]) }}></div>
              </th>
            </tr>
          </thead>

          <tbody className="table-font">
            {props.list?.map((row) => (
              <tr style={{ backgroundColor: "rgb(242,242,242)", borderBottom: "5px solid #FFF", textAlign: "left" }}>
                <td className="tabletd-number">{row?.number}</td>
                <td className="tabletd">{`${row?.product_data?.name} (${row?.product_data?.reference})`}</td>
                {matchesTable && <td style={matchesTable ? { paddingLeft: "15px" } : {}}>{row?.exchange_reason_data?.label}</td>}
                <td className="tabletd-number">{row.date}</td>
                <td>
                  {props.State === "DRAFT" ? (
                    <div style={{ display: "block" }}>
                      <div style={{ display: "block" }}>
                        <ButtonAbandonned
                          message={removeTags(`${content["TABLE-TRACKING-WARRANTY.BUTTON-GIVE"]}`)}
                          onClick={() => updateStatus(row._id)}
                          width="80px"
                          float="right"
                        />
                      </div>

                      <div style={{ display: "block" }} onClick={() => handleRedirect(row)}>
                        <ButtonTable message={removeTags(`${content["TABLE-TRACKING-WARRANTY.BUTTON-DISTRIBUTOR-CHOICE"]}`)} width="80px" />
                      </div>
                    </div>
                  ) : (
                    <Link to={originPath ? `/details/${originPath}/${row._id}` : `/details/${row._id}`}>
                      <div>
                        <ButtonTable message={removeTags(`${content["TABLE-TRACKING-WARRANTY.BUTTON-DETAIL"]}`)} width="80px" />
                      </div>
                    </Link>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        ""
      )}
    </div>
  );
}

export default TableState;
