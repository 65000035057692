import { useContext, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import AppContext from "../context/AppContext";

// COMPONENTS

import { Link, useHistory } from "react-router-dom";

// CSS
import "../constants/global.css";
import "./styles/warranty.css";

import {
  getAllDistributorAddress,
  getAllDistributorGroupCodeVisible,
  getAllDistributorPostalCodeAndGroupCode,
  getAllExchangesById,
} from "../api/facom_services";
import {
  getAllDistributorName,
  getAllDistributorPostalCode,
  getDistributorsById,
  updateExchanges,
  updateExchangesStatus,
  getAllDistributors,
} from "../api/facom_services";
import LoadingPag from "../components/atoms/LoadingPag";
import LoadingFacom from "../components/atoms/LoadingFacom";
import { DistributorSection } from "../components";
import { removeTags } from "../helpers/removeHtml";
import useGlobalContent from "../hooks/useGlobalContent";
import { getInLocalStorage, setInLocalStorage } from "../helpers/local-storage-manager";
import useToastMui from "../hooks/useToastMui";
import useInfiniteScroll from "react-infinite-scroll-hook";

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    window.scroll(0, 0);
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

function SelectDistributor(props) {
  let history = useHistory();
  const { width } = useWindowSize();
  const { distributorID, setdistributorID } = useContext(AppContext);
  const { content } = useContext(AppContext);
  const { handleCreateToast } = useToastMui();

  const { replaceGlobalContents } = useGlobalContent();

  const originPath = getInLocalStorage("origin");
  const [valueDist, setValueDist] = useState("");

  useEffect(() => {
    (async () => {
      if (props.origin) {
        setInLocalStorage("origin", props.origin);
      } else {
        setInLocalStorage("origin", "");
      }
      await getExchangesID();
    })();
  }, []);

  const [selectedPostalCodeFinal, setSelectedPostalCodeFinal] = useState("");
  const [inputDistributor, setInputDistributor] = useState("");

  // const [productReferenceID,setProductReferenceID ] = useState('')
  if (selectedPostalCodeFinal === "") {
    if (content["FORM-NEW-WARRANTY-FINAL.STEP-PLACEHOLDER"]) {
      setSelectedPostalCodeFinal(removeTags(`${content["FORM-NEW-WARRANTY-FINAL.STEP-PLACEHOLDER"]}`));
    }
  }
  var [pageStatus, setPageStatus] = useState(1);

  //API STATE

  const [distributorssrray, setDistributorsArray] = useState([]);
  const [distributorsIdArray, setDistributorsIdArray] = useState([]);
  const [productsID, setProductID] = useState([]);
  const [customerID, setCustomerID] = useState([]);
  const [reasonForExchangesID, setReasonForExchangesID] = useState([]);
  const [exchangesArray, setExchangesArray] = useState([]);
  const [valueDistSelect, setValueDistSelect] = useState("");
  const [valueDistSearch, setValueDistSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingLast, setLoadingLast] = useState(false);
  const [loadingEx, setLoadingEx] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [loadingScroll, setLoadingScroll] = useState(false);

  const [hasNextPage, setgasNextPage] = useState(false);
  useEffect(() => {
    if (!valueDistSearch) return;

    const delayDebounceFn = setTimeout(() => {
      getDistributorsFunction();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [valueDistSearch]);
  const getDistributorsFunction = async () => {
    if (valueDistSearch.slice(0, 1) !== "*") {
      if (valueDistSearch.length >= 2) {
        setgasNextPage(false);
        await getDistributor(valueDistSearch);
      }
    }
    if (valueDistSearch === "*") {
      await getAllDistributor(originPath);
    }
  };

  const getExchangesID = async () => {
    setLoadingEx(!loadingEx);
    try {
      const response = getAllExchangesById(props.id);
      const data = (await response).data;
      setExchangesArray(data);
      setProductID(data.result.product_id);
      setCustomerID(data.result.customer_id);
      setReasonForExchangesID(data.result.exchange_reason_id);
      setPictures(data.result.pictures);
    } catch (error) {
      console.log("error get exchange");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(removeTags(`${content["ALERT-ERROR-GET-EXCHANGES"]}`), "error");
    } finally {
      console.log("end");
      setLoadingEx(false);
    }
  };

  //!-------------------DISTRIBUTOR------------------------

  const [page, setPage] = useState(0);

  const getDistributor = async (value) => {
    setLoading(!loading);

    try {
      const handlePostalAndGroupCode = value.split(":");
      let arrayByPostalCodeAndGroupCode = [];
      let completeArray = [];
      if (handlePostalAndGroupCode.length > 1) {
        if (handlePostalAndGroupCode[1].length > 0) {
          if (handlePostalAndGroupCode[1] === "*") {
            const responseGroupAndPostal = getAllDistributorGroupCodeVisible(handlePostalAndGroupCode[0], originPath);
            const dataGroupAndPostal = (await responseGroupAndPostal).data;

            arrayByPostalCodeAndGroupCode = dataGroupAndPostal.result.items.map(function (items) {
              // return(items.reference)
              var rObj = {
                name: `${items.address?.postal_code} - ${items.name}`,
                id: items.id,
              };
              return rObj;
            });
          } else {
            const responseGroupAndPostal = getAllDistributorPostalCodeAndGroupCode(
              handlePostalAndGroupCode[1],
              handlePostalAndGroupCode[0],
              originPath
            );
            const dataGroupAndPostal = (await responseGroupAndPostal).data;

            arrayByPostalCodeAndGroupCode = dataGroupAndPostal.result.items.map(function (items) {
              // return(items.reference)
              var rObj = {
                name: `${items.address?.postal_code} - ${items.name}`,
                id: items.id,
              };
              return rObj;
            });
          }
        }
      } else {
        const responseByPostalCode = getAllDistributorPostalCode(value, originPath);
        const dataByPostalCode = (await responseByPostalCode).data;

        const fullArray = [...dataByPostalCode?.result?.items];
        completeArray = fullArray.map(function (items) {
          // return(items.reference)
          var rObj = {
            name: `${items.address?.postal_code} - ${items.name}`,
            id: items.id,
          };
          return rObj;
        });
      }
      let arrayOp = [...completeArray, ...arrayByPostalCodeAndGroupCode];
      let hash = {};
      arrayOp = arrayOp.filter((o) => (hash[o.id] ? false : (hash[o.id] = true)));

      setDistributorsArray(arrayOp);

      return arrayOp;
    } catch (error) {
      console.log("error get products");
      console.log(error);
      // if (error?.request?.status !== 401 && error?.request?.status !== 403)
      //   handleCreateToast(removeTags(`${content["ALERT-ERROR-GET-DISTRIBUTORS"]}`), "error");
    } finally {
      console.log("end");
      setLoading(false);
    }
  };

  const getAllDistributor = async () => {
    if (page === 0) setLoading(true);
    setLoadingScroll(true);
    try {
      setgasNextPage(true);
      const response = getAllDistributors(originPath, page);

      const data = (await response).data;

      // setProductsArray(data)
      const array1 = data.result.items.map(function (items) {
        // return(items.reference)
        var rObj = {
          name: `${items.address?.postal_code} - ${items.name}`,
          id: items.id,
        };
        return rObj;
      });

      if (array1.length === 0) {
        setgasNextPage(false);
      }
      setDistributorsArray([...distributorssrray, ...array1]);
      setPage((prev) => prev + 1);
      return data;
    } catch (error) {
      console.log("error get products");
      console.log(error);
      // if (error?.request?.status !== 401 && error?.request?.status !== 403)
      //   handleCreateToast(removeTags(`${content["ALERT-ERROR-GET-DISTRIBUTORS"]}`), "error");
    } finally {
      console.log("end");
      setLoading(false);
      setLoadingScroll(false);
    }
  };

  const [loadinDist, setLoadingDist] = useState(false);
  const getDistributorID = async (id) => {
    setLoadingDist(!loadinDist);
    try {
      if (id === "0" || id === "undefined") return;
      const response = getDistributorsById(id);
      const data = (await response).data;
      setDistributorsIdArray(data);
      return data;
    } catch (error) {
      console.log("error get products");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(removeTags(`${content["ALERT-ERROR-GET-ONE-EXCHANGE"]}`), "error");
    } finally {
      console.log("end");
      setLoadingDist(false);
    }
  };

  const handleChangeDist = (e, value) => {
    setValueDistSelect(value?.name);
    setValueDist(value?.name);
    setdistributorID(value?.id);
    getDistributorID(`${value?.id}`);
  };

  const handleChangeInputDist = (e) => {
    setValueDist(e?.target?.value);
    setValueDistSearch(e?.target?.value);
    setDistributorsArray([]);
  };

  //* MAPEO DE REASN FOR EXCHANGE EN OBJETO PARA DROPDOWN

  //!------------------------------EXCHANGES------------------------------------

  const updateToSpecificStatus = async (button) => {
    try {
      const response = await updateExchangesInfo();

      if (response) {
        await updateExchangesStatus(props.id, "Submitted");
        handleCreateToast(removeTags(`${content["ALERT-SUCCESS-CREATE-EXCHANGE-SUBMITTED"]}`), "success");
      }
    } catch (error) {
      console.log("error updated");
      console.log(error);
      if (error?.request?.status !== 401 && error?.request?.status !== 403)
        handleCreateToast(removeTags(`${content["ALERT-ERROR-UPDATE-STATUS"]}`), "error");
    } finally {
      setLoading(false);
      console.log("end");
      if (button) {
        history.push(`/historic/${originPath}`);
      } else {
        setInLocalStorage("recreate", "yes");
        window.location.replace(`/demand/${originPath}`);
      }
    }
  };

  //UPDATE ALL INFO
  const updateExchangesInfo = async () => {
    setLoading(!loading);
    try {
      if (productsID && reasonForExchangesID && pictures.length >= 3) {
        const response = updateExchanges(props.id, customerID, distributorID, productsID, reasonForExchangesID);
        const data = (await response).data;
        return data;
      } else {
        throw Error("product_id or exchange_reason_id required", { status: 400 });
      }
      // successUpdated()
    } catch (error) {
      console.log("error update info");
      console.log(error);
      console.log(error.message);
      if (error.message) {
        if (error?.message.includes("product_id") || error?.message.includes("exchange_reason_id") || pictures.length < 3) {
          try {
            await updateExchangesStatus(props.id, "Abandonned");
            handleCreateToast(
              "Votre demande était incomplète (photo, raison, ou commentaire) et nous devons la supprimer . \nNous vous remercions de bien vouloir refaire votre demande.",
              "error"
            );
          } catch {
            if (error?.request?.status !== 401 && error?.request?.status !== 403)
              handleCreateToast(removeTags(`${content["ALERT-ERROR-UPDATE-STATUS"]}`), "error");
          }
        }
      } else {
        if (error?.request?.status !== 401 && error?.request?.status !== 403) {
          if (error?.request?.response.includes("product_id") || error?.request?.response.includes("exchange_reason_id")) {
            try {
              await updateExchangesStatus(props.id, "Abandonned");
              handleCreateToast(
                "Votre demande était incomplète (photo, raison, ou commentaire) et nous devons la supprimer . \nNous vous remercions de bien vouloir refaire votre demande.",
                "error"
              );
            } catch {
              if (error?.request?.status !== 401 && error?.request?.status !== 403)
                handleCreateToast(removeTags(`${content["ALERT-ERROR-UPDATE-STATUS"]}`), "error");
            }
          } else {
            if (error) handleCreateToast(removeTags(`${content["ALERT-ERROR-UPDATE-1"]}`), "error");
          }
        }
      }
    } finally {
      setLoading(false);
      console.log("end");
    }
  };

  const arrowLeft = "< ";

  var ColorPage = false;
  if (pageStatus === 1 || pageStatus === 6) {
    ColorPage = true;
  } else {
    ColorPage = false;
  }

  return (
    <div>
      <div
        className="row py-5"
        style={ColorPage === true ? { backgroundColor: "#000", margin: "0" } : { backgroundColor: "#1B1E1F", margin: "0" }}
      >
        <Link to={`/historic/${originPath}`}>
          <h6 className="h6-return" style={{ display: "flex", gap: "10px" }}>
            {arrowLeft}
            <div dangerouslySetInnerHTML={{ __html: content["FORM.ALL.BACK"] }}></div>
          </h6>
        </Link>

        {!loadingEx ? (
          <div>
            <div className="row pb-5">
              <div>
                <h2 className="h1-stl Center pt-5 ">
                  <div
                    dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FINAL.STEP-TITLE"]) }}
                    className="main-titles"
                  ></div>
                </h2>
                <div className="py-3">
                  <div
                    dangerouslySetInnerHTML={{ __html: replaceGlobalContents(content["FORM-NEW-WARRANTY-FINAL.STEP-INFO"]) }}
                    className="description-text"
                  ></div>
                </div>
              </div>
            </div>
            {!loadingLast ? (
              <DistributorSection
                distributorsArray={distributorssrray}
                selectedPostalCodeFinal={selectedPostalCodeFinal}
                onSelectDdDist={(value) => {
                  setSelectedPostalCodeFinal(value);
                  getDistributorID(distributorID);
                }}
                onChangeDdDist={handleChangeDist}
                loadingDist={loadinDist}
                distributorsIdArray={distributorsIdArray}
                distributorID={distributorID}
                width={width}
                setPageStatusBefore={() => {
                  updateToSpecificStatus(true);
                  setPageStatus(4);
                }}
                setPageStatusAfter={() => {
                  updateToSpecificStatus(false);
                  setPageStatus(4);
                }}
                handleChangeInputDist={handleChangeInputDist}
                valueDist={valueDist}
                valueDistSelect={valueDistSelect}
                getDistributorsFunction={getDistributorsFunction}
                hasNextPage={hasNextPage}
                loadingScroll={loadingScroll}
              />
            ) : (
              <LoadingFacom />
            )}
          </div>
        ) : (
          <div className="py-5">
            <LoadingFacom />
          </div>
        )}
      </div>

      <LoadingPag open={loading} />
    </div>
  );
}

export default SelectDistributor;
