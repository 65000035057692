import { useState } from "react";

const useAlertWarranty = () => {
  const [alertWrongStatus, setAlertWrongStatus] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const alertWrongGet = (
    getUser,
    getOneProduct,
    getAllProdcuts,
    getDistributor,
    getOneDistributor,
    getExchanges,
    createExchange,
    updateStatus,
    updateInfo
  ) => {
    setAlertWrongStatus([
      getUser,
      getOneProduct,
      getAllProdcuts,
      getDistributor,
      getOneDistributor,
      getExchanges,
      createExchange,
      updateStatus,
      updateInfo,
    ]);
  };

  return {
    alertWrongGet,
    setAlertWrongStatus,
    alertWrongStatus,
  };
};

export default useAlertWarranty;
