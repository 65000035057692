import { useContext, useEffect, useState } from "react";
import useQuery from "../helpers/getLocation";
import logoEval from "../images/logo-eval.png";
import { LoadingFacom } from "../components";
import { rateExchangeFromEmail } from "../api/facom_services";
import AppContext from "../context/AppContext";
export default function FacEval() {
  let query = useQuery();

  const { content } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const rateFromEmail = async () => {
    setLoading((prev) => !prev);
    try {
      if (query.get("c") && query.get("e")) {
        await rateExchangeFromEmail(query.get("c"), +query.get("e"));
        setError(false);
      } else {
        setError(true);
      }
    } catch (error) {
      console.log("error validating account");
      console.log(error);
      setError(true);
    } finally {
      console.log("end");
      setLoading(false);
    }
  };
  useEffect(() => {
    rateFromEmail();
  }, []);

  return (
    <div className="Background_Gray p-4" style={{ minHeight: "100vh" }}>
      {loading ? (
        <div className="my-5 pb-5" style={{ margin: "0 auto" }}>
          <div className="my-5 py-5">
            <LoadingFacom />
          </div>
        </div>
      ) : (
        <></>
      )}

      <img src={logoEval} alt="img-logo" />
      {error ? (
        <h6 style={{ color: "#FFF" }} className="mt-3">
          <div dangerouslySetInnerHTML={{ __html: content["RATE-EXCHANGE-EMAIL-ERROR"] }}></div>
        </h6>
      ) : (
        <h6 style={{ color: "#FFF" }} className="mt-3">
          <div dangerouslySetInnerHTML={{ __html: content["RATE-EXCHANGE-EMAIL-SUCCESS"] }}></div>
        </h6>
      )}
    </div>
  );
}
