import { useSnackbar } from "notistack";
import "./style.css";

export default function useToastMui() {
  const { enqueueSnackbar } = useSnackbar();
  const handleCreateToast = (message, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant: variant,
      anchorOrigin: { horizontal: "center", vertical: "top" },
      className: `${variant} alert-font`,
    });
  };

  return { handleCreateToast };
}
