import ProgressBar from "react-bootstrap/ProgressBar";
import "./style/warrantyprogress.css";
import { useContext } from "react";
import useGlobalContent from "../../hooks/useGlobalContent";
import AppContext from "../../context/AppContext";
export default function WarrantyProgress(props) {
  const { content } = useContext(AppContext);
  const { replaceGlobalContents } = useGlobalContent();
  return (
    <div>
      <div className="step-title-mobile">
        <div className="step-title__title">
          <div
            dangerouslySetInnerHTML={{
              __html: replaceGlobalContents(
                props.pageStatus === 2
                  ? content["FORM-NEW-WARRANTY-ALL.TAB-PRODUCT"]
                  : props.pageStatus === 3
                  ? content["FORM-NEW-WARRANTY-ALL.TAB-PICTURES"]
                  : props.pageStatus === 4
                  ? content["FORM-NEW-WARRANTY-ALL.TAB-TYPE"]
                  : content["FORM-NEW-WARRANTY-ALL.TAB-SUBMIT"]
              ),
            }}
            className="main-tabs"
          ></div>
        </div>
      </div>
      <div className="flex-progress input-font">
        <div style={{ width: "100%" }}>
          <ProgressBar
            style={{ height: "10px" }}
            now={props.pageStatus === 2 ? 25 : props.pageStatus === 3 ? 50 : props.pageStatus === 4 ? 75 : 100}
          />
        </div>
        <div>
          {props.pageStatus === 2 && "1/4"}
          {props.pageStatus === 3 && "2/4"}
          {props.pageStatus === 4 && "3/4"}
          {props.pageStatus === 5 && "4/4"}
        </div>
      </div>
    </div>
  );
}
