import { useSelector } from "react-redux";
import config from "../config/settings.json";
export default function useGlobalContent(data) {
  const { profile } = useSelector((state) => state.profileReducer);
  const { full_name, email, language, username } = profile;

  const replaceGlobalContents = (str, itemData) => {
    if (str?.includes("{{backendURL}}")) str = str.replace(/{{backendURL}}/g, `${config.BACKEND_URL}`);
    if (str?.includes("{{username}}")) str = str.replace(/{{username}}/g, `${username}`);
    if (str?.includes("{{email}}")) str = str.replace(/{{email}}/g, `${email}`);
    if (str?.includes("{{language}}")) str = str.replace(/{{language}}/g, `${language}`);
    if (str?.includes("{{full_name}}")) str = str.replace(/{{full_name}}/g, `${full_name}`);
    if (str?.includes("{{distributor_name}}")) str = str.replace(/{{distributor_name}}/g, `${data?.result?.name}`);
    if (str?.includes("{{distributor_address}}")) str = str.replace(/{{distributor_address}}/g, `${data?.result?.address?.address}`);
    if (str?.includes("{{distributor_opening_hours}}"))
      str = str.replace(
        /{{distributor_opening_hours}}/g,
        `${data?.result?.address?.opening_hours ? data?.result?.address?.opening_hours : ""}`
      );
    if (str?.includes("{{distributor_phone}}"))
      str = str.replace(/{{distributor_phone}}/g, `${data?.result?.address?.phone ? data?.result?.address.phone : ""}`);
    if (str?.includes("{{distributor_code}}"))
      str = str.replace(/{{distributor_code}}/g, `${data?.result?.code ? data?.result?.code : ""}`);
    if (str?.includes("{{distributor_evaluation}}"))
      str = str.replace(/{{distributor_evaluation}}/g, `${data?.result?.evaluation ? data?.result?.evaluation : ""}`);
    if (str?.includes("{{distributor_description}}"))
      str = str.replace(/{{distributor_description}}/g, `${data?.result?.description ? data?.result?.description : ""}`);
    if (str?.includes("{{distributor_city}}"))
      str = str.replace(/{{distributor_city}}/g, `${data?.result?.address?.city ? data?.result?.address.city : ""}`);
    if (str?.includes("{{distributor_postal_code}}"))
      str = str.replace(/{{distributor_postal_code}}/g, `${data?.result?.address?.postal_code ? data?.result?.address.postal_code : ""}`);

    if (str?.includes("{{product_name}}"))
      str = str.replace(/{{product_name}}/g, `${data?.result?.product_data?.name ? data?.result?.product_data?.name : ""}`);
    if (str?.includes("{{product_reference}}"))
      str = str.replace(/{{product_reference}}/g, `${data?.result?.product_data?.reference ? data?.result?.product_data?.reference : ""}`);
    if (str?.includes("{{product_type}}"))
      str = str.replace(/{{product_type}}/g, `${data?.result?.product_data?.type ? data?.result?.product_data?.type : ""}`);

    if (str?.includes("{{detail_exchange_number}}")) str = str.replace(/{{detail_exchange_number}}/g, `${data?.result?.number}`);
    if (str?.includes("{{detail_exchange_comment}}")) str = str.replace(/{{detail_exchange_comment}}/g, `${data?.result.comments}`);
    if (str?.includes("{{detail_exchange_createdAt}}")) str = str.replace(/{{detail_exchange_createdAt}}/g, `${itemData?.created_at}`);
    if (str?.includes("{{detail_exchange_distributor_name}}"))
      str = str.replace(
        /{{detail_exchange_distributor_name}}/g,
        `${data?.result?.distributor_data?.name ? data?.result?.distributor_data?.name : ""}`
      );
    if (str?.includes("{{detail_exchange_distributor_address}}"))
      str = str.replace(
        /{{detail_exchange_distributor_address}}/g,
        `${data?.result?.distributor_data?.address?.address ? data?.result?.distributor_data?.address?.address : ""}`
      );
    if (str?.includes("{{detail_exchange_distributor_city}}"))
      str = str.replace(
        /{{detail_exchange_distributor_city}}/g,
        `${data?.result?.distributor_data?.address?.city ? data?.result?.distributor_data?.address?.city : ""}`
      );
    if (str?.includes("{{detail_exchange_distributor_postal_code}}"))
      str = str.replace(
        /{{detail_exchange_distributor_postal_code}}/g,
        `${data?.result?.distributor_data?.address?.postal_code ? data?.result?.distributor_data?.address?.postal_code : ""}`
      );
    if (str?.includes("{{detail_exchange_distributor_phone}}"))
      str = str.replace(
        /{{detail_exchange_distributor_phone}}/g,
        `${data?.result?.distributor_data?.address?.phone ? data?.result?.distributor_data?.address?.phone : ""}`
      );
    if (str?.includes("{{detail_exchange_evaluation}}"))
      str = str.replace(
        /{{detail_exchange_evaluation}}/g,
        `${data?.result?.distributor_data?.evaluation ? data?.result?.distributor_data?.evaluation : ""}`
      );
    if (str?.includes("{{detail_refusal_reason}}"))
      str = str.replace(
        /{{detail_refusal_reason}}/g,
        `${data?.result?.refusal_reason_data?.label ? data?.result?.refusal_reason_data?.label : ""}`
      );
    return str;
  };

  return { replaceGlobalContents };
}
